import isEmpty from 'lodash/isEmpty'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'

import systemCustomerActions from '../actions/systemCustomerActions'
import { apiCertificateApi } from '../api/apiCertificateApi'
import { updatePricingRule } from '../api/productLineApi'
import {
  approveTermsOfService,
  createPacketIndustry,
  createPricingRuleSet,
  createSystemCustomerBankAccount,
  deletePricingRuleSet,
  deleteSystemCustomerBankAccount,
  fetchPacketIndustries,
  fetchPricingRuleFallbacks,
  fetchPricingRules,
  fetchPricingRuleSets,
  fetchSubItem,
  fetchSystemCustomer,
  fetchSystemCustomerBankAccounts,
  fetchSystemCustomerCreditActions,
  fetchWageIncomeTypes,
  requestHelp,
  salaryGroupApi,
  salaryTypeApi,
  startProduction,
  syncSystemCustomerBankAccountBalance,
  systemCustomerAnnualInvoicingApi,
  systemCustomerApproversApi,
  updatePacketIndustry,
  updatePricingRuleFallback,
  updatePricingRuleSet,
  updateSystemCustomerAccountingSettings,
  updateSystemCustomerBankAccount,
  updateSystemCustomerDetails,
  updateSystemCustomerEmailSettings,
  updateSystemCustomerFormTemplateSettings,
  updateSystemCustomerMaventaSettings,
  updateSystemCustomerSettings,
  updateSystemCustomerSmsSettings
} from '../api/systemCustomerApi'
import { normalizeWhoAmI } from '../api/whoAmIApi'
import { actionTypes } from '../constants'
import { addErrorNotification, addSuccessNotification } from '../helpers/notificationHelpers'
import {
  actionFlow,
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  defaultApiResponseHandler,
  deleteFlow,
  fetchFlow,
  formatSoapError,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubSagas,
  updateFlow
} from '../helpers/sagaHelpers'
import { apiCertificateActions } from '../slices/apiCertificateSlice'
import { approverApiHandler } from './receiverSaga'
import { handleWhoAmI } from './whoAmISaga'

const watchOnSockets = createSocketWatcher('system_customers', {
  updated: systemCustomerActions.updateSuccess
})

function* systemCustomerFetchFlow({ data = {} }) {
  try {
    if(data.force || !(yield select(state => state.systemCustomer.settingsData.fetchedAt))) {
      yield put(systemCustomerActions.fetchStart())
      const systemCustomer = yield call(fetchSystemCustomer, data)
      yield put(systemCustomerActions.fetchSuccess(systemCustomer, { replace: true }))
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe asetusten noutamisessa')
  }
}

const systemCustomerSettingsFetchFlow = settingsDataKey => function* ({ data = {} }) {
  try {
    if(isEmpty(yield select(state => state.systemCustomer.settingsData[settingsDataKey]))) {
      yield put(systemCustomerActions[settingsDataKey].fetchStart())
      const response = yield call(fetchSubItem, { subItem: settingsDataKey })
      yield put(systemCustomerActions[settingsDataKey].fetchSuccess(response, { replace: true }))
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe asetusten noutamisessa')
  }
}

function* systemCustomerBankAccountsFetchFlow({ data = {} }) {
  try {
    const currentBankAccounts = yield select(state => state.systemCustomer.bankAccounts.records || [])
    if(data.force || !currentBankAccounts.length) {
      yield put(systemCustomerActions.bankAccounts.fetchStart())
      const bankAccounts = yield call(fetchSystemCustomerBankAccounts, data)
      yield put(systemCustomerActions.bankAccounts.fetchSuccess(bankAccounts, { replace: true }))
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe pankkitilien noutamisessa')
  }
}

const extractNotification = data => {
  const { notification } = data
  return notification
}

const systemCustomerDetailsUpdateFlow = updateFlow(updateSystemCustomerDetails, systemCustomerActions.details, 'Perustiedot', 'Perustietojen')
const systemCustomerSettingsUpdateFlow = updateFlow(updateSystemCustomerSettings, systemCustomerActions.settings, 'Asetukset', 'Asetusten')
const systemCustomerMaventaSettingsUpdateFlow = updateFlow(updateSystemCustomerMaventaSettings, systemCustomerActions.maventa, 'Liittymäasetukset', 'Liittymäasetusten')
const systemCustomerAccountingSettingsUpdateFlow = updateFlow(updateSystemCustomerAccountingSettings, systemCustomerActions.accounting, 'Tiliöintiasetukset', 'Tiliöintiasetusten')
const systemCustomerEmailSettingsUpdateFlow = updateFlow(updateSystemCustomerEmailSettings, systemCustomerActions.emailSettings, 'Sähköpostiasetukset', 'Sähköpostiasetusten')
const systemCustomerBankAccountUpdateFlow = updateFlow(updateSystemCustomerBankAccount, systemCustomerActions.bankAccounts, 'Pankkitili', 'Pankkitilin')
const systemCustomerBankAccountCreateFlow = createFlow(createSystemCustomerBankAccount, systemCustomerActions.bankAccounts, 'Pankkitili', 'Pankkitilin')
const systemCustomerSmsSettingsUpdateFlow = updateFlow(updateSystemCustomerSmsSettings, systemCustomerActions.smsSettings, extractNotification, 'Tekstiviestin')
const systemCustomerFormTemplateSettingsUpdateFlow = updateFlow(updateSystemCustomerFormTemplateSettings, systemCustomerActions.formTemplate, 'Lomakepohjien asetukset', 'Lomakepohjien asetusten')

const systemCustomerTermsOfServiceApproveFlow = function* ({ record, data = {}, ...opts }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(approveTermsOfService)
    yield * handleWhoAmI(normalizeWhoAmI(response))
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe käyttöönoton viimeistelyssä.', reject)
  }
}

const systemCustomerBankAccountDeleteFlow = deleteFlow({
  deleteApi: deleteSystemCustomerBankAccount,
  actions: systemCustomerActions.bankAccounts,
  singular: 'Pankkitili',
  errorMsg: 'Pankkitilin',
  base: 'systemCustomer.bankAccounts'
})

function* systemCustomerCreditActionsFetchFlow({ data = {} }) {
  try {
    yield put(systemCustomerActions.creditActions.fetchStart())
    const creditActions = yield call(fetchSystemCustomerCreditActions, data)
    yield put(systemCustomerActions.creditActions.fetchSuccess(creditActions))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe krediittien noutamisessa')
  }
}
const watchOnCreditActionSockets = createSocketWatcher('creditAction', {
  created: systemCustomerActions.creditActions.createSuccess
})

const pricingRuleFetchFlow = fetchFlow({
  fetchApi: fetchPricingRules,
  actions: systemCustomerActions.pricingRules,
  base: 'systemCustomer.pricingRules',
  errorMsg: 'hinnoittelusääntöjen'
})
const pricingRuleUpdateFlow = updateFlow(updatePricingRule, systemCustomerActions.pricingRules, 'Hinnoittelusääntö', 'Hinnoittelusäännön')
const pricingRuleCreateFlow = createFlow(updatePricingRule, systemCustomerActions.pricingRules, 'Hinnoittelusääntö', 'Hinnoittelusäännön')
const pricingRuleSetFetchFlow = fetchFlow({
  fetchApi: fetchPricingRuleSets,
  actions: systemCustomerActions.pricingRuleSets,
  errorMsg: 'hinnoittelusääntötaulukkojen',
  base: 'systemCustomer.pricingRuleSets'
})

function* pricingRuleSetDeleteFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const toDelete = yield select(state => state.systemCustomer.pricingRuleSets.records.find(pricingRuleSet => pricingRuleSet.id === record.id))
    yield call(deletePricingRuleSet, toDelete)
    yield put(systemCustomerActions.pricingRuleSets.deleteSuccess(toDelete))
    addSuccessNotification('Hinnoittelusäännöstö poistettu')
    yield call(resolve, toDelete)
  } catch(err) {
    console.error(err)
    addErrorNotification('Virhe hinnoittelusäännöstön poistamisessa')
    yield call(reject, err)
  }
}
const pricingRuleSetUpdateFlow = updateFlow(updatePricingRuleSet, systemCustomerActions.pricingRuleSets, 'Hinnoittelusäännöstö', 'Hinnoittelusäännöstön')
const pricingRuleSetCreateFlow = createFlow(createPricingRuleSet, systemCustomerActions.pricingRuleSets, 'Hinnoittelusäännöstö', 'Hinnoittelusäännöstön')

const pricingRuleFallbackFetchFlow = fetchFlow({
  fetchApi: fetchPricingRuleFallbacks,
  actions: systemCustomerActions.pricingRuleFallbacks,
  base: 'systemCustomer.pricingRuleFallbacks',
  errorMsg: 'ostolaskujen hinnoittelusääntöjen'
})
const pricingRuleFallbackUpdateFlow = updateFlow(updatePricingRuleFallback, systemCustomerActions.pricingRuleFallbacks, 'Ostolaskujen hinnoittelusääntö', 'Ostolaskujen hinnoittelusäännön')
const pricingRuleFallbackCreateFlow = createFlow(updatePricingRuleFallback, systemCustomerActions.pricingRuleFallbacks, 'Ostolaskujen hinnoittelusääntö', 'Ostolaskujen hinnoittelusäännön')

const watchOnSalaryTypeSockets = createSocketWatcher('salaryType', {
  created: systemCustomerActions.salaryTypes.createSuccess,
  updated: systemCustomerActions.salaryTypes.updateSuccess,
  deleted: systemCustomerActions.salaryTypes.deleteSuccess
})

const salaryTypeFetchFlow = fetchFlow({
  fetchApi: salaryTypeApi.fetch,
  actions: systemCustomerActions.salaryTypes,
  base: 'systemCustomer.salaryTypes',
  errorMsg: 'Palkkalajien'
})
const salaryTypeUpdateFlow = updateFlow(salaryTypeApi.update, systemCustomerActions.salaryTypes, 'Palkkalaji', 'Palkkalajin')
const salaryTypeCreateFlow = createFlow(salaryTypeApi.create, systemCustomerActions.salaryTypes, 'Palkkalaji', 'Palkkalajin')
const salaryTypeDeleteFlow = deleteFlow({
  deleteApi: salaryTypeApi.remove,
  actions: systemCustomerActions.salaryTypes,
  singular: 'Palkkalaji',
  errorMsg: 'Palkkalajin',
  base: 'systemCustomer.salaryTypes'
})

const watchOnSalaryGroupSockets = createSocketWatcher('salaryGroup', {
  created: systemCustomerActions.salaryGroups.createSuccess,
  updated: systemCustomerActions.salaryGroups.updateSuccess,
  deleted: systemCustomerActions.salaryGroups.deleteSuccess
})

const watchOnSystemCustomerAnnualInvoicingSockets = createSocketWatcher('systemCustomerAnnualInvoicing', {
  created: systemCustomerActions.systemCustomerAnnualInvoicings.createSuccess,
  updated: systemCustomerActions.systemCustomerAnnualInvoicings.updateSuccess,
  deleted: systemCustomerActions.systemCustomerAnnualInvoicings.deleteSuccess
})

const salaryGroupFetchFlow = fetchFlow({
  fetchApi: salaryGroupApi.fetch,
  actions: systemCustomerActions.salaryGroups,
  base: 'systemCustomer.salaryGroups',
  errorMsg: 'Palkkaryhmien'
})
const salaryGroupUpdateFlow = updateFlow(salaryGroupApi.update, systemCustomerActions.salaryGroups, 'Maksuryhmä', 'Palkkaryhmien')
const salaryGroupCreateFlow = createFlow(salaryGroupApi.create, systemCustomerActions.salaryGroups, 'Maksuryhmä', 'Palkkaryhmien')
const salaryGroupDeleteFlow = deleteFlow({
  deleteApi: salaryGroupApi.remove,
  actions: systemCustomerActions.salaryGroups,
  singular: 'Maksuryhmä',
  errorMsg: 'Palkkaryhmien',
  base: 'systemCustomer.salaryGroups'
})

const wageIncomeTypeFetchFlow = fetchFlow({
  fetchApi: fetchWageIncomeTypes,
  actions: systemCustomerActions.wageIncomeTypes,
  base: 'systemCustomer.wageIncomeTypes',
  errorMsg: 'Palkka.fi -palkkalajien'
})

function* syncBankAccountBalanceFlow({ record: id }) {
  try {
    const response = yield call(syncSystemCustomerBankAccountBalance, { id })
    yield put(systemCustomerActions.bankAccounts.updateSuccess(response))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe saldon noudossa')
  }
}

const watchOnPacketIndustrySockets = createSocketWatcher('packetIndustry', {
  created: systemCustomerActions.packetIndustries.createSuccess,
  updated: systemCustomerActions.packetIndustries.updateSuccess
})

function* packetIndustryFetchFlow({ data = {} }) {
  try {
    if(data.force || (yield select(state => state.systemCustomer.packetIndustries)).records.length === 0) {
      yield put(systemCustomerActions.packetIndustries.fetchStart())
      const packetIndustries = yield call(fetchPacketIndustries, data)
      yield put(systemCustomerActions.packetIndustries.fetchSuccess(packetIndustries))
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe toimialojen noutamisessa')
  }
}
const packetIndustryUpdateFlow = updateFlow(updatePacketIndustry, systemCustomerActions.packetIndustries, 'toimiala', 'toimialan')
const packetIndustryCreateFlow = createFlow(createPacketIndustry, systemCustomerActions.packetIndustries, 'toimiala', 'toimialan')

const startProductionFlow = actionFlow({
  apiFunction: startProduction,
  apiResponseHandler: handleWhoAmI,
  successMsg: 'Käytön aloitus onnistui',
  errorMsg: 'Virhe tietojen tallentamisessa'
})

const requestHelpFlow = actionFlow({
  apiFunction: requestHelp,
  successMsg: 'Tukipyyntö lähetetty',
  errorMsg: 'Virhe tukipyynnön lähettämisessä'
})

const systemCustomerAnnualInvoicingFetchFlow = fetchFlow({
  fetchApi: systemCustomerAnnualInvoicingApi.fetch,
  actions: systemCustomerActions.systemCustomerAnnualInvoicings,
  base: 'systemCustomer.systemCustomerAnnualInvoicings',
  errorMsg: 'Motivaatiopalkkien'
})
const systemCustomerAnnualInvoicingUpdateFlow = updateFlow(systemCustomerAnnualInvoicingApi.update, systemCustomerActions.systemCustomerAnnualInvoicings, 'Motivaatiopalkin', 'Motivaatiopalkkien')
const systemCustomerAnnualInvoicingCreateFlow = createFlow(systemCustomerAnnualInvoicingApi.create, systemCustomerActions.systemCustomerAnnualInvoicings, 'Motivaatiopalkin', 'Motivaatiopalkkien')
const systemCustomerAnnualInvoicingDeleteFlow = deleteFlow({
  deleteApi: systemCustomerAnnualInvoicingApi.remove,
  actions: systemCustomerActions.systemCustomerAnnualInvoicings,
  singular: 'Motivaatiopalkin',
  errorMsg: 'Motivaatiopalkkien',
  base: 'systemCustomer.systemCustomerAnnualInvoicings'
})

const {
  subFetchFlow: systemCustomerApproversFetchFlow,
  subCreateFlow: systemCustomerApproverCreateFlow,
  subUpdateFlow: systemCustomerApproverUpdateFlow,
  subDeleteFlow: systemCustomerApproverDeleteFlow
} = getSubSagas({
  mainActions: systemCustomerActions,
  mainReduxName: 'systemCustomer',
  subReduxName: 'approvers'
}, {
  fetchApi: systemCustomerApproversApi.fetch,
  createApi: systemCustomerApproversApi.create,
  updateApi: systemCustomerApproversApi.update,
  deleteApi: systemCustomerApproversApi.remove
}, {
  singular: 'Ostolaskuhyväksyjä',
  accusative: 'Ostolaskuhyväksyjän',
  fetchError: 'Virhe ostolaskuhyväksyjien noutamisessa',
  deleteSuccess: 'Ostolaskuhyväksyjä poistettu',
  deleteError: 'Virhe ostolaskuhyväksyjän poistamisessa'
}, approverApiHandler)

const apiCertificateFetchFlow = fetchFlow({
  fetchApi: apiCertificateApi.fetch,
  actions: apiCertificateActions,
  base: 'apiCertificates',
  errorMsg: 'varmenteiden',
  apiResponseHandler: defaultApiResponseHandler(apiCertificateActions.fetchSuccess)
})

const apiCertificateRequestFlow = createFlow(apiCertificateApi.create, apiCertificateActions, 'varmenne', formatSoapError('varmenteen'), defaultApiResponseHandler(apiCertificateActions.fetchSuccess))

const watchOnApiCertificateSockets = createSocketWatcherWithApiHandlerAndNormalizer('apiCertificate', apiCertificateActions, defaultApiResponseHandler)

export default function* systemCustomerSaga() {
  yield takeLeading(systemCustomerActions.actionTypes.fetchRequest, systemCustomerFetchFlow)
  yield takeLatest(systemCustomerActions.bankAccounts.actionTypes.fetchRequest, systemCustomerBankAccountsFetchFlow)
  yield takeLatest(systemCustomerActions.creditActions.actionTypes.fetchRequest, systemCustomerCreditActionsFetchFlow)
  yield takeEvery(systemCustomerActions.actionTypes.updateRequest, systemCustomerDetailsUpdateFlow)
  yield takeEvery(systemCustomerActions.settings.actionTypes.updateRequest, systemCustomerSettingsUpdateFlow)
  yield takeEvery(systemCustomerActions.maventa.actionTypes.updateRequest, systemCustomerMaventaSettingsUpdateFlow)
  yield takeLatest(systemCustomerActions.maventa.actionTypes.fetchRequest, systemCustomerSettingsFetchFlow('maventa'))
  yield takeLatest(systemCustomerActions.accounting.actionTypes.fetchRequest, systemCustomerSettingsFetchFlow('accounting'))
  yield takeEvery(systemCustomerActions.accounting.actionTypes.updateRequest, systemCustomerAccountingSettingsUpdateFlow)
  yield takeEvery(systemCustomerActions.bankAccounts.actionTypes.updateRequest, systemCustomerBankAccountUpdateFlow)
  yield takeEvery(systemCustomerActions.bankAccounts.actionTypes.createRequest, systemCustomerBankAccountCreateFlow)
  yield takeEvery(systemCustomerActions.bankAccounts.actionTypes.deleteRequest, systemCustomerBankAccountDeleteFlow)
  yield takeEvery(systemCustomerActions.emailSettings.actionTypes.updateRequest, systemCustomerEmailSettingsUpdateFlow)
  yield takeEvery(systemCustomerActions.termsOfService.actionTypes.updateRequest, systemCustomerTermsOfServiceApproveFlow)
  yield takeEvery(systemCustomerActions.smsSettings.actionTypes.updateRequest, systemCustomerSmsSettingsUpdateFlow)
  yield takeLeading(systemCustomerActions.pricingRules.actionTypes.fetchRequest, pricingRuleFetchFlow)
  yield takeEvery(systemCustomerActions.pricingRules.actionTypes.createRequest, pricingRuleCreateFlow)
  yield takeEvery(systemCustomerActions.pricingRules.actionTypes.updateRequest, pricingRuleUpdateFlow)
  yield takeLeading(systemCustomerActions.pricingRuleSets.actionTypes.fetchRequest, pricingRuleSetFetchFlow)
  yield takeEvery(systemCustomerActions.pricingRuleSets.actionTypes.createRequest, pricingRuleSetCreateFlow)
  yield takeEvery(systemCustomerActions.pricingRuleSets.actionTypes.updateRequest, pricingRuleSetUpdateFlow)
  yield takeEvery(systemCustomerActions.pricingRuleSets.actionTypes.deleteRequest, pricingRuleSetDeleteFlow)
  yield takeLeading(systemCustomerActions.pricingRuleFallbacks.actionTypes.fetchRequest, pricingRuleFallbackFetchFlow)
  yield takeEvery(systemCustomerActions.pricingRuleFallbacks.actionTypes.createRequest, pricingRuleFallbackCreateFlow)
  yield takeEvery(systemCustomerActions.pricingRuleFallbacks.actionTypes.updateRequest, pricingRuleFallbackUpdateFlow)

  yield takeLatest(systemCustomerActions.salaryTypes.actionTypes.fetchRequest, salaryTypeFetchFlow)
  yield takeEvery(systemCustomerActions.salaryTypes.actionTypes.updateRequest, salaryTypeUpdateFlow)
  yield takeEvery(systemCustomerActions.salaryTypes.actionTypes.createRequest, salaryTypeCreateFlow)
  yield takeEvery(systemCustomerActions.salaryTypes.actionTypes.deleteRequest, salaryTypeDeleteFlow)

  yield takeLatest(systemCustomerActions.salaryGroups.actionTypes.fetchRequest, salaryGroupFetchFlow)
  yield takeEvery(systemCustomerActions.salaryGroups.actionTypes.updateRequest, salaryGroupUpdateFlow)
  yield takeEvery(systemCustomerActions.salaryGroups.actionTypes.createRequest, salaryGroupCreateFlow)
  yield takeEvery(systemCustomerActions.salaryGroups.actionTypes.deleteRequest, salaryGroupDeleteFlow)

  yield takeLatest(systemCustomerActions.packetIndustries.actionTypes.fetchRequest, packetIndustryFetchFlow)
  yield takeEvery(systemCustomerActions.packetIndustries.actionTypes.updateRequest, packetIndustryUpdateFlow)
  yield takeEvery(systemCustomerActions.packetIndustries.actionTypes.createRequest, packetIndustryCreateFlow)

  yield takeLatest(systemCustomerActions.wageIncomeTypes.actionTypes.fetchRequest, wageIncomeTypeFetchFlow)
  yield takeLatest(actionTypes.SYNC_BANK_ACCOUNT_BALANCE, syncBankAccountBalanceFlow)

  yield takeLatest(systemCustomerActions.actionTypes.startProductionRequest, startProductionFlow)
  yield takeLatest(systemCustomerActions.actionTypes.requestHelpRequest, requestHelpFlow)

  yield takeLatest(systemCustomerActions.systemCustomerAnnualInvoicings.actionTypes.fetchRequest, systemCustomerAnnualInvoicingFetchFlow)
  yield takeEvery(systemCustomerActions.systemCustomerAnnualInvoicings.actionTypes.updateRequest, systemCustomerAnnualInvoicingUpdateFlow)
  yield takeEvery(systemCustomerActions.systemCustomerAnnualInvoicings.actionTypes.createRequest, systemCustomerAnnualInvoicingCreateFlow)
  yield takeEvery(systemCustomerActions.systemCustomerAnnualInvoicings.actionTypes.deleteRequest, systemCustomerAnnualInvoicingDeleteFlow)

  yield takeLatest(systemCustomerActions.approvers.actionTypes.fetchRequest, systemCustomerApproversFetchFlow)
  yield takeEvery(systemCustomerActions.approvers.actionTypes.updateRequest, systemCustomerApproverUpdateFlow)
  yield takeEvery(systemCustomerActions.approvers.actionTypes.createRequest, systemCustomerApproverCreateFlow)
  yield takeEvery(systemCustomerActions.approvers.actionTypes.deleteRequest, systemCustomerApproverDeleteFlow)

  yield takeEvery(systemCustomerActions.formTemplate.actionTypes.updateRequest, systemCustomerFormTemplateSettingsUpdateFlow)
  yield takeLatest(systemCustomerActions.formTemplate.actionTypes.fetchRequest, systemCustomerSettingsFetchFlow('formTemplate'))

  yield takeLatest(apiCertificateActions.fetchRequest, apiCertificateFetchFlow)
  yield takeEvery(apiCertificateActions.createRequest, apiCertificateRequestFlow)

  yield all([
    watchOnSockets(),
    watchOnCreditActionSockets(),
    watchOnSalaryTypeSockets(),
    watchOnPacketIndustrySockets(),
    watchOnSalaryGroupSockets(),
    watchOnSystemCustomerAnnualInvoicingSockets(),
    watchOnApiCertificateSockets()
  ])
}
