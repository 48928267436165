import { systemMessageTypes } from '@evelia/common/constants'
import {
  faAt,
  faBullhorn,
  faMobileAlt,
  faPhone,
  faPhoneSlash,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons'

import { mapObjectToOptions } from '../../helpers/helpers'

export const addPhoneNumberToDestinations = (phoneNumber, destinations, updateField) => {
  if(phoneNumber && !destinations.includes(phoneNumber)) {
    updateField('destinations', [...destinations, phoneNumber])
  }
}

export const getPhoneNumberIcon = (phoneNumber, model) => {
  let icon
  if(!phoneNumber) {
    icon = faPhoneSlash
  } else if(model.destinations.includes(phoneNumber)) {
    icon = faUserCheck
  } else {
    icon = faPhone
  }
  return icon
}

export const getSystemMessageColumns = () => {
  return (
    <tr>
      <th width='500px'>Viesti</th>
      <th>Lähettäjä</th>
      <th>Vastaanottaja</th>
      <th>Tila</th>
      <th>Lähetysaika</th>
      <th />
    </tr>
  )
}

export const systemMessageSendTypeMap = {
  [systemMessageTypes.NOTIFICATION]: { text: 'Tiedote', icon: faBullhorn },
  [systemMessageTypes.SMS]: { text: 'Tekstiviesti', icon: faMobileAlt },
  [systemMessageTypes.EMAIL]: { text: 'Sähköposti', icon: faAt }
}

export const systemMessageSendTypeOptions = mapObjectToOptions(systemMessageSendTypeMap)
