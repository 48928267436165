import {
  MouseEvent,
  MouseEventHandler,
  PureComponent,
  ReactNode,
  Ref
} from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonProps } from 'reactstrap'

export interface EveliaButtonProps extends ButtonProps {
  busyContent?: ReactNode
  disabled?: boolean
  isBusy?: boolean
  buttonRef?: Ref<HTMLButtonElement>
  href?: string
  onClick?: MouseEventHandler<HTMLButtonElement> | ((ev: MouseEvent<HTMLButtonElement>) => Promise<unknown>)
  captureClick?: boolean
  renderContent?: (...args: unknown[]) => ReactNode
  testId?: string
  dontModifyOnClick?: boolean
}

export default class EveliaButton extends PureComponent<EveliaButtonProps> {
  state = {
    isBusy: false
  }

  render() {
    const {
      children,
      disabled,
      isBusy: propIsBusy,
      busyContent,
      buttonRef,
      href,
      onClick,
      captureClick,
      renderContent,
      testId,
      dontModifyOnClick,
      ...buttonProps
    } = this.props
    const { isBusy: stateIsBusy } = this.state
    const isBusy = stateIsBusy || propIsBusy

    const clickAction = onClick ? dontModifyOnClick ? onClick : this.handleOnClick : undefined
    const extraProps = {
      tag: href && !href.match(/^https?:\/\//) ? Link : undefined,
      to: href,
      href,
      onClick: captureClick ? undefined : clickAction,
      onClickCapture: captureClick ? clickAction : undefined // To register an event handler for the capture phase,
    }

    return (
      <Button
        size='sm'
        type='button'
        data-testid={testId}
        {...extraProps}
        {...buttonProps}
        disabled={disabled || isBusy}
        innerRef={buttonRef}
      >
        {renderContent
          ? renderContent(isBusy)
          : (busyContent && isBusy
              ? busyContent
              : children
            )}
      </Button>
    )
  }

  handleOnClick = event => {
    event.stopPropagation()
    const { onClick } = this.props
    const result = onClick!(event)
    if(result?.then) {
      return new Promise(resolve => this.setState({ isBusy: true }, () => resolve(result)))
        .then(() => result)
        .catch(err => console.error(err))
        .finally(() => this.setState({ isBusy: false }))
    }
    return result
  }
}
