import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { ApiCertificateModel } from '../api/apiCertificateApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const apiCertificateAdapter = createEntityAdapter<ApiCertificateModel>({})

const apiCertificateSlice = createGenericSlice({
  name: 'apiCertificates',
  entityAdapter: apiCertificateAdapter
})

export const apiCertificateEntitySelectors = apiCertificateAdapter.getSelectors<EveliaRootState>(state => state.apiCertificates)
export const apiCertificateActions = apiCertificateSlice.actions

export default apiCertificateSlice
