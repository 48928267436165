import { emptyArray } from '@evelia/common/constants'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getMemoSelector,
  getStats,
  getSubentitySelectors
} from '../helpers/selectorHelpers'

export const getTargetsFromArgument = arg => arg.targets ? arg.targets.records : arg

export const getSubTargetsOf = getFilterItemsByFieldSelector(getTargetsFromArgument, 'parentTargetId', Number)

export const getParentTargetsOf = createCachedSelector(
  getTargetsFromArgument,
  (state, targetId) => Number(targetId),
  (targets, targetId) => {
    const baseTarget = findTargetWithId(targets, targetId)
    if(!baseTarget || baseTarget.parentTargetId === null) {
      return emptyArray
    } else {
      const parentTarget = findTargetWithId(targets, baseTarget.parentTargetId)
      if(!parentTarget) {
        return emptyArray
      }
      return [...getParentTargetsOf(targets, parentTarget.id), parentTarget]
    }
  }
)((targets, targetId) => targetId)

export const findTargetWithId = getFindItemByIdSelector(getTargetsFromArgument)

export const getTargetsByTableIds = createCachedSelector(
  state => state.targets.records,
  (state, tableIdentifier) => state.targets.tableOptions[tableIdentifier || 'default'],
  (records, tableOptions) => {
    if(!tableOptions.ids.length && tableOptions.q == null && !tableOptions.filters.length) {
      return records
    }

    const targetMap = new Map()
    tableOptions.ids.forEach(id => {
      const idTarget = findTargetWithId(records, id)
      if(idTarget) {
        targetMap.set(idTarget.id, idTarget)
        if(tableOptions.q == null) {
          records.filter(target => target.targetPath.startsWith(idTarget.targetPath + '.')).forEach(target => targetMap.set(target.id, target))
        }
      }
    })

    return [...targetMap.values()]
  }
)((state, tableIdentifier) => tableIdentifier)

export const {
  getSubItemsOfItem: getContactsOfTarget,
  rejectSubItemsOfItem: rejectContactsOfTarget
} = getSubentitySelectors('targets', 'contacts', 'contacts', 'targetId', 'contactId')

export const {
  getSubItemsOfItem: getFilesOfTarget,
  rejectSubItemsOfItem: rejectFilesOfTarget
} = getSubentitySelectors('targets', 'files', 'files', 'targetId', 'fileId')

export const findTargetsByCustomerId = getFilterItemsByFieldSelector(getTargetsFromArgument, 'customerId', Number)

export const findMemosByTargetId = getMemoSelector('targets', 'targetId')

export const getTargetsFromSearchResult = getItemsFromSearchResultsSelector('targets', getTargetsFromArgument, findTargetWithId)

export const getTargetPath = createSelector(
  target => target.targetPath,
  targetPath => targetPath.split('.').map(Number)
)

export const getStatsOfTarget = getStats(findTargetWithId, 'targets', 'targetId')
