import _isObject from 'lodash/isObject'

export type NotSureOfThisType<T = number | string> = T | null

export type BodyWithID<T> = {
  id: number
  body: T
}

export type ServerMessageError = {
  error?: { data?: { message?: string } }
}
export const isServerMessageError = (error: unknown): error is ServerMessageError => {
  return _isObject(error) && 'error' in error &&
        _isObject(error.error) && 'data' in error.error &&
        _isObject(error.error.data) && 'message' in error.error.data
}
