import { emptyArray } from '@evelia/common/constants'
import { appendEitherOrEmpty, sortByProperty } from '@evelia/common/helpers'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { actionKeys } from '../constants'
import { getFilterItemsByFieldSelector, getFindItemByIdSelector } from '../helpers/selectorHelpers'
import { findReceiverByInboundInvoiceId } from './receiverSelectors'
import { getHasAccessToFeature } from './whoAmISelectors'
import { findWorkWithId } from './workSelectors'

const getDenominationsFromArgument = arg => arg.denominations ? arg.denominations.records : arg
export const getDenominationGroupsFromArgument = arg => arg.denominations?.denominationGroups ? arg.denominations.denominationGroups.records : arg
const getDenominationPacketsFromArgument = arg => arg.denominations?.denominationPackets ? arg.denominations.denominationPackets.records : arg

export const formatDenominationText = denomination => denomination ? appendEitherOrEmpty(denomination.denominationNumber, denomination.name, ' - ') : null

export const findDenominationWithId = getFindItemByIdSelector(getDenominationsFromArgument)
export const findDenominationGroupWithId = getFindItemByIdSelector(getDenominationGroupsFromArgument)

export const findDenominationGroupsByType = getFilterItemsByFieldSelector(getDenominationGroupsFromArgument, 'type')

export const findDenominationsByDenominationGroupId = createCachedSelector(
  getFilterItemsByFieldSelector(getDenominationsFromArgument, 'denominationGroupId', Number),
  denominations => denominations.sort(sortByProperty('number'))
)((state, denominationGroupId) => `${denominationGroupId}`)

export const findEnabledDenominationsOfWork = createCachedSelector(
  getDenominationsFromArgument,
  (state, { workId }) => {
    const work = findWorkWithId(state, workId)
    return work?.defaultDenominationId
  },
  (state, { workId }) => {
    const work = findWorkWithId(state, workId)
    return work?.workDenominationIds ?? emptyArray
  },
  (denominations, defaultDenominationId, workDenominationIds) => {
    const denominationIds = [...(new Set([defaultDenominationId, ...workDenominationIds].filter(Boolean)))]
    return denominationIds
      .map(denominationId => denominations.find(denomination => denomination?.id === denominationId))
      .filter(Boolean)
  }
)((state, { workId }) => `${workId}`)

export const findEnabledDenominationOptionsOfWork = createCachedSelector(
  findEnabledDenominationsOfWork,
  denominations => {
    return denominations
      .map(denomination => ({
        value: denomination.id,
        text: formatDenominationText(denomination)
      }))
  }
)((state, { workId }) => `${workId}`)

const findReceiverDenominationIdsByInboundInvoice = createCachedSelector(
  findReceiverByInboundInvoiceId,
  receiver => receiver?.receiverDenominationIds ?? emptyArray
)((state, { inboundInvoiceId }) => `${inboundInvoiceId}`)

const findReceiverDefaultDenominationIdByInboundInvoice = createCachedSelector(
  findReceiverByInboundInvoiceId,
  receiver => receiver?.defaultDenominationId
)((state, { inboundInvoiceId }) => `${inboundInvoiceId}`)

export const findEnabledDenominationsOfInboundInvoice = createCachedSelector(
  getDenominationsFromArgument,
  findReceiverDefaultDenominationIdByInboundInvoice,
  findReceiverDenominationIdsByInboundInvoice,
  (denominations, defaultDenominationId, receiverDenominationIds) => {
    const denominationIds = [...(new Set([defaultDenominationId, ...receiverDenominationIds].filter(Boolean)))]
    return denominationIds
      .map(denominationId => denominations.find(denomination => denomination.id === denominationId))
      .filter(Boolean)
  }
)((state, { inboundInvoiceId }) => `${inboundInvoiceId}`)

export const mapDenominationOptions = createSelector(
  getDenominationsFromArgument,
  denominations =>
    denominations.map(denomination => ({
      value: denomination.id,
      text: formatDenominationText(denomination)
    }))
)

export const isDenominationsEnabled = state => getHasAccessToFeature(state, actionKeys.DENOMINATIONS, true)

export const findDenominationPackets = createSelector(
  getDenominationPacketsFromArgument,
  denominationPackets => denominationPackets.filter(denominationPacket => !denominationPacket.isTemplate || denominationPacket.isLviPacket)
)
export const findDenominationPacketTemplates = createSelector(
  getDenominationPacketsFromArgument,
  denominationPackets => denominationPackets.filter(denominationPacket => denominationPacket.isTemplate)
)

export const findDenominationGroupsByPacketId = createCachedSelector(
  getDenominationGroupsFromArgument,
  (state, { denominationPacketId }) => Number(denominationPacketId),
  (state, { type }) => type,
  (denominationGroups, denominationPacketId, denominationType) =>
    denominationGroups
      .filter(denominationGroup => denominationGroup.denominationPacketId === denominationPacketId && (denominationType == null || denominationGroup.type === denominationType))
      .sort(sortByProperty('number'))
)((__state, { denominationPacketId }) => `${denominationPacketId}`)
