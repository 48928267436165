import PropTypes from 'prop-types'
import { ClearButton } from 'react-bootstrap-typeahead'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Use clearButton={false} to hide ClearButton. Show dropdown always
 */
const FormSelectButton = ({ onClear, selected, required, clearButton }) => (
  <div className='rbt-aux'>
    {(selected.length && !required && clearButton !== false)
      ? <ClearButton size='sm' onClick={onClear} />
      : (
        <span>
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
        )}
  </div>
)
FormSelectButton.propTypes = {
  onClear: PropTypes.func.isRequired,
  selected: PropTypes.array,
  required: PropTypes.bool,
  clearButton: PropTypes.bool
}

export default FormSelectButton
