import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'

import timeRecordActions from '../../actions/timeRecordActions'
import useFetch from '../../hooks/useFetch'
import { findCurrentTimeRecordType, getTimeRecordTypesOfCurrentEmployee } from '../../selectors/timeRecordSelectors'
import { Subtitle } from '../Typography'
import TimeRecordTypeButton from './TimeRecordTypeButton'

const TimeRecordTypeButtons = ({ onClick }) => {
  useFetch(timeRecordActions.timeRecordTypes.fetchRequest)
  const timeRecordTypes = useSelector(getTimeRecordTypesOfCurrentEmployee)
  const currentTimeRecordType = useSelector(findCurrentTimeRecordType)

  return (
    <>
      <Subtitle>Tee kirjaus</Subtitle>
      <Row xs={1} lg={2} xxl={3} className='gx-2 row-gap-2'>
        {timeRecordTypes.map(timeRecordType => (
          <Col key={timeRecordType.id}>
            <TimeRecordTypeButton timeRecordType={timeRecordType} disabled={currentTimeRecordType?.id === timeRecordType.id} onClick={onClick} />
          </Col>
        )
        )}
      </Row>
    </>
  )
}

TimeRecordTypeButtons.propTypes = {
  onClick: PropTypes.func
}

export default TimeRecordTypeButtons
