import { appendEitherOrEmpty, appendValuesOrEmpty, capitalize } from '@evelia/common/helpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import costCentreActions from '../actions/costCentreActions'
import customerActions from '../actions/customerActions'
import denominationActions from '../actions/denominationActions'
import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import machineRecordActions from '../actions/machineRecordActions'
import { navigateTo } from '../actions/navigateActions'
import projectActions from '../actions/projectActions'
import targetActions from '../actions/targetActions'
import ticketActions from '../actions/ticketActions'
import { showActionPrompt } from '../actions/uiActions'
import workActions from '../actions/workActions'
import workRecordActions from '../actions/workRecordActions'
import {
  createWork,
  createWorkEmployee,
  createWorkFile,
  createWorkTicket,
  deleteWork,
  deleteWorkEmployee,
  deleteWorkFile,
  doWorkAction,
  doWorkPatchAction,
  doWorkPostAction,
  fetchWork,
  fetchWorkEmployees,
  fetchWorkFiles,
  fetchWorkInvoicingRules,
  fetchWorkSummaryStats,
  importInstalmentRowsFromFile,
  normalizeWorkEmployeeResponse,
  normalizeWorkFileResponse,
  normalizeWorkList,
  normalizeWorkRowResponse,
  updateWork,
  updateWorkEmployee,
  updateWorkFile,
  updateWorkInvoicingRule
} from '../api/workApi'
import {
  createWorkRowsFromInboundInvoiceRows,
  createWorkRowsFromMachineRecords,
  createWorkRowsFromWorkRecords,
  workRowApi
} from '../api/workRowApi'
import machineRecordTitles from '../components/MachineRecords/machineRecordTitles'
import ticketTitles from '../components/Tickets/ticketTitles'
import { actionTypes, uiTypes } from '../constants'
import { getMemoSagas, getSystemMessageSagas } from '../helpers/generalSagas'
import { addInfoNotification, addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createActionFlow,
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getDefaultGeneratorSocketHandlers,
  getPromiseHandlersFromData,
  getSubEntitySagas,
  getSubFilesSagas,
  updateFlow,
  updateRowRankFlow
} from '../helpers/sagaHelpers'
import { initialWorkTableOptions } from '../reducers/workReducer'
import { PATH_WORK_SEARCH_RESULTS } from '../routes'
import { getWorkTableOptions } from '../selectors/workSelectors'
import { handleEmployeeApiResponse } from './employeeSaga'

const WORK_SUMMARY_STATS_FETCH_DELAY = 600

export const handleWorkApiResponse = (mainAction, tableIdentifier) =>
  function* ({
    data,
    customers,
    targets,
    supervisors,
    contacts,
    workEmployees,
    projects,
    workRows,
    denominations,
    costCentres,
    tableOptions,
    extraInfo,
    tickets
  }) {
    // Do not update empty results to prevent state from changing array references
    yield put(customerActions.fetchSuccess(customers))
    yield put(targetActions.fetchSuccess(targets))
    yield handleEmployeeApiResponse(employeeActions.fetchSuccess, false)(supervisors)
    yield put(contactActions.fetchSuccess(contacts))
    yield put(projectActions.fetchSuccess(projects))
    yield put(ticketActions.fetchSuccess(tickets))
    yield put(denominationActions.fetchSuccess(denominations))
    yield put(costCentreActions.fetchSuccess(costCentres))
    yield put(workActions.employees.fetchSuccess(workEmployees))
    yield put(workActions.workRows.fetchSuccess(workRows))
    yield put(workActions.fetchExtraInfoSuccess(extraInfo))

    yield put(mainAction(data))
    if(tableIdentifier != null && tableOptions?.orderBy != null) {
      yield put(workActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleWorkRowApiResponse = mainAction =>
  function* ({ data, denominations, costCentres }) {
    yield put(mainAction(data))
    yield put(denominationActions.fetchSuccess(denominations))
    yield put(costCentreActions.fetchSuccess(costCentres))
    return data
  }

const watchOnWorkSockets = createSocketWatcherWithGenerator('work', {
  ...getDefaultGeneratorSocketHandlers(workActions, handleWorkApiResponse, normalizeWorkList),
  confirmChanges: function* (data) {
    yield put(showActionPrompt(uiTypes.PROMPT_WORK_CHANGES, data))
  }
})

const watchOnWorkRowSockets = createSocketWatcherWithApiHandlerAndNormalizer('workRow', workActions.workRows, handleWorkRowApiResponse, normalizeWorkRowResponse)

const watchOnWorkEmployeeSockets = createSocketWatcherWithGenerator('workEmployee', {
  created: function* (data) {
    const [[workEmployee]] = normalizeWorkEmployeeResponse(data)
    yield put(workActions.employees.createSuccess(workEmployee))
  },
  updated: function* (data) {
    const [[workEmployee]] = normalizeWorkEmployeeResponse(data)
    yield put(workActions.employees.updateSuccess(workEmployee))
  },
  deleted: function* (data) {
    yield put(workActions.employees.deleteSuccess(data))
  }
})

const watchOnWorkFileSockets = createSocketWatcherWithGenerator('fileLink:work', {
  created: function* (data) {
    const [fileLink, file] = normalizeWorkFileResponse(data)
    yield put(workActions.files.createSuccess(fileLink))
    yield put(fileActions.fetchSuccess(file))
  },
  updated: function* (data) {
    const [fileLink, file] = normalizeWorkFileResponse(data)
    yield put(workActions.files.updateSuccess(fileLink))
    yield put(fileActions.fetchSuccess(file))
  },
  deleted: function* (data) {
    yield put(workActions.files.deleteSuccess(data))
  }
})

const workFetchFlow = fetchFlow({
  fetchApi: fetchWork,
  actions: workActions,
  base: 'work',
  errorMsg: 'Töiden',
  getApiResponseHandler: data => handleWorkApiResponse(workActions.fetchSuccess, data.tableIdentifier)
})

const workUpdateFlow = updateFlow(updateWork, workActions, 'Työ', 'Työn', handleWorkApiResponse(workActions.updateSuccess))
const workCreateFlow = createFlow(createWork, workActions, 'Työ', 'Työn', handleWorkApiResponse(workActions.createSuccess))
const workDeleteFlow = deleteFlow({
  deleteApi: deleteWork,
  actions: workActions,
  singular: 'Työ',
  errorMsg: 'Työn',
  base: 'work'
})

const workSearchFlow = function* workSearchFlow({ searchTerm, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workActions.searchStart())
    const tableOptions = yield select(state => data.tableIdentifier
      ? getWorkTableOptions(state, data.tableIdentifier)
      : initialWorkTableOptions.search
    )
    const apiResponse = yield call(fetchWork, { ...tableOptions, q: searchTerm })
    const work = yield handleWorkApiResponse(workActions.fetchSuccess, data.tableIdentifier || 'search')(apiResponse)
    yield put(workActions.searchSuccess(work, searchTerm))
    yield call(resolve, work)
  } catch(err) {
    yield put(workActions.fetchError(miniSerializeError(err)))
    yield put(workActions.searchError(err, searchTerm))
    yield * genericSagaErrorHandler(err, 'Virhe töiden hakemisessa', reject)
  }
}

const workRowsFetchFlow = fetchFlow({
  fetchApi: workRowApi.fetch,
  actions: workActions.workRows,
  errorMsg: 'Rivien',
  getApiResponseHandler: () => handleWorkRowApiResponse(workActions.workRows.fetchSuccess)
})

const workRowUpdateFlow = updateFlow(workRowApi.update, workActions.workRows, 'Rivi', 'Rivin', handleWorkRowApiResponse(workActions.workRows.updateSuccess))
const workRowCreateFlow = createFlow(workRowApi.create, workActions.workRows, 'Rivi', 'Rivin', handleWorkRowApiResponse(workActions.workRows.createSuccess))
const workRowDeleteFlow = deleteFlow({
  deleteApi: workRowApi.remove,
  actions: workActions.workRows,
  singular: 'Rivi',
  errorMsg: 'Rivin',
  base: 'work.workRows'
})

const fileFlows = getSubFilesSagas(workActions, fetchWorkFiles, createWorkFile, deleteWorkFile, updateWorkFile, 'workId', 'work', {
  base: 'työn',
  singular: 'Työn tiedosto',
  accusative: 'Työn tiedoston',
  fetchError: 'Virhe työn tiedostojen noutamisessa',
  deleteSuccess: 'Työn tiedostolinkki poistettu',
  deleteError: 'Virhe työn tiedostolinkin poistamisessa'
})

function* createWorkRowsFromInboundInvoiceRowsFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workActions.createWorkRowsFromInboundInvoiceRowsStart())
    const { data: workRows } = yield call(createWorkRowsFromInboundInvoiceRows, record, { workId: data.workId })
    yield put(workActions.createWorkRowsFromInboundInvoiceRowsSuccess(workRows))
    yield put(workActions.workRows.fetchSuccess(workRows))
    addSuccessNotification('Ostolaskurivit edelleenlaskutettu')
    yield call(resolve, workRows)
  } catch(err) {
    yield put(workActions.createWorkRowsFromInboundInvoiceRowsError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe ostolaskurivien edelleenlaskutuksessa', reject)
  }
}

function* createWorkRowsFromWorkRecordsFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workRecordActions.setBusy(true))
    yield put(workActions.createWorkRowsFromWorkRecordsStart())
    const { data: workRows } = yield call(createWorkRowsFromWorkRecords, record, data)
    yield put(workActions.createWorkRowsFromWorkRecordsSuccess(workRows))
    yield put(workActions.workRows.fetchSuccess(workRows))
    addSuccessNotification(`${workRows.length} riviä luotu`)
    yield call(resolve, workRows)
    yield put(workRecordActions.setBusy(false))
  } catch(err) {
    yield put(workActions.createWorkRowsFromWorkRecordsError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe työtehtävien laskutuksessa', reject)
  }
}

function* createWorkRowsFromMachineRecordsFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(machineRecordActions.setBusy(true))
    yield put(workActions.createWorkRowsFromMachineRecordsStart())
    const { data: workRows } = yield call(createWorkRowsFromMachineRecords, record, data)
    yield put(workActions.createWorkRowsFromMachineRecordsSuccess(workRows))
    yield put(workActions.workRows.fetchSuccess(workRows))
    addSuccessNotification(`${workRows.length} riviä luotu`)
    yield call(resolve, workRows)
    yield put(machineRecordActions.setBusy(false))
  } catch(err) {
    yield put(workActions.createWorkRowsFromMachineRecordsError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, `Virhe ${machineRecordTitles.pluralGenetive} laskutuksessa`, reject)
  }
}

function* createWorkTicketFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(ticketActions.setBusy(true))
    const { data: ticket } = yield call(createWorkTicket, record, data)
    yield put(ticketActions.createSuccess(ticket))
    addSuccessNotification(`${capitalize(ticketTitles.basic)} luotu`)
    yield call(resolve, ticket)
    yield put(ticketActions.setBusy(false))
  } catch(err) {
    yield * genericSagaErrorHandler(err, `Virhe ${ticketTitles.genetive} luonnissa`, reject)
  }
}

const workPatchActionFlow = createActionFlow(doWorkPatchAction, workActions, handleWorkApiResponse)
const workPostActionFlow = createActionFlow(doWorkPostAction, workActions, handleWorkApiResponse)

const workRowPutActionFlow = createActionFlow(workRowApi.update, workActions.workRows, handleWorkRowApiResponse)
const workRowPostActionFlow = createActionFlow(workRowApi.create, workActions.workRows, handleWorkRowApiResponse)

function* workShowMoreSearchResults() {
  try {
    const { q, limit, ids } = yield select(state => state.work.tableOptions.search)
    yield put(workActions.tableActions.updateOptions({ q, ids, limit }, 'search'))
    yield put(navigateTo(PATH_WORK_SEARCH_RESULTS))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Toiminto epäonnistui')
  }
}

const employeeFlows = getSubEntitySagas(workActions, employeeActions, fetchWorkEmployees, createWorkEmployee, deleteWorkEmployee, 'workId', 'employeeId', 'work', 'employees', {
  singular: 'Työn työntekijöiden',
  accusative: 'Työn työntekijöiden',
  fetchError: 'Virhe työn työntekijöiden noutamisessa',
  deleteSuccess: 'Työn työntekijä poistettu',
  deleteError: 'Virhe työn työntekijöiden poistamisessa'
}, function* ([workEmployees, employees]) {
  yield put(workActions.employees.fetchSuccess(workEmployees))
  yield put(employeeActions.fetchSuccess(employees.map(employee => {
    const { _embedded, ...employeeData } = employee // eslint-disable-line no-unused-vars
    return employeeData
  })))
})

function* workEmployeeUpdateFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const [workEmployeeData] = yield call(updateWorkEmployee, record)
    yield put(workActions.employees.updateSuccess(workEmployeeData))
    addSuccessNotification('Työn työntekijä päivitetty')
    yield call(resolve, workEmployeeData)
  } catch(err) {
    yield put(workActions.employees.updateError(err, record))
    yield * genericSagaErrorHandler(err, 'Virhe työn työntekijän muokkaamisessa', reject)
  }
}

const updateWorkRowRankFlow = updateRowRankFlow(workRowApi.update, workActions.workRows, 'work.workRows')

function* summaryStatsFetchFlow({ data = {}, record }) {
  const workId = data?.workId || record?.workId
  try {
    yield put(workActions.summaryStatsFetchStart())
    const summaryStats = yield call(fetchWorkSummaryStats, { workId })
    yield put(workActions.summaryStatsFetchSuccess(summaryStats))
  } catch(err) {
    yield put(workActions.summaryStatsFetchError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe työn tilaston noutamisessa')
  }
}

function* instalmentRowImportFlow({ workId, files }) {
  try {
    addSuccessNotification('Tiedosto(je)n käsittely aloitettu')
    const { data: workRows } = yield call(importInstalmentRowsFromFile, workId, files)
    yield put(workActions.workRows.fetchSuccess(workRows))
    const [invoicedRows, openRows] = workRows.reduce((acc, row) => {
      acc[0] += row.isInvoiced ? 1 : 0
      acc[1] += row.isInvoiced ? 0 : 1
      return acc
    }, [0, 0])

    addSuccessNotification('Maksuerien tuonti valmis.')
    addInfoNotification(`Laskutettuja eriä ${invoicedRows} kpl. Avoimia ${openRows} kpl`)
  } catch(err) {
    yield * genericSagaErrorHandler(err, appendEitherOrEmpty('Virhe maksuerien tuonnissa', err.json?.message))
  }
}

const workSystemMessageSagas = getSystemMessageSagas({
  actions: workActions,
  baseName: 'work',
  socketName: 'work',
  titleGenetive: 'lähetteen'
})

const memoSagas = getMemoSagas({
  actions: workActions,
  baseName: 'work',
  socketName: 'work',
  titleGenetive: 'lähetteen'
})

const workInvoicingRulesFetchFlow = fetchFlow({
  fetchApi: fetchWorkInvoicingRules,
  actions: workActions.workInvoicingRules,
  errorMsg: 'Laskutussääntöjen'
})

const workInvoicingRulesUpdateFlow = updateFlow(
  updateWorkInvoicingRule,
  workActions.workInvoicingRules,
  'Laskutussääntö',
  'Laskutussääntöjen'
)

function* doWorkActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(doWorkAction, record, data)
    addSuccessNotification('Toiminto onnistui')
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, appendValuesOrEmpty(['Toiminto epäonnistui', err?.json?.message], ': '), reject)
  }
}

export default function* workSaga() {
  yield takeEvery(workActions.actionTypes.fetchRequest, workFetchFlow)
  yield takeEvery(workActions.actionTypes.updateRequest, workUpdateFlow)
  yield takeEvery(workActions.actionTypes.createRequest, workCreateFlow)
  yield takeEvery(workActions.actionTypes.deleteRequest, workDeleteFlow)
  yield takeLatest(workActions.actionTypes.searchRequest, workSearchFlow)

  yield takeLatest(workActions.workRows.actionTypes.fetchRequest, workRowsFetchFlow)
  yield takeEvery(workActions.workRows.actionTypes.updateRequest, workRowUpdateFlow)
  yield takeEvery(workActions.workRows.actionTypes.createRequest, workRowCreateFlow)
  yield takeEvery(workActions.workRows.actionTypes.deleteRequest, workRowDeleteFlow)

  yield takeLatest(workActions.workRows.actionTypes.updateRankRequest, updateWorkRowRankFlow)

  yield takeLatest(workActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(workActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(workActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)
  yield takeLatest(workActions.files.actionTypes.updateRequest, fileFlows.subUpdateFlow)

  yield takeLatest(workActions.employees.actionTypes.fetchRequest, employeeFlows.subFetchFlow)
  yield takeEvery(workActions.employees.actionTypes.createRequest, employeeFlows.subCreateFlow)
  yield takeLatest(workActions.employees.actionTypes.deleteRequest, employeeFlows.subDeleteFlow)
  yield takeLatest(workActions.employees.actionTypes.updateRequest, workEmployeeUpdateFlow)

  yield takeEvery(actionTypes.WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_REQUEST, createWorkRowsFromInboundInvoiceRowsFlow)
  yield takeLeading(workActions.actionTypes.createWorkRowsFromWorkRecordsRequest, createWorkRowsFromWorkRecordsFlow)
  yield takeLeading(workActions.actionTypes.createWorkRowsFromMachineRecordsRequest, createWorkRowsFromMachineRecordsFlow)
  yield takeLeading(workActions.actionTypes.createWorkTicketRequest, createWorkTicketFlow)

  yield takeEvery(actionTypes.WORK_PATCH_ACTION_REQUEST, workPatchActionFlow)
  yield takeEvery(actionTypes.WORK_POST_ACTION_REQUEST, workPostActionFlow)

  yield takeEvery(actionTypes.WORK_ROW_PUT_ACTION_REQUEST, workRowPutActionFlow)
  yield takeEvery(actionTypes.WORK_ROW_POST_ACTION_REQUEST, workRowPostActionFlow)

  yield takeLatest(actionTypes.WORK_SHOW_MORE_SEARCH_RESULTS_REQUEST, workShowMoreSearchResults)

  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, workActions.workRows.actionTypes.fetchRequest, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, workActions.workRows.actionTypes.updateRequest, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, workActions.workRows.actionTypes.createRequest, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, workActions.workRows.actionTypes.deleteRequest, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, actionTypes.WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_REQUEST, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, actionTypes.WORK_ROW_PUT_ACTION_REQUEST, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, workActions.actionTypes.createWorkRowsFromWorkRecordsRequest, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, workActions.actionTypes.createWorkRowsFromMachineRecordsRequest, summaryStatsFetchFlow)
  yield debounce(WORK_SUMMARY_STATS_FETCH_DELAY, actionTypes.WORK_ROW_POST_ACTION_REQUEST, summaryStatsFetchFlow)

  yield takeLatest(workActions.workInvoicingRules.actionTypes.fetchRequest, workInvoicingRulesFetchFlow)
  yield takeEvery(workActions.workInvoicingRules.actionTypes.updateRequest, workInvoicingRulesUpdateFlow)

  yield takeLatest(actionTypes.WORK_INSTALMENT_IMPORT_ROWS_REQUEST, instalmentRowImportFlow)

  yield takeEvery(actionTypes.WORK_ACTION_REQUEST, doWorkActionFlow)

  yield all([
    workSystemMessageSagas(),
    memoSagas(),
    watchOnWorkSockets(),
    watchOnWorkRowSockets(),
    watchOnWorkEmployeeSockets(),
    watchOnWorkFileSockets()
  ])
}
