import moment, { dateFormats, formatOrNull } from '@evelia/common/dateHelpers'
import { round8decimals } from '@evelia/common/helpers'
import { locale } from '@evelia/common/yup/locale'
import yupMomentValidator from '@evelia/common/yup/yupMomentValidator'
import isNumber from 'lodash/isNumber'
import * as yup from 'yup'

import '@evelia/common/yup/yupObjectValidatorExtensions.js'
import { getStringOrNull } from '../../helpers/helpers'

const safeNumber = (value: number, originalValue: string | number) => {
  if(originalValue == null || originalValue === '') {
    return null
  }
  if(isNaN(value)) {
    if(isNumber(originalValue)) {
      return isNaN(originalValue) || !isFinite(originalValue) ? null : originalValue
    }

    return round8decimals(originalValue.replace(/,/, '.'))
  }
  return round8decimals(value)
}

yup.setLocale(locale)

export const objectValidator = <T extends yup.ObjectShape>(schema: T, label?: string) => label ? yup.object().label(label).shape(schema) : yup.object().shape(schema)

export const arrayValidator = (ofSchema: yup.ISchema<unknown> = yup.mixed(), label?: string) => label ? yup.array().label(label).of(ofSchema) : yup.array().of(ofSchema)

export const stringValidators = {
  default: (label: string) => yup.string().label(label),
  nullable: (label: string) => yup.string().label(label).nullable(),
  required: (label: string) => yup.string().label(label).required(),
  nullableRequired: (label: string) => yup.string().label(label).nullable().required(),
  safe: (label: string) => yup.string().label(label).transform(getStringOrNull),
  safeNullable: (label: string) => yup.string().label(label).transform(getStringOrNull).nullable(),
  safeRequired: (label: string) => yup.string().label(label).transform(getStringOrNull).nullable().required(),
  oneOf: (label: string, ...options: string[]) => yup.string().label(label).oneOf(options)
}

export const emailValidators = {
  default: (label: string) => stringValidators.safe(label).email(),
  nullable: (label: string) => stringValidators.safeNullable(label).email(),
  required: (label: string) => stringValidators.safeRequired(label).email()
}

export const dateValidators = {
  default: (label: string) => yupMomentValidator().label(label),
  nullable: (label: string) => yupMomentValidator().label(label).nullable()
}

const timeSchema = yup
  .mixed()
  .transform(value => {
    // Tried to follow yup's example of creating mixed schema with type checking
    // but it throws ctx.typeCheck is not defined https://github.com/jquense/yup#mixed
    if(moment.isMoment(value) || moment(value, [moment.ISO_8601]).isValid()) {
      return formatOrNull(value, dateFormats.isoTimeFormat)
    } else if(moment(value, [dateFormats.isoTimeFormat]).isValid()) {
      return value
    }
    return null
  })
export const timeValidators = {
  default: (label: string) => timeSchema.label(label),
  nullable: (label: string) => timeSchema.label(label).nullable()
}

export const numberValidators = {
  default: (label: string) => yup.number().label(label),
  nullable: (label: string) => yup.number().label(label).nullable(),
  required: (label: string) => yup.number().label(label).required(),
  nullableRequired: (label: string) => yup.number().label(label).nullable().required(),
  safe: (label: string) => yup.number().label(label).transform(safeNumber),
  safeNullable: (label: string) => yup.number().label(label).transform(safeNumber).nullable(),
  safeRequired: (label: string) => yup.number().label(label).transform(safeNumber).nullable().required(),
  positive: (label: string) => yup.number().label(label).transform(safeNumber).min(0), // .positive() doesn't include zero
  negative: (label: string) => yup.number().label(label).transform(safeNumber).max(0)
}

export const booleanValidators = {
  default: (label: string) => yup.boolean().label(label),
  nullable: (label: string) => yup.boolean().label(label).nullable(),
  required: (label: string) => yup.boolean().label(label).nullable().required()
}

export const idValidators = {
  default: (label: string) => numberValidators.positive(label).integer().nullable(),
  required: (label: string) => numberValidators.positive(label).integer().nullable().required()
}
