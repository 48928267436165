import { genericSendStatuses, salaryCalculationStates } from '@evelia/common/constants'
import { ValueOf } from 'type-fest'

import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { BaseIdModel, NullableId } from './types/baseModelTypes'

export type SalaryPeriodModel = {
  salaryGroupId: number | null
  paymentDate: string | null
  startDate: string
  endDate: string
  state: ValueOf<typeof salaryCalculationStates>
} & BaseIdModel

export type SalaryCalculationModel = {
  salaryPeriodId: number
  employmentId: number
  state: ValueOf<typeof salaryCalculationStates>
  incomesRegisterState: ValueOf<typeof genericSendStatuses> | null
  inboundInvoiceId: number | null
} & BaseIdModel

export type SalaryCalculationRowModel = {
  salaryCalculationId: number
  salaryTypeId: number | null
  salaryEventId: number | null
  systemType: number | null
  amount: number | null
  price: number | null
  priceSum: number | null
  socialCosts: number
  message: string | null
} & BaseIdModel

export type SalaryCalculationRowBaseModel = NullableId<SalaryCalculationRowModel>

export const defaultSalaryCalculationRowModel: SalaryCalculationRowBaseModel = {
  id: null,
  salaryCalculationId: 0,
  salaryTypeId: null,
  salaryEventId: null,
  systemType: null,
  amount: null,
  price: null,
  priceSum: null,
  socialCosts: 0,
  message: null
}

export const salaryPeriodApi = createApi({
  base: 'salary_periods',
  normalizer: defaultEmbeddedNormalizer
})

const getSalaryCalculationParams = (params: { salaryPeriodId: number, id: number }, model: SalaryCalculationModel) => ({
  ...params,
  id: model?.salaryPeriodId ?? params.salaryPeriodId,
  subItem: 'salary_calculations',
  subItemId: params.id
})

export const salaryCalculationApi = createApi({
  base: 'salary_periods',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getSalaryCalculationParams,
  editMutateParams: getSalaryCalculationParams
})

const getSalaryCalculationRowParams = (params: { salaryPeriodId: number, salaryCalculationId: number, id: number }, model: SalaryCalculationRowModel) => ({
  ...params,
  id: params.salaryPeriodId,
  subItem: 'salary_calculations',
  subItemId: model?.salaryCalculationId ?? params.salaryCalculationId,
  subItemAction: 'rows',
  salaryCalculationRowId: model?.id ?? params.id
})

export const salaryCalculationRowApi = createApi({
  base: 'salary_periods',
  extraUrlTemplate: '{/salaryCalculationRowId}',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getSalaryCalculationRowParams,
  editMutateParams: getSalaryCalculationRowParams
})
