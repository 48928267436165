import { castToArray } from '@evelia/common/helpers'
import flatMap from 'lodash/flatMap'
import { parseTemplate } from 'url-template'

import {
  createApi,
  del,
  get,
  getSubEntityApi,
  post,
  put
} from '../helpers/apiHelpers'
import { parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { queryWildcard } from './urlTemplates'
import { normalizeWorkList } from './workApi'

const baseUrl = '/api/employees{/employeeId}'

const employeesUrlTemplate = parseTemplate(`${baseUrl}{?q}`)
const employeeLevelsUrlTemplate = parseTemplate(`${baseUrl}/levels{/employeeLevelId}`)
const employeeWorkUrlTemplate = parseTemplate(`${baseUrl}/work`)
const employeeLicencesUrlTemplate = parseTemplate(`${baseUrl}/employee_licences{/employeeLicenceId}`)
const employeeFileUrlTemplate = parseTemplate(`${baseUrl}/files{/employeeFileId}`)

const employeeSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemType,subItemId}' + queryWildcard)

export const fetchEmployee = (params = {}) =>
  get(employeesUrlTemplate.expand(params))
    .then(normalizeEmployeeResponse)

export const updateEmployee = employees =>
  put(employeesUrlTemplate.expand({ employeeId: employees.id }), {
    body: JSON.stringify(employees)
  }).then(normalizeEmployeeResponse)

export const createEmployee = employees =>
  post(employeesUrlTemplate.expand({}), {
    body: JSON.stringify(employees)
  }).then(normalizeEmployeeResponse)

export const deleteEmployee = employee =>
  del(employeesUrlTemplate.expand({ employeeId: employee.id }))

export const searchEmployees = search =>
  get(employeesUrlTemplate.expand({ q: search }))
    .then(normalizeEmployeeResponse)

export const fetchEmployeeLevels = (params = {}) =>
  get(employeeLevelsUrlTemplate.expand(params))

// EMPLOYEE INVITATIONS

export const employeeInvitationApi = createApi({
  base: 'employees/invitations'
})

export const fetchEmployeeWork = (params = {}) =>
  get(employeeWorkUrlTemplate.expand(params))
    .then(response => [response.record || response.records, response._embedded.employees])

// Mutates original employee
const separateEmployeeRoles = employee => {
  const { employeeRoles } = employee._embedded || {}
  delete employee._embedded
  return employeeRoles
}

export const normalizeEmployeeResponse = response => {
  const employees = castToArray(response)
  const employeeRoles = flatMap(employees, separateEmployeeRoles)
  return [employees, employeeRoles]
}

// EMPLOYEE LICENCES

export const fetchEmployeeLicences = (params = {}) =>
  get(employeeLicencesUrlTemplate.expand(params))

export const updateEmployeeLicence = (employeeLicence, params) =>
  put(employeeLicencesUrlTemplate.expand({ ...params, employeeLicenceId: employeeLicence.id }), {
    body: JSON.stringify(employeeLicence)
  })

export const createEmployeeLicence = (employeeLicence, params) =>
  post(employeeLicencesUrlTemplate.expand(params), {
    body: JSON.stringify(employeeLicence)
  })

export const deleteEmployeeLicence = (employeeLicence, params) =>
  del(employeeLicencesUrlTemplate.expand({ ...params, employeeLicenceId: employeeLicence.id }))

// EMPLOYEE FILES

export const {
  fetchApi: fetchEmployeeFiles,
  createApi: createEmployeeFiles,
  deleteApi: deleteEmployeeFiles
} = getSubEntityApi(employeeFileUrlTemplate, 'employeeId', 'fileId', 'employeeFileId', 'file')

export const fetchEmployeeSalaryEvents = (params = {}) =>
  get(employeeSubItemUrlTemplate.expand({ ...params, subItemType: 'salary_events' }))
    .then(response => {
      const {
        work,
        options
      } = response._embedded
      return {
        data: response.record || response.records,
        work: normalizeWorkList(work),
        tableOptions: parseTableOptionsFromQuery(options)
      }
    })
