import { castToArray } from '@evelia/common/helpers'
import { parseTemplate } from 'url-template'

import {
  defaultEmbeddedNormalizer,
  defaultNormalizer,
  del,
  get,
  getSubApi,
  getSubEntityApi,
  patch,
  post,
  put,
  upload
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { normalizeEmployeeResponse } from './employeeApi'
import { optionsQueryBase, optionsQueryTerms, queryTermsToTemplate } from './urlTemplates'

const baseUrl = '/api/offers{/offerId}'
const actionUrl = `${baseUrl}{/action}${queryTermsToTemplate([...optionsQueryTerms, 'showExtraInfo'])}`
const offerPostBaseUrl = baseUrl + '/posts{/offerPostId}'
const offerPostActionUrl = `${offerPostBaseUrl}{/action}${optionsQueryBase}`

const offerUrlTemplate = parseTemplate(actionUrl)
const offerFileUrlTemplate = parseTemplate(baseUrl + '/files{/offerFileId}')
const offerPostUrlTemplate = parseTemplate(offerPostActionUrl + '{/subItem,subItemType,subItemId}')
const offerSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemType,subItemId}')

const rowPart = '/rows{/offerPostRowId,action}'
const offerPostRowsUrlTemplate = parseTemplate(offerPostBaseUrl + rowPart)
const offerRowsUrlTemplate = parseTemplate(baseUrl + rowPart)

export const normalizeOffers = response => {
  const { _embedded, records, record } = response
  const {
    customers,
    targets,
    projects,
    contacts,
    employees,
    extraInfo,
    options
  } = _embedded || {}
  const data = records || record
  return {
    data,
    customers,
    targets,
    projects,
    contacts,
    employees: normalizeEmployeeResponse(employees),
    tableOptions: parseTableOptionsFromQuery(options),
    extraInfo: extraInfo || []
  }
}

export const fetchOffers = (params = {}) => {
  const filterParams = parseFilterParams(params)
  const url = offerUrlTemplate.expand(filterParams)
  return get(url)
    .then(normalizeOffers)
}

export const updateOffer = offer => {
  return put(offerUrlTemplate.expand({ offerId: offer.id }), {
    body: JSON.stringify(offer)
  }).then(normalizeOffers)
}

export const createOffer = offer => {
  return post(offerUrlTemplate.expand({}), {
    body: JSON.stringify(offer)
  }).then(normalizeOffers)
}

export const deleteOffer = (offer, params) =>
  del(offerUrlTemplate.expand({ offerId: offer.id, ...params }))

export const searchOffers = search => {
  return get(offerUrlTemplate.expand({ q: search }))
    .then(normalizeOffers)
}

export const {
  fetchApi: fetchOfferPosts,
  createApi: createOfferPost,
  updateApi: updateOfferPost,
  deleteApi: deleteOfferPost
} = getSubApi(offerPostUrlTemplate, 'offerPostId', defaultNormalizer)

export const fileApi = getSubEntityApi(offerFileUrlTemplate, 'offerId', 'fileId', 'offerFileId', 'file')

export const {
  fetchApi: fetchOfferPostRows,
  createApi: createOfferPostRow,
  updateApi: updateOfferPostRow,
  deleteApi: deleteOfferPostRow
} = getSubApi(params => (params.offerPostId ? offerPostRowsUrlTemplate : offerRowsUrlTemplate).expand(params), 'offerPostRowId', defaultNormalizer)

export const doOfferPostRowPostAction = (data = {}, actionData = {}) =>
  post(offerPostRowsUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  }).then(defaultNormalizer)

export const generateOfferPostRowReport = params =>
  post(offerSubItemUrlTemplate.expand(params), {
    body: JSON.stringify({})
  })

export const doOfferAction = (record = {}, actionData = {}) => {
  const template = actionData.offerPostId ? offerPostUrlTemplate : offerSubItemUrlTemplate
  return post(template.expand(actionData), {
    body: JSON.stringify(record || {})
  }).then(defaultEmbeddedNormalizer)
}

export const fetchOfferDefaults = () =>
  get(offerSubItemUrlTemplate.expand({ subItem: 'defaults' }))

export const updateOfferDefaults = record =>
  put(offerSubItemUrlTemplate.expand({ subItem: 'defaults' }), {
    body: JSON.stringify(record)
  })

export const doOfferPatchAction = (data, { offerId, action } = {}) => {
  return patch(offerUrlTemplate.expand({ offerId, action }), {
    body: JSON.stringify(data || {})
  }).then(normalizeOffers)
}

export const doOfferPostPatchAction = (data, { offerId, offerPostId, action } = {}) => {
  return patch(offerPostUrlTemplate.expand({ offerId, offerPostId, action }), {
    body: JSON.stringify(data || {})
  }).then(defaultNormalizer)
}

export const importPostRowsFromFile = (offerId, file, subItemType) => {
  const formData = new FormData()
  castToArray(file).forEach(file => formData.append('postRows', file))
  const uploadUrl = offerSubItemUrlTemplate.expand({ offerId, subItem: 'imports', subItemType })
  return upload(uploadUrl, {
    body: formData
  }).then(defaultEmbeddedNormalizer)
}

export const doOfferPostRowPutAction = (data = {}, actionData = {}) =>
  put(offerPostRowsUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  }).then(defaultNormalizer)
