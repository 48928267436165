import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'

import FormPlainSelectInput from './FormPlainSelectInput'

const FormSelectInput = ({
  control,
  name,
  errors,
  label,
  labelClassName,
  inputProps,
  inputOnly,
  required,
  multiple,
  placeholder,
  disabled,
  options,
  tooltipError,
  labelKey,
  valueKey,
  formGroupClassName,
  positionFixed,
  typeaheadProps,
  onValueChange,
  isCustomAddon,
  invalid,
  tooltip,
  helpText,
  inline
}) => {
  const { field, formState } = useController({ name, control })
  const inputErrors = formState.errors

  return (
    <FormPlainSelectInput
      name={name}
      options={options}
      // ref={field.ref}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      errors={errors || inputErrors}
      label={label}
      labelClassName={labelClassName}
      inputOnly={inputOnly}
      required={required}
      multiple={multiple}
      placeholder={placeholder}
      disabled={disabled}
      tooltipError={tooltipError}
      labelKey={labelKey}
      valueKey={valueKey}
      formGroupClassName={formGroupClassName}
      positionFixed={positionFixed}
      typeaheadProps={typeaheadProps}
      onValueChange={onValueChange}
      isCustomAddon={isCustomAddon}
      invalid={invalid}
      tooltip={tooltip}
      helpText={helpText}
      inline={inline}
      inputProps={inputProps}
    />
  )
}

FormSelectInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  inputProps: PropTypes.object,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.node,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  tooltipError: PropTypes.bool,
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  valueKey: PropTypes.string,
  formGroupClassName: PropTypes.string,
  positionFixed: PropTypes.bool,
  typeaheadProps: PropTypes.object,
  onValueChange: PropTypes.func,
  isCustomAddon: PropTypes.bool,
  invalid: PropTypes.bool,
  tooltip: PropTypes.node,
  helpText: PropTypes.node,
  inline: PropTypes.bool
}

export default FormSelectInput
