import { Control, FieldValues, Path, useController } from 'react-hook-form'

import FormPlainCheckboxInput from './FormPlainCheckboxInput'

interface FormCheckboxInputProps<T extends FieldValues> extends React.ComponentProps<typeof FormPlainCheckboxInput>{
  control: Control<T>
  name: Path<T>
}

const FormCheckboxInput = <T extends FieldValues>({
  control,
  errors,
  ...props
}: FormCheckboxInputProps<T>) => {
  const { field, formState } = useController({ name: props.name, control })
  const { value, onChange, ...fieldProps } = field
  const inputErrors = formState.errors
  return (
    <FormPlainCheckboxInput
      {...props}
      value={value}
      errors={errors || inputErrors}
      onChange={onChange}
      inputProps={{
        ...props.inputProps,
        ...fieldProps
      }}
    />
  )
}

export default FormCheckboxInput
