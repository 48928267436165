import { WorkModel } from '@evelia/common/types'
import { Action, createListenerMiddleware, ThunkDispatch } from '@reduxjs/toolkit'
import { createApi, SerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import uniqBy from 'lodash/uniqBy'
import queryString from 'query-string'

import contactActions from '../../actions/contactActions'
import costCentreActions from '../../actions/costCentreActions'
import customerActions from '../../actions/customerActions'
import denominationActions from '../../actions/denominationActions'
import projectActions from '../../actions/projectActions'
import targetActions from '../../actions/targetActions'
import workActions from '../../actions/workActions'
import { DefaultArgs } from '../../components/Kanban/Kanban'
import { getBaseQuery } from './apiHelpers'
import { ApiResponse } from './types/api'

interface WorkKanbanModel {
  records: WorkModel[]
  totalCount: number
  column: string
  columnValue: string | number
}

// Remove unwanted query args from the cache key (https://redux-toolkit.js.org/rtk-query/api/createApi#serializequeryargs-1)
const serializeWorkQueryArgs: SerializeQueryArgs<DefaultArgs, Partial<DefaultArgs>> = data => {
  const newQueryArgs = { ...data.queryArgs as DefaultArgs }
  delete newQueryArgs.page
  delete newQueryArgs.limit
  delete newQueryArgs.extraFilters
  delete newQueryArgs.cacheBust
  delete newQueryArgs.sort
  return newQueryArgs
}

const WORK_DEFAULT_FILTERS = ['isTemplate', false]
const WORK_DEFAULT_LIMIT = 25

export const workApi = createApi({
  reducerPath: 'workApi',
  baseQuery: getBaseQuery('work'),
  endpoints: builder => ({
    getWork: builder.query<ApiResponse<WorkModel>, DefaultArgs>({
      query: query => {
        const {
          mainFilter,
          extraFilters = [],
          page,
          limit,
          sort
        } = query
        const extraFiltersFormatted = extraFilters.flatMap(extraFilter => extraFilter.values.map(value => [extraFilter.field, value]))
        const filters = (extraFiltersFormatted as (string | number | boolean)[][]).concat([WORK_DEFAULT_FILTERS, mainFilter])
        const orderBy = sort?.sortKey
        const sortOrder = sort?.order

        const q = queryString.stringify({ filters, page, limit: limit ?? WORK_DEFAULT_LIMIT, orderBy, sortOrder })
        return `?${q}`
      },
      serializeQueryArgs: serializeWorkQueryArgs,
      merge: (currentCache, newItems, { arg }) => {
        if(currentCache.records && !arg.cacheBust) {
          return {
            // Prevent storing duplicate items
            records: uniqBy([...currentCache.records, ...newItems.records].reverse(), 'id').reverse(),
            _embedded: { ...newItems._embedded }
          }
        }
        return newItems
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.page !== previousArg?.page
      },
      keepUnusedDataFor: Infinity
    })
  })
})

const listenerMiddlewareWork = createListenerMiddleware()

export const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, _embedded: ApiResponse<WorkKanbanModel | WorkModel>['_embedded']) => {
  // Dispatch embedded data into base redux state
  dispatch(customerActions.fetchSuccess(_embedded.customers ?? []))
  dispatch(contactActions.fetchSuccess(_embedded.contacts ?? []))
  dispatch(projectActions.fetchSuccess(_embedded.projects ?? []))
  dispatch(targetActions.fetchSuccess(_embedded.targets ?? []))
  dispatch(costCentreActions.fetchSuccess(_embedded.costCentres ?? []))
  dispatch(denominationActions.fetchSuccess(_embedded.denominations ?? []))
  dispatch(workActions.employees.fetchSuccess(_embedded.workEmployees ?? []))
}

listenerMiddlewareWork.startListening({
  matcher: workApi.endpoints.getWork.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      // Dispatch work into base redux state
      listenerApi.dispatch(workActions.fetchSuccess(action.payload.records))
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const { middleware: workApiMiddleware } = listenerMiddlewareWork
