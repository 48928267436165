import { now } from '@evelia/common/dateHelpers'

export const defaultTableOptions = {
  defaultLimits: [10, 25, 50, 100],
  sortOrder: ['ASC'],
  orderBy: ['id'],
  filters: [],
  totalCount: 0,
  limit: 25,
  page: 0,
  ids: [],
  isBusy: false,
  lastFetched: null
}

const tableReducer = (actionType, initialOptions = {}) => {
  const initialState = Object.entries(initialOptions).reduce((acc, [key, initialOption]) => {
    acc[key] = {
      ...defaultTableOptions,
      ...initialOption
    }
    return acc
  }, {})
  if(!initialState.default) {
    initialState.default = {
      ...defaultTableOptions
    }
  }
  return (state = initialState, action = {}) => {
    switch(action.type) {
      case actionType.toUpperCase() + '_OPTIONS_FETCH_START':
        return {
          ...state,
          [action.identifier]: {
            ...state[action.identifier],
            isBusy: true
          }
        }
      case actionType.toUpperCase() + '_OPTIONS_UPDATE':
        return {
          ...state,
          [action.identifier]: {
            ...state[action.identifier],
            ...action.data,
            lastFetched: now(),
            isBusy: false
          }
        }
      case actionType.toUpperCase() + '_OPTIONS_CLEAR':
        return {
          ...state,
          [action.identifier]: {
            ...initialState[action.identifier],
            isBusy: false
          }
        }
      case actionType.toUpperCase() + '_OPTIONS_CREATE':
        return {
          ...state,
          [action.identifier]: {
            ...defaultTableOptions,
            sortOrder: [],
            orderBy: [],
            limit: null
          }
        }
      default:
        return state
    }
  }
}

export default tableReducer
