import { EVELIA_TOTP_TOKEN_HEADER } from '@evelia/common/constants'
import { parseTemplate } from 'url-template'

import {
  createApi,
  defaultEmbeddedNormalizer,
  del,
  get,
  getSubApi,
  getSubEntityApi,
  patch,
  post,
  put,
  upload
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { receiverInboundInvoiceUrlTemplate } from './receiverApi'
import { optionsQueryTerms } from './urlTemplates'
import { workSubItemUrlTemplate } from './workApi'

const baseUrl = '/api/inbound_invoices{/inboundInvoiceId}'

const inboundInvoiceUrlTemplate = parseTemplate(`${baseUrl}{?${['showExtraInfo', 'fullEdit', ...optionsQueryTerms].join(',')}}`)
const inboundInvoiceRowUrlTemplate = parseTemplate(`${baseUrl}/rows{/inboundInvoiceRowId,action}`)
const inboundInvoiceApprovalUrlTemplate = parseTemplate(`${baseUrl}/approvals{/inboundInvoiceApprovalId}`)
const inboundInvoiceApproverUrlTemplate = parseTemplate(`${baseUrl}/approvers{/inboundInvoiceApproverId}`)
const inboundInvoiceAccountingUrlTemplate = parseTemplate(baseUrl + '/accountings{/inboundInvoiceAccountingId}')
const inboundInvoiceFileUrlTemplate = parseTemplate(baseUrl + '/files{/inboundInvoiceFileId}')
const inboundInvoiceActionTemplate = parseTemplate(baseUrl + '{/action}')
const inboundInvoiceSubItemUrlTemplate = parseTemplate(baseUrl + '{/subItem,subItemType,subItemId}')

export const normalizeInboundInvoices = response => {
  const { _embedded, records, record } = response
  const {
    receivers,
    denominations,
    costCentres,
    options,
    extraInfo
  } = _embedded || {}
  const data = records || record
  return {
    data,
    receivers,
    denominations,
    costCentres,
    extraInfo: extraInfo || [],
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const normalizeInboundInvoiceRowResponse = response => {
  return defaultEmbeddedNormalizer(response)
}

export const fetchInboundInvoices = (params = {}) => {
  return get(getInboundInvoiceFetchUrl(params))
    .then(normalizeInboundInvoices)
}

export const updateInboundInvoice = (inboundInvoice, params = {}) =>
  put(inboundInvoiceUrlTemplate.expand({ ...params, inboundInvoiceId: inboundInvoice.id }), {
    body: JSON.stringify(inboundInvoice)
  }).then(normalizeInboundInvoices)

export const createInboundInvoice = inboundInvoice => {
  return post(inboundInvoiceUrlTemplate.expand({}), {
    body: JSON.stringify(inboundInvoice)
  }).then(normalizeInboundInvoices)
}

export const doInboundInvoicePatchAction = (data, { inboundInvoiceId, action } = {}) =>
  patch(inboundInvoiceActionTemplate.expand({ inboundInvoiceId, action }), {
    body: JSON.stringify(data || {})
  }).then(normalizeInboundInvoices)

export const fetchFromMaventa = ({ inboundInvoiceId } = {}) => {
  return post(inboundInvoiceActionTemplate.expand({ inboundInvoiceId, action: 'fetch_from_maventa' }), {
    body: JSON.stringify({})
  }).then(data => inboundInvoiceId ? [data] : data)
    .then(normalizeInboundInvoices)
}

export const normalizeInboundInvoiceApprovalList = response => {
  const { _embedded, records, record } = response
  const data = records || record
  const {
    employees,
    employee,
    approvers
  } = _embedded || {}
  return {
    data,
    approvers,
    employees: employees ?? employee
  }
}

export const normalizeInboundInvoiceApprovers = response => {
  const { _embedded, records, record } = response
  const data = records || record
  const {
    employees,
    employee,
    approvals
  } = _embedded || {}
  return {
    data,
    approvals,
    employees: employees || (employee ? [employee] : [])
  }
}

export const approveInboundInvoice = (approval, { inboundInvoiceId, token } = {}) => {
  const headers = token && {
    [EVELIA_TOTP_TOKEN_HEADER]: token
  }
  return post(inboundInvoiceActionTemplate.expand(
    { inboundInvoiceId, action: 'approve' }),
  { body: JSON.stringify(approval), headers }
  ).then(normalizeInboundInvoiceApprovalList)
}

const getInboundInvoiceFetchUrl = params => {
  if(params.workId) {
    return workSubItemUrlTemplate.expand({ ...params, subItem: 'inbound_invoices' })
  } else if(params.receiverId) {
    return receiverInboundInvoiceUrlTemplate.expand({ ...params, subItem: 'inbound_invoices' })
  } else {
    const filterParams = parseFilterParams(params)
    return inboundInvoiceUrlTemplate.expand(filterParams)
  }
}

const getInboundInvoiceRowFetchUrl = params => {
  if(params.workId) {
    return workSubItemUrlTemplate.expand({ ...params, subItem: 'inbound_invoice_rows' })
  } else {
    return inboundInvoiceRowUrlTemplate.expand(params)
  }
}

export const {
  fetchApi: fetchInboundInvoiceRows,
  createApi: createInboundInvoiceRow,
  updateApi: updateInboundInvoiceRow,
  deleteApi: deleteInboundInvoiceRow
} = getSubApi(getInboundInvoiceRowFetchUrl, 'inboundInvoiceRowId', normalizeInboundInvoiceRowResponse)

export const doInboundInvoiceRowPutAction = (data = {}, actionData = {}) =>
  put(inboundInvoiceRowUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  }).then(normalizeInboundInvoiceRowResponse)

export const {
  fetchApi: fetchInboundInvoiceApprovals,
  // createApi: createInboundInvoiceApproval,
  // updateApi: updateInboundInvoiceApproval,
  deleteApi: deleteInboundInvoiceApproval
} = getSubApi(inboundInvoiceApprovalUrlTemplate, 'inboundInvoiceApprovalId', normalizeInboundInvoiceApprovalList)

export const {
  fetchApi: fetchInboundInvoiceApprovers,
  createApi: createInboundInvoiceApprover,
  updateApi: updateInboundInvoiceApprover,
  deleteApi: deleteInboundInvoiceApprover
} = getSubApi(inboundInvoiceApproverUrlTemplate, 'inboundInvoiceApproverId', normalizeInboundInvoiceApprovers)

const normalizeInboundInvoiceAccountingList = data => data

export const {
  fetchApi: fetchInboundInvoiceAccountings,
  createApi: createInboundInvoiceAccounting,
  updateApi: updateInboundInvoiceAccounting,
  deleteApi: deleteInboundInvoiceAccounting
} = getSubApi(inboundInvoiceAccountingUrlTemplate, 'inboundInvoiceAccountingId', normalizeInboundInvoiceAccountingList)

export const {
  fetchApi: fetchInboundInvoiceFiles,
  createApi: createInboundInvoiceFile,
  deleteApi: deleteInboundInvoiceFile
} = getSubEntityApi(inboundInvoiceFileUrlTemplate, 'inboundInvoiceId', 'fileId', 'inboundInvoiceFileId', 'file')

export const assignInboundInvoiceRowsToWork = (data, { inboundInvoiceId } = {}) =>
  post(inboundInvoiceRowUrlTemplate.expand({ inboundInvoiceId, action: 'assign_to_work' }), {
    body: JSON.stringify(data)
  }).then(normalizeInboundInvoiceRowResponse)

export const fetchInboundInvoiceStats = () => get(inboundInvoiceActionTemplate.expand({ action: 'stats' }))

export const deleteInboundInvoice = inboundInvoice =>
  del(inboundInvoiceUrlTemplate.expand({ inboundInvoiceId: inboundInvoice.id }))

export const doInboundInvoicePostAction = (record = {}, actionData = {}) =>
  post(inboundInvoiceActionTemplate.expand(actionData), {
    body: JSON.stringify(record || {})
  }).then(normalizeInboundInvoices)

export const importC2BFromFile = ({ files, type }) => {
  const formData = new FormData()
  files.forEach(file => formData.append('c2b', file))
  formData.append('type', type)
  return upload(inboundInvoiceSubItemUrlTemplate.expand({ subItem: 'import', subItemType: 'c2b' }), {
    body: formData
  }).then(normalizeInboundInvoices)
}

export const recurrenceRuleApi = createApi({
  base: 'inbound_invoices',
  editGetParams: params => ({ ...params, subItem: 'recurrence_rules' }),
  editMutateParams: params => ({ ...params, id: params.inboundInvoiceId, subItem: 'recurrence_rules', subItemId: params.id }),
  normalizer: defaultEmbeddedNormalizer
})
