import { WorkModel } from '@evelia/common/types'
import { createListenerMiddleware, ThunkAction, UnknownAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import constant from 'lodash/constant'
import isEqual from 'lodash/isEqual'

import workActions from '../../actions/workActions'
import { ApiResponse, KanbanQuery, KanbanQuickFilter, KanbanQuickFilterCreateBody } from '.'
import { getBaseQuery, kanbanQuery } from './apiHelpers'
import { handleDispatchEmbeddedData, workApi } from './workApi'

type WorkKanbanModel = {
  records: WorkModel[]
  totalCount: number
  mainFilter: string
  column: string | number
}

export type WorkKanbanQuickFilter = KanbanQuickFilter<'work'>

export const workKanbanApi = createApi({
  reducerPath: 'workKanbanApi',
  baseQuery: getBaseQuery('work/kanban'),
  tagTypes: ['quick_filters'],
  endpoints: builder => ({
    getWorkKanban: builder.query<ApiResponse<WorkKanbanModel>, KanbanQuery>({
      query: kanbanQuery,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg?.extraFilters, previousArg?.extraFilters) ||
         !isEqual(currentArg?.sort, previousArg?.sort) ||
         currentArg?.mainFilter !== previousArg?.mainFilter ||
         !isEqual(currentArg?.columns, previousArg?.columns)
      }
    }),
    getWorkKanbanQuickFilters: builder.query<WorkKanbanQuickFilter[], void>({
      query: constant('/quick_filters'),
      providesTags: [{ type: 'quick_filters' }]
    }),
    getWorkKanbanQuickFilter: builder.query<WorkKanbanQuickFilter, number>({
      query: query => `/quick_filters/${query}`,
      providesTags: (__result, __error, id) => [{ type: 'quick_filters', id }]
    }),
    createWorkKanbanQuickFilter: builder.mutation<WorkKanbanQuickFilter, KanbanQuickFilterCreateBody>({
      query: body => ({
        url: '/quick_filters',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'quick_filters' }]
    }),
    updateWorkKanbanQuickFilter: builder.mutation<WorkKanbanQuickFilter, WorkKanbanQuickFilter>({
      query: body => ({
        url: `/quick_filters/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: 'quick_filters' }, { type: 'quick_filters', id }]
    }),
    deleteWorkKanbanQuickFilter: builder.mutation<void, number>({
      query: id => ({
        url: `/quick_filters/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (__result, __error, id) => [{ type: 'quick_filters' }, { type: 'quick_filters', id }]
    })
  })
})

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: workKanbanApi.endpoints.getWorkKanban.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      listenerApi.dispatch(workApi.util.resetApiState())
      for(const record of records) {
        const { mainFilter, column } = record
        const data: ApiResponse<WorkModel> = {
          records: record.records,
          _embedded: {
            options: {
              totalCount: record.totalCount
            }
          }
        }
        const upsertQueryData = workApi.util.upsertQueryData('getWork', { mainFilter: [mainFilter, column], cacheBust: true }, { ...data })
        // Something weird happens here with typescript
        listenerApi.dispatch(upsertQueryData as ThunkAction<unknown, unknown, unknown, UnknownAction>)
        listenerApi.dispatch(workActions.fetchSuccess(record.records))
      }
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const { middleware: workKanbanApiMiddleware } = listenerMiddleware
export const {
  useGetWorkKanbanQuery,
  useGetWorkKanbanQuickFiltersQuery,
  useCreateWorkKanbanQuickFilterMutation,
  useGetWorkKanbanQuickFilterQuery,
  useDeleteWorkKanbanQuickFilterMutation,
  useUpdateWorkKanbanQuickFilterMutation
} = workKanbanApi
