import { useCallback } from 'react'
import { Control, Path, useController } from 'react-hook-form'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import noop from 'lodash/noop'

import './formThemeColorInput.scss'
import useToggle from '../../hooks/useToggle'
import IconButton from '../Buttons/IconButton'
import ColorBadge from '../misc/ColorBadge'

const themeColors = [
  'primary',
  'secondary',
  'success',
  'warning',
  'info',
  'danger'
]

interface FormThemeColorInput<TFieldValues extends Record<string, unknown>, TContext> {
  control: Control<TFieldValues, TContext>
  name: Path<TFieldValues>
  disabled?: boolean
  setNull?: boolean
}

const FormThemeColorInput = <TFieldValues extends Record<string, unknown>, TContext>({
  control,
  name,
  disabled,
  setNull
}: FormThemeColorInput<TFieldValues, TContext>) => {
  const [displayColorPicker, { toggle, disable: closePicker }] = useToggle(false)
  const { field } = useController({ name, control })
  const { value, onChange } = field

  const handleChange = (color: string) => () => {
    onChange(color)
    closePicker()
  }

  const resetValue = useCallback(() =>
    onChange(null)
  , [onChange])

  return (
    <div className='d-flex align-items-center h-100'>
      <Dropdown isOpen={displayColorPicker} toggle={disabled ? noop : toggle} data-testid='themeColorPicker'>
        <DropdownToggle
          tag='span'
          data-toggle='dropdown'
          aria-expanded={displayColorPicker}
          className={clsx(!disabled && 'cursor-pointer')}
        >
          <ColorBadge color={value as string} className={clsx(!disabled && 'bg-white')} />
        </DropdownToggle>
        <div className='theme-color-dropdown-container'>
          <DropdownMenu className={clsx(displayColorPicker && 'd-flex flex-column')} style={{ minWidth: 'fit-content' }}>
            {themeColors.map(color => (
              <ColorBadge
                key={color}
                color={color}
                onClick={handleChange(color)}
                className='m-1 mx-2'
                style={{ width: 'fit-content' }}
                data-testid={`themeColorPicker_${color}`}
              />
            ))}
          </DropdownMenu>
        </div>
      </Dropdown>
      {setNull && (
        <IconButton
          onClick={resetValue}
          icon={faXmark}
          size='xs'
          color='link'
          className='text-muted'
        />
      )}
    </div>
  )
}

export default FormThemeColorInput
