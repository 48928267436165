import { sortById } from '@evelia/common/helpers'
import { createSelector } from 'reselect'

import { mapWorkTypes } from '../helpers/helpers'

export const getTicketsStatesFromArgument = arg => arg.ticketStates ? arg.ticketStates.records : arg

export const findTicketStateOptions = createSelector(
  getTicketsStatesFromArgument,
  ticketStates => mapWorkTypes(ticketStates.sort(sortById), state => state.id)
)

export const getTicketStateSelectMap = createSelector(
  getTicketsStatesFromArgument,
  ticketStates => ticketStates.reduce((acc, ticketState) => ({
    ...acc,
    [ticketState.id]: {
      value: ticketState.id,
      text: ticketState.name,
      color: ticketState.color
    }
  }), {})
)
