import { workRowTypes } from '@evelia/common/constants'
import { createCachedSelector } from 're-reselect'

import { workStates, workSystemTypes } from '../constants'
import { isNewlyCreated } from '../helpers/helpers'
import { findCustomerWithId, getCustomersFromArgument } from './customerSelectors'
import { getInvoicedWorkRows, getOpenWorkRows } from './workRowSelectors'
import { findWorkWithId } from './workSelectors'

export const getWorkViewStatuses = createCachedSelector(
  findWorkWithId,
  getOpenWorkRows,
  getInvoicedWorkRows,
  getCustomersFromArgument,
  (work, openWorkRows, invoicedWorkRows, customers) => {
    const customer = findCustomerWithId(customers, work?.customerId)

    const hasUnsavedWorkRows = openWorkRows.some(isNewlyCreated)
    const hasOnlyNotInvoicableRows = openWorkRows.every(row => row.preventInvoicing || row.type === workRowTypes.WORK_ROW_TYPE_COMMENT)
    const customerIsSalesProhibited = customer?.isSalesProhibited
    const disableAll = !work || isNewlyCreated(work) || work.isClosed || hasUnsavedWorkRows || customerIsSalesProhibited
    const canCreateInvoice = !disableAll && !!openWorkRows.length && !hasOnlyNotInvoicableRows && !!work.customerId && !customerIsSalesProhibited
    const canBeDeleted = !disableAll && !invoicedWorkRows.length && work.state === workStates.WORK_STATE_CREATED
    const canBeClosed = !disableAll && work.type === workSystemTypes.WORK_TYPE_DEFAULT
    return {
      vatType: work?.vatType,
      isTemplate: !!work?.isTemplate,
      isClosed: !!work?.isClosed,
      isVatPrimary: !!work?.isVatPrimary,
      isProject: work?.type === workSystemTypes.WORK_TYPE_PROJECT,
      disableAll,
      canCreateInvoice,
      canBeDeleted,
      canBeClosed,
      customerIsSalesProhibited,
      isFixedPrice: !!work?.isFixedPrice,
      defaultDenominationId: work?.defaultDenominationId ?? null,
      defaultCostCentreId: work?.defaultCostCentreId ?? null
    }
  }
)((__state, workId) => `${workId}`)
