import { ColumnMeta, flexRender, Header } from '@tanstack/react-table'
import { clsx } from 'clsx'
import isString from 'lodash/isString.js'

import { dataTableGlobalFilterPropType, dataTableHeaderPropType } from '../../propTypes'
import { sortingIndicators } from './dataTableHelpers'

interface DataTableHeaderProps<TData, TValue> {
  header: Header<TData, TValue>
  globalFilter?: string | { filter: string }
}

const DataTableHeader = <TData, TValue = unknown>({
  header,
  globalFilter
}: DataTableHeaderProps<TData, TValue>) => {
  const {
    colProps: { className: colClassName, isEditable: __isEditable, ...colProps } = {},
    headerProps: { className: headerClassName, ...headerProps } = {},
    isHidden
  } = header.column.columnDef.meta ?? {} as ColumnMeta<TData, TValue>

  if(isHidden) {
    return null
  }

  const isFilterIgnored = !header.column.getCanGlobalFilter() && !!(isString(globalFilter) ? globalFilter : globalFilter?.filter)?.length
  return (
    <div
      key={header.id}
      role='columnheader'
      tabIndex={-1}
      title={isFilterIgnored ? 'Ei huomioida haussa' : undefined}
      className={clsx('dt-th px-1',
        colClassName,
        headerClassName,
        header.column.getCanSort() && 'cursor-pointer',
        isFilterIgnored && 'text-muted fw-normal'
      )}
      onClick={header.column.getToggleSortingHandler()}
      onKeyDown={header.column.getToggleSortingHandler()}
      style={{
        width: header.getSize()
      }}
      data-testid={`${header.id}_header`}
      {...colProps}
      {...headerProps}
    >
      <b className='px-1 text-ellipsis'>
        {header.isPlaceholder
          ? null
          : flexRender(
            header.column.columnDef.header,
            header.getContext()
          )}
      </b>
      {header.column.getCanResize()
        ? (
          <div
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            onClick={event => event.stopPropagation()}
            onKeyDown={event => event.stopPropagation()}
            className={clsx(
              (header.subHeaders.length !== 1) && 'resizer',
              header.column.getIsResizing() && 'isResizing')}
          />
          )
        : null}
      {' '}
      {(header.subHeaders.length === 0 && sortingIndicators[header.column.getIsSorted()]) ?? sortingIndicators.placeholder}
    </div>
  )
}

DataTableHeader.propTypes = {
  header: dataTableHeaderPropType.isRequired,
  globalFilter: dataTableGlobalFilterPropType
}

export default DataTableHeader
