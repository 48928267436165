import constant from 'lodash/constant'

import { BaseIdModel } from '../types/baseModelTypes'
import { createCRUDApi } from './createCRUDApi'

export type DeliveryTermModel = {
  isSystem: boolean
  name: string
  code: string
  isDefault: boolean
  createdAt: string | null
  updatedAt: string | null
} & BaseIdModel

const { api, useRecords, useMutationsHook } = createCRUDApi<DeliveryTermModel, void, 'deliveryTerms'>({
  path: 'deliveryTerms',
  queryDefinition: { query: constant('') },
  socketMatchers: {
    created: constant(true),
    updated: constant(true)
  },
  titles: {
    singular: 'toimitusehto',
    plural: 'toimitusehdot',
    genetive: 'toimitusehdon',
    pluralGenetive: 'toimitusehtojen'
  },
  invalidateOnDelete: true
})

export const deliveryTermApi = api
export const useDeliveryTermMutations = useMutationsHook
export const useDeliveryTerms = useRecords
