import { appendValuesOrEmpty } from '@evelia/common/helpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  debounce,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import customerActions from '../actions/customerActions'
import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import projectActions from '../actions/projectActions'
import supplyOfferActions from '../actions/supplyOfferActions'
import targetActions from '../actions/targetActions'
import { showActionPrompt } from '../actions/uiActions'
import {
  createSupplyOffer,
  createSupplyOfferFile,
  createSupplyOfferPreview,
  createSupplyOfferRow,
  deleteSupplyOffer,
  deleteSupplyOfferFile,
  deleteSupplyOfferRow,
  doSupplyOfferAction,
  doSupplyOfferPatchAction,
  doSupplyOfferRowPostAction,
  doSupplyOfferRowPutAction,
  fetchSupplyOffer,
  fetchSupplyOfferDefaults,
  fetchSupplyOfferFiles,
  fetchSupplyOfferRows,
  fetchSupplyOfferSummaryStats,
  normalizeSupplyOfferList,
  searchSupplyOffers,
  updateSupplyOffer,
  updateSupplyOfferDefaults,
  updateSupplyOfferFile,
  updateSupplyOfferRow
} from '../api/supplyOfferApi'
import { actionTypes, uiTypes } from '../constants'
import { getMemoSagas, getSystemMessageSagas } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createActionFlow,
  createFlow,
  createPreviewsFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  fileLinkUpdateFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getRecordsFromState,
  getSubEntitySagas,
  searchFlow,
  updateFlow,
  updateRowRankFlow
} from '../helpers/sagaHelpers'
import { handleEmployeeApiResponse } from './employeeSaga'

const SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY = 600

const watchOnSupplyOfferSockets = createSocketWatcherWithGenerator('supplyOffer', {
  created: function* (data) {
    yield handleSupplyOfferApiResponse(supplyOfferActions.createSuccess)(normalizeSupplyOfferList(data))
  },
  updated: function* (data) {
    yield handleSupplyOfferApiResponse(supplyOfferActions.updateSuccess)(normalizeSupplyOfferList(data))
  },
  deleted: function* (data) {
    yield put(supplyOfferActions.deleteSuccess(data))
  },
  switchedVat: function* (data) {
    yield put(showActionPrompt(uiTypes.PROMPT_SUPPLY_OFFER_SWITCH_VAT, data))
  }
})

const handleWorkRowApiResponse = mainAction =>
  function* ({ data }) {
    yield put(mainAction(data))
    return data
  }

const watchOnSupplyOfferRowSockets = createSocketWatcherWithApiHandlerAndNormalizer('supplyOfferRow', supplyOfferActions.supplyOfferRows, handleWorkRowApiResponse)

const watchOnSupplyOfferFileSockets = createSocketWatcher('supplyOfferFile', {
  created: supplyOfferActions.files.createSuccess,
  updated: supplyOfferActions.files.updateSuccess,
  deleted: supplyOfferActions.files.deleteSuccess
})

const handleSupplyOfferApiResponse = (mainAction, tableIdentifier = 'default') =>
  function* ({
    data,
    customers,
    targets,
    supervisors,
    projects,
    contacts,
    tableOptions
  }) {
    yield put(mainAction(data))
    yield put(customerActions.fetchSuccess(customers))
    yield put(targetActions.fetchSuccess(targets))
    yield handleEmployeeApiResponse(employeeActions.fetchSuccess, false)(supervisors)
    yield put(projectActions.fetchSuccess(projects))
    yield put(contactActions.fetchSuccess(contacts))
    if(tableOptions && tableOptions.orderBy != null) {
      yield put(supplyOfferActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const supplyOfferFetchFlow = fetchFlow({
  fetchApi: fetchSupplyOffer,
  actions: supplyOfferActions,
  base: 'supplyOffers',
  errorMsg: 'Tarviketarjouksien',
  getApiResponseHandler: data => handleSupplyOfferApiResponse(supplyOfferActions.fetchSuccess, data.tableIdentifier ?? 'default')
})

const supplyOfferUpdateFlow = updateFlow(updateSupplyOffer, supplyOfferActions, 'Tarviketarjous', 'Tarviketarjouksen', handleSupplyOfferApiResponse(supplyOfferActions.updateSuccess))
const supplyOfferCreateFlow = createFlow(createSupplyOffer, supplyOfferActions, 'Tarviketarjous', 'Tarviketarjouksen', handleSupplyOfferApiResponse(supplyOfferActions.createSuccess))
const supplyOfferDeleteFlow = deleteFlow({
  deleteApi: deleteSupplyOffer,
  actions: supplyOfferActions,
  singular: 'Tarviketarjous',
  errorMsg: 'Tarviketarjouksen',
  base: 'supplyOffers'
})

const supplyOfferRowsFetchFlow = fetchFlow({
  fetchApi: fetchSupplyOfferRows,
  actions: supplyOfferActions.supplyOfferRows,
  errorMsg: 'Rivien'
})

const supplyOfferRowUpdateFlow = updateFlow(updateSupplyOfferRow, supplyOfferActions.supplyOfferRows, 'Rivi', 'Rivin')
const supplyOfferRowCreateFlow = createFlow(createSupplyOfferRow, supplyOfferActions.supplyOfferRows, 'Rivi', 'Rivin')
const supplyOfferRowDeleteFlow = deleteFlow({
  deleteApi: deleteSupplyOfferRow,
  actions: supplyOfferActions.supplyOfferRows,
  singular: 'Rivi',
  errorMsg: 'Rivin',
  base: 'supplyOffers.supplyOfferRows'
})

const supplyOfferSearchFlow = searchFlow(searchSupplyOffers, supplyOfferActions, 'Tarviketarjousten', function* (data, searchTerm) {
  const supplyOffers = yield handleSupplyOfferApiResponse(supplyOfferActions.fetchSuccess)(data)
  yield put(supplyOfferActions.searchSuccess(supplyOffers, searchTerm))
  return supplyOffers
})

const supplyOfferPatchActionFlow = createActionFlow(doSupplyOfferPatchAction, supplyOfferActions, handleSupplyOfferApiResponse)
const supplyOfferRowPutActionFlow = createActionFlow(doSupplyOfferRowPutAction, supplyOfferActions.supplyOfferRows)
const updateSupplyOfferRowRankFlow = updateRowRankFlow(doSupplyOfferRowPutAction, supplyOfferActions.supplyOfferRows, 'supplyOffers.supplyOfferRows')

const supplyOfferRowPostActionFlow = createActionFlow(doSupplyOfferRowPostAction, supplyOfferActions.supplyOfferRows)
const createSupplyOfferPreviewsFlow = createPreviewsFlow(
  supplyOfferActions.createSupplyOfferPreviewsStart,
  createSupplyOfferPreview,
  supplyOfferActions.createSupplyOfferPreviewsSuccess,
  supplyOfferActions.createSupplyOfferPreviewsError
)

const fileFlows = getSubEntitySagas(supplyOfferActions, fileActions, fetchSupplyOfferFiles, createSupplyOfferFile, deleteSupplyOfferFile, 'supplyOfferId', 'fileId', 'supplyOffers', 'files', {
  singular: 'Tarviketarjouksen tiedosto',
  accusative: 'Tarviketarjouksen tiedoston',
  fetchError: 'Virhe tarviketarjouksen tiedostojen noutamisessa',
  deleteSuccess: 'Tarviketarjouksen tiedostolinkki poistettu',
  deleteError: 'Virhe tarviketarjouksen tiedostolinkin poistamisessa'
})

function* summaryStatsFetchFlow({ data = {}, record }) {
  const supplyOfferId = data?.supplyOfferId || record?.supplyOfferId
  try {
    yield put(supplyOfferActions.summaryStatsFetchStart())
    const summaryStats = yield call(fetchSupplyOfferSummaryStats, { supplyOfferId })
    yield put(supplyOfferActions.summaryStatsFetchSuccess(summaryStats))
  } catch(err) {
    yield put(supplyOfferActions.summaryStatsFetchError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe tarviketarjouksen tilaston noutamisessa')
  }
}

function* doSupplyOfferActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(doSupplyOfferAction, record, data)
    addSuccessNotification('Toiminto onnistui')
    if(data.subItem === 'copy') {
      yield put(supplyOfferActions.fetchSuccess(response.data))
      yield put(supplyOfferActions.supplyOfferRows.fetchSuccess(response.rows))
    }
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, appendValuesOrEmpty(['Toiminto epäonnistui', err?.json?.message], ': '), reject)
  }
}

const workSystemMessageSagas = getSystemMessageSagas({
  actions: supplyOfferActions,
  baseName: 'supplyOffers',
  socketName: 'supply_offer',
  titleGenetive: 'tarviketarjouksen',
  apiBaseUrl: 'supply_offers'
})

const memoSagas = getMemoSagas({
  actions: supplyOfferActions,
  baseName: 'supplyOffers',
  socketName: 'supply_offer',
  titleGenetive: 'tarviketarjouksen',
  apiBaseUrl: 'supply_offers'
})

const supplyOfferDefaultsFetchFlow = fetchFlow({
  fetchApi: fetchSupplyOfferDefaults,
  actions: supplyOfferActions.supplyOfferDefaults,
  base: 'supplyOffers.supplyOfferDefaults',
  errorMsg: 'tarjousten lomakeoletusten',
  shouldPerformRequest: function* (base) {
    const record = yield getRecordsFromState(base)
    return !record
  }
})

const supplyOfferDefaultsUpdateFlow = updateFlow(updateSupplyOfferDefaults, supplyOfferActions.supplyOfferDefaults, 'Tarjouksen oletukset', 'Tarjouksen oletusten')

const supplyOfferFileLinkUpdateFlow = fileLinkUpdateFlow(updateSupplyOfferFile, supplyOfferActions, 'tarviketarjouksen')

export default function* supplyOfferSaga() {
  yield takeEvery(supplyOfferActions.actionTypes.fetchRequest, supplyOfferFetchFlow)
  yield takeEvery(supplyOfferActions.actionTypes.updateRequest, supplyOfferUpdateFlow)
  yield takeEvery(supplyOfferActions.actionTypes.createRequest, supplyOfferCreateFlow)
  yield takeEvery(supplyOfferActions.actionTypes.deleteRequest, supplyOfferDeleteFlow)
  yield takeEvery(supplyOfferActions.actionTypes.searchRequest, supplyOfferSearchFlow)

  yield takeLatest(supplyOfferActions.supplyOfferRows.actionTypes.fetchRequest, supplyOfferRowsFetchFlow)
  yield takeEvery(supplyOfferActions.supplyOfferRows.actionTypes.updateRequest, supplyOfferRowUpdateFlow)
  yield takeEvery(supplyOfferActions.supplyOfferRows.actionTypes.createRequest, supplyOfferRowCreateFlow)
  yield takeLatest(supplyOfferActions.supplyOfferRows.actionTypes.deleteRequest, supplyOfferRowDeleteFlow)

  yield takeLatest(supplyOfferActions.supplyOfferRows.actionTypes.updateRankRequest, updateSupplyOfferRowRankFlow)

  yield takeEvery(actionTypes.SUPPLY_OFFER_PATCH_ACTION_REQUEST, supplyOfferPatchActionFlow)

  yield takeEvery(actionTypes.SUPPLY_OFFER_ROW_PUT_ACTION_REQUEST, supplyOfferRowPutActionFlow)

  yield takeEvery(actionTypes.SUPPLY_OFFER_ROW_POST_ACTION_REQUEST, supplyOfferRowPostActionFlow)

  yield takeEvery(supplyOfferActions.actionTypes.createSupplyOfferPreviewsRequest, createSupplyOfferPreviewsFlow)

  yield takeLatest(supplyOfferActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(supplyOfferActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(supplyOfferActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)
  yield takeLatest(supplyOfferActions.files.actionTypes.updateRequest, supplyOfferFileLinkUpdateFlow)

  yield debounce(SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY, supplyOfferActions.supplyOfferRows.actionTypes.fetchRequest, summaryStatsFetchFlow)
  yield debounce(SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY, supplyOfferActions.supplyOfferRows.actionTypes.updateRequest, summaryStatsFetchFlow)
  yield debounce(SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY, supplyOfferActions.supplyOfferRows.actionTypes.createRequest, summaryStatsFetchFlow)
  yield debounce(SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY, supplyOfferActions.supplyOfferRows.actionTypes.deleteRequest, summaryStatsFetchFlow)
  yield debounce(SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY, actionTypes.SUPPLY_OFFER_ROW_PUT_ACTION_REQUEST, summaryStatsFetchFlow)
  yield debounce(SUPPLY_OFFER_SUMMARY_STATS_FETCH_DELAY, actionTypes.SUPPLY_OFFER_ROW_POST_ACTION_REQUEST, summaryStatsFetchFlow)

  yield takeEvery(actionTypes.SUPPLY_OFFER_ACTION_REQUEST, doSupplyOfferActionFlow)

  yield takeEvery(supplyOfferActions.supplyOfferDefaults.actionTypes.fetchRequest, supplyOfferDefaultsFetchFlow)
  yield takeEvery(supplyOfferActions.supplyOfferDefaults.actionTypes.updateRequest, supplyOfferDefaultsUpdateFlow)

  yield all([
    workSystemMessageSagas(),
    memoSagas(),
    watchOnSupplyOfferSockets(),
    watchOnSupplyOfferRowSockets(),
    watchOnSupplyOfferFileSockets()
  ])
}
