import { Children, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import {
  Getter,
  Plugin,
  Template,
  TemplatePlaceholder
} from '@devexpress/dx-react-core'
import { IntegratedSelection } from '@devexpress/dx-react-grid'
import { appendEitherOrEmpty } from '@evelia/common/helpers'
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faFileDownload,
  faPen,
  faPlus,
  faTrash,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Grid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableSelection
} from 'dx-react-grid-bootstrap5'
import noop from 'lodash/noop'
import memoize from 'micro-memoize'

import { callFunctionOrContent } from '../../helpers/helpers'
import { childrenPropType } from '../../propTypes'
import ExpandButton from '../Buttons/ExpandButton'
import IconButton from '../Buttons/IconButton'
import LoadingSpinner from '../Loading/LoadingSpinner'
import DelayedActionButton from '../misc/DelayedActionButton'

const RootComponent = memoize(height => restProps => (
  <Grid.Root {...restProps} style={{ height }} />
))

const HeadComponent = memoize(headClasses => restProps => (
  <Table.TableHead
    {...restProps}
    className={headClasses}
    style={{ ...restProps.style, backgroundColor: undefined }} // override inline background color setting
  />
), { maxSize: 10 })

const EveliaHeadCellComponent = ({ CellComponent = TableHeaderRow.Cell, ...restProps }) => (
  <CellComponent
    {...restProps}
    className={appendEitherOrEmpty(restProps.className, 'table-success')}
    style={{ ...restProps.style, backgroundColor: undefined }} // override inline background color setting
  />
)

EveliaHeadCellComponent.propTypes = {
  CellComponent: PropTypes.elementType
}

export const HeadCellComponent = restProps => <EveliaHeadCellComponent {...restProps} CellComponent={TableHeaderRow.Cell} />
export const EditingHeadCellComponent = restProps => <EveliaHeadCellComponent {...restProps} CellComponent={TableEditColumn.HeaderCell} />

const TableComponent = memoize((tableClasses, testId) => ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className={appendEitherOrEmpty('table-sm', tableClasses)}
    data-testid={testId}
  />
), { maxSize: 10 })

const renderTableRow = memoize((onClick, rowPropsByRow) => props =>
  <TableRow {...props} onClick={onClick} rowPropsByRow={rowPropsByRow} />
, { maxSize: 10 })

const TableRow = ({
  row,
  onClick,
  rowPropsByRow,
  className,
  ...restProps
}) => {
  const { className: customClassName, ...restCustomProps } = rowPropsByRow?.(row) || {}
  return (
    <Table.Row
      {...restProps}
      className={appendEitherOrEmpty(customClassName, className)}
      onClick={() => onClick && onClick(row)}
      style={{
        cursor: onClick ? 'pointer' : 'initial'
      }}
      {...restCustomProps}
    />
  )
}

TableRow.propTypes = {
  row: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  rowPropsByRow: PropTypes.func,
  className: PropTypes.string
}

const findColumnExtensionOfCell = memoize((name, columnExtensions) =>
  columnExtensions.find(columnExtension => columnExtension.columnName === name))

const renderTableCell = memoize((columnExtensions, editingStates) => props =>
  <TableCell {...props} columnExtensions={columnExtensions} editingStates={editingStates} />
, { maxSize: 20 })

const TableCell = ({
  row,
  column,
  className,
  columnExtensions,
  editingStates,
  onClick,
  onDoubleClick,
  ...restProps
}) => {
  const { getCellProps } = findColumnExtensionOfCell(column.name, columnExtensions) ?? {}
  const { editingEnabled } = findColumnExtensionOfCell(column.name, editingStates) ?? {}
  const editingProps = callFunctionOrContent(editingEnabled, row) ? { onClick, onDoubleClick } : {} // if editing is not enabled, don't open inline edit form on (double) click
  const { className: cellClassName, ...cellProps } = getCellProps ? getCellProps(row) : {}
  return (
    <Table.Cell
      {...restProps}
      {...editingProps}
      className={appendEitherOrEmpty(cellClassName, className)}
      {...cellProps}
    />
  )
}

TableCell.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  columnExtensions: PropTypes.array,
  editingStates: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func
}

const SortingIcon = ({ direction }) => (
  <FontAwesomeIcon
    icon={direction === 'asc' ? faCaretUp : faCaretDown}
    className='ms-1'
  />
)
SortingIcon.propTypes = {
  direction: PropTypes.string.isRequired
}

const SortLabel = ({ onSort, children, direction, disabled }) => (
  <span
    onClick={disabled ? noop : onSort}
    className={disabled ? '' : 'cursor-pointer'}
  >
    {children}
    {(direction && <SortingIcon direction={direction} />)}
  </span>
)
SortLabel.propTypes = {
  onSort: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  disabled: PropTypes.bool
}

const CommandButton = ({
  onClick,
  icon,
  text,
  hint,
  color,
  outline,
  testId
}) => (
  <Button
    outline={outline}
    size='sm'
    color={color}
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
    title={hint}
    data-testid={testId}
  >
    {text}
    <FontAwesomeIcon icon={icon} />
  </Button>
)
CommandButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.object,
  hint: PropTypes.string,
  outline: PropTypes.bool,
  testId: PropTypes.string
}

const AddButton = ({ onClick }) => (
  <CommandButton
    color='success'
    icon={faPlus}
    hint='Uusi'
    text='Lisää uusi '
    onClick={onClick}
    testId='addNewRow'
  />
)
AddButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const EditButton = ({ onClick }) => (
  <CommandButton
    outline
    icon={faPen}
    hint='Muokkaa'
    color='warning'
    onClick={onClick}
    testId='editRow'
  />
)
EditButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const DeleteButton = ({ onClick, disabled }) => (
  <DelayedActionButton
    disabled={disabled}
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
    hint='Poista'
    confirmationMessage={null}
    testId='deleteRow'
  >
    <FontAwesomeIcon icon={faTrash} />
  </DelayedActionButton>
)
DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const CommitButton = ({ onClick }) => (
  <CommandButton
    icon={faCheck}
    hint='Tallenna'
    color='success'
    onClick={onClick}
    testId='saveRow'
  />
)
CommitButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const CancelButton = ({ onClick }) => (
  <CommandButton
    icon={faXmark}
    hint='Peruuta'
    color='danger'
    onClick={onClick}
    testId='cancelRow'
  />
)
CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
}

const Command = ({ id, onExecute }) => {
  const ButtonComponent = commandComponents[id]
  return (
    <ButtonComponent
      onClick={onExecute}
    />
  )
}
Command.propTypes = {
  id: PropTypes.any.isRequired,
  onExecute: PropTypes.func.isRequired
}

const getCommandCell = buttonFilters => buttonFilters
  ? ({ children, row, ...props }) => { // eslint-disable-line react/prop-types
      return (
        <TableEditColumn.Cell row={row} {...props}>
          {Children.map(children, child => {
            if(child) {
              return buttonFilters(row, child.props.id) ? child : null
            }
            return child
          })}
        </TableEditColumn.Cell>
      )
    }
  : TableEditColumn.Cell

const ToolbarContentDependencies = [{ name: 'Toolbar' }]
const ToolbarContent = ({ renderToolbarContent }) => (
  <Plugin name='toolbarContentPlugin' dependencies={ToolbarContentDependencies}>
    <Template name='toolbarContent'>
      {renderToolbarContent && renderToolbarContent()}
      <TemplatePlaceholder />
    </Template>
  </Plugin>
)
ToolbarContent.propTypes = {
  renderToolbarContent: PropTypes.func
}

class DisablableIntegratedSelection extends PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props
    if(!rowSelectionEnabled) {
      return <IntegratedSelection {...restProps} />
    }
    return (
      <Plugin>
        <Getter
          name='rows'
          computed={({ rows }) => {
            this.rows = rows
            return rows.filter(rowSelectionEnabled)
          }}
        />
        <IntegratedSelection {...restProps} />
        <Getter
          name='rows'
          computed={() => this.rows}
        />
      </Plugin>
    )
  }

  static propTypes = {
    rowSelectionEnabled: PropTypes.func
  }
}

const stopPropagationOnClick = e => e.stopPropagation()

class DisablableTableSelection extends PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props
    const TableSelectionCell = props => {
      const CellComponent = !rowSelectionEnabled || rowSelectionEnabled(props.tableRow.row) ? TableSelection.Cell : Table.StubCell
      return <CellComponent {...props} onClick={stopPropagationOnClick} style={{ ...props.style, cursor: 'default' }} />
    }

    TableSelectionCell.propTypes = {
      // eslint-disable-next-line react-redux/no-unused-prop-types
      style: PropTypes.object
    }
    return <TableSelection {...restProps} cellComponent={TableSelectionCell} />
  }

  static propTypes = {
    rowSelectionEnabled: PropTypes.func
  }
}

const ExportButton = ({ buttonRef, onToggle }) => (
  <IconButton buttonRef={buttonRef} onClick={onToggle} icon={faFileDownload} color='success' />
)

ExportButton.propTypes = {
  buttonRef: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
}

const TableLoadingSpinner = props => (
  <tbody>
    <Table.StubRow style={{ backgroundColor: '#FFFFFF' }}>
      <Table.StubCell colSpan={9999} className='py-5 text-center'>
        <LoadingSpinner className='h1' />
      </Table.StubCell>
    </Table.StubRow>
  </tbody>
)

const TableSummaryContent = ({ children, type, getMessage }) => (
  <strong>
    {getMessage(type)}:<br />
    {children}
  </strong>
)

TableSummaryContent.propTypes = {
  children: childrenPropType.isRequired,
  type: PropTypes.string.isRequired,
  getMessage: PropTypes.func.isRequired
}

const ExpandButtonColumn = ({ expanded, onToggle }) => (
  <td>
    <ExpandButton expanded={expanded} onToggle={onToggle} />
  </td>
)
ExpandButtonColumn.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

const TableNoDataCell = props => <Table.NoDataCell data-testid='tableNoDataCell' {...props} />
const TableNoDataCellLow = props => <Table.NoDataCell data-testid='tableNoDataCell' {...props} className='py-1' />

export {
  RootComponent,
  HeadComponent,
  TableComponent,
  SortLabel,
  renderTableRow,
  renderTableCell,
  EditButton,
  DeleteButton,
  CommitButton,
  CancelButton,
  Command,
  getCommandCell,
  ToolbarContent,
  DisablableIntegratedSelection,
  DisablableTableSelection,
  ExportButton,
  TableLoadingSpinner,
  TableSummaryContent,
  ExpandButtonColumn,
  TableNoDataCell,
  TableNoDataCellLow
}
