import { createRef, PureComponent } from 'react'
import PropTypes from 'prop-types'
import DateMomentUtils from '@date-io/moment'
import moment, { now, withFormat } from '@evelia/common/dateHelpers'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import { keyCodes } from '../../../constants'
import { checkIfEnter } from '../../../helpers/helpers'
import MaterialUiInputAdapter from '../helpers/MaterialUiInputAdapter'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

class DateTimeFormInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state.value = this.formatValue(props.value)
    this.inputRef = createRef()
  }

  state = {
    isOpen: false,
    lastSelectionStart: null,
    lastSelectionEnd: null,
    value: null
  }

  componentDidUpdate = (prevProps, prevState) => {
    // Retain cursor position when model updates
    if(prevProps.value !== this.props.value) {
      const { lastSelectionStart, lastSelectionEnd } = this.state
      this.inputRef.type !== 'hidden' && this.inputRef === document.activeElement && this.inputRef.setSelectionRange(lastSelectionStart, lastSelectionEnd || lastSelectionStart)
    }
    if(prevState.value !== this.state.value) {
      this.handleModelChange()
    } else if(prevProps.value !== this.props.value) {
      this.setState({ value: this.formatValue(this.props.value) }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  setOpen = () => this.setIsOpen(true)
  setClosed = () => this.setIsOpen(false)
  setIsOpen = open => this.setState({ isOpen: open })
  setNow = () => this.handleOnChange(now())
  clear = () => this.handleOnChange(null)

  render() {
    const {
      isInvalid,
      disabled,
      disableAll,
      readOnly,
      buttonSize,
      field,
      testId
    } = this.props

    const { value } = this.state

    return (
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <KeyboardDateTimePicker
          value={value}
          invalid={isInvalid}
          onChange={this.handleOnChange}
          TextFieldComponent={MaterialUiInputAdapter}
          isDate
          format='DD.MM.YYYY HH:mm'
          ampm={false}
          disabled={disabled || disableAll || readOnly}
          readOnly={readOnly}
          clearable
          autoOk
          clearLabel='Tyhjennä'
          cancelLabel='Peruuta'
          // showTodayButton
          todayLabel='Tänään'
          onError={this.handleError}
          open={this.state.isOpen}
          onOpen={this.setOpen}
          onClose={this.setClosed}
          setOpen={this.setOpen}
          setNow={this.setNow}
          clear={this.clear}
          onKeyUp={this.handleKeyUp}
          onBlur={this.onBlur}
          inputRef={this.inputRef}
          invalidDateMessage='Virheellinen muoto'
          buttonSize={buttonSize}
          data-testid={testId || field}
        />
      </MuiPickersUtilsProvider>
    )
  }

  onBlur = () => {
    this.setState({ value: this.formatValue(this.props.value, true) })
  }

  formatValue = (value, blur) => {
    if(value == null) {
      return value
    }
    /* if(blur && this.inputRef.current && this.inputRef.current.value.match(allowedIncompleteFormat)) {
      const incompleteMoment = moment(this.inputRef.current.value.replace(/_/g, ''), allowedInputFormats)
      if(incompleteMoment.isValid()) {
        return incompleteMoment.format()
      }
    } */
    return value ? withFormat(value) : value
  }

  handleOnChange = date => {
    this.setState({ lastSelectionStart: this.inputRef.selectionStart, lastSelectionEnd: this.inputRef.selectionEnd, value: this.formatValue(date) })
  }

  handleModelChange = () => {
    const {
      onChange,
      setNull
    } = this.props
    let { value } = this.state
    if(value === '' && setNull) {
      value = null
    }
    if(value) {
      const momentValue = moment(value, moment.ISO_8601)
      if(!momentValue.isValid()) {
        this.setValidationError('Virheellinen päiväys')
        return
      }
      value = momentValue.format()
    }
    this.clearValidationErrors()
    return onChange(value, null)
  }

  handleKeyUp = event => {
    if(event.keyCode === keyCodes.ESC) {
      return this.props.onEscPressed(event)
    } else if(checkIfEnter(event)) {
      return this.props.onEnterPressed(event)
    }
    return event
  }

  handleError = msg => {
    /* if(this.inputRef.current && this.inputRef.current.value.match(allowedIncompleteFormat)) {
      this.clearValidationErrors()
    } else { */
    this.setValidationError(msg)
    // }
  }

  setValidationError = msg => this.props.setValidationError(msg)
  clearValidationErrors = () => this.props.clearValidationError()
  static propTypes = {
    ...commonFormInputPropTypes,
    buttonSize: PropTypes.string
  }

  static defaultProps = {
    ...commonFormInputDefaultProps
  }
}

export default DateTimeFormInput
