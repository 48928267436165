import { SerializedError } from '@reduxjs/toolkit'
import { fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import queryString from 'query-string'

import { parseValidationErrors } from '../../helpers/apiHelpers'
import { getSocket } from '../../socket'
import { KanbanQuery } from '.'

export const getBaseQuery = (path: string) => fetchBaseQuery({
  baseUrl: `/api/${path}`,
  headers: {
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
    'X-Socket-Id': getSocket().id ?? '',
    Accept: 'application/json'
  },
  credentials: 'same-origin'
})

export const kanbanQuery = (query: KanbanQuery) => {
  const {
    mainFilter,
    columns,
    extraFilters = [],
    sort
  } = query
  const filters = extraFilters.flatMap(extraProp => extraProp.values.map(value => [extraProp.field, value.value]))
  const sortOrder = sort?.order
  const orderBy = sort?.sortKey
  const q = queryString.stringify({ mainFilter, columns, filters, sortOrder, orderBy })
  return `?${q}`
}

export const isSuccessResponse = <T>(response: { data: T } | { error: FetchBaseQueryError | SerializedError }): response is { data: T } => {
  return 'data' in response
}

export const isErrorResponse = <T>(response: { data: T } | { error: FetchBaseQueryError | SerializedError }): response is { error: FetchBaseQueryError } => {
  return 'error' in response && 'data' in response.error
}

export const transformErrorResponse = baseQueryReturnResponse => {
  const { data, status } = baseQueryReturnResponse as { data: { message?: string, validationErrors: unknown[] }, status: number }
  const error = {
    message: data.message,
    status,
    data,
    json: data,
    validationErrors: parseValidationErrors(data.validationErrors)
  }
  return error
}
