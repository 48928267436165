import { capitalize } from '@evelia/common/helpers'
import { createListenerMiddleware, ThunkAction, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import constant from 'lodash/constant'

import ticketActions from '../../actions/ticketActions'
import ticketTitles, { ticketTemplateListTitles } from '../../components/Tickets/ticketTitles'
import { addErrorNotification, addSuccessNotification, addWarningNotification } from '../../helpers/notificationHelpers'
import { EveliaRootState } from '../../reducerTypes'
import { ApiResponse } from '.'
import { getBaseQuery } from './apiHelpers'
import { basicApiNotification } from './rtkHelpers'
import { TicketModel } from './ticketsApi'

export type TicketTemplateListModel = {
  id: number
  name: string
  type: string | null
  ticketIds: number[]
}

export const ticketTemplateListApi = createApi({
  reducerPath: 'ticketTemplateListApi',
  baseQuery: getBaseQuery('ticket_template_lists'),
  tagTypes: ['template_tickets'],
  endpoints: builder => ({
    getTicketTemplateLists: builder.query<ApiResponse<TicketTemplateListModel>, undefined>({
      query: constant('/'),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      providesTags: ['template_tickets']
    }),
    createTicketTemplateList: builder.mutation<{ record: TicketTemplateListModel }, Omit<TicketTemplateListModel, 'id'>>({
      query: body => ({
        method: 'POST',
        body,
        url: '/'
      }),
      invalidatesTags: ['template_tickets'],
      onQueryStarted: (__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, { successMessage: `${capitalize(ticketTemplateListTitles.basic)} luotu`, errorMessage: `Virhe ${ticketTemplateListTitles.genetive} luonnissa` })
    }),
    updateTicketTemplateList: builder.mutation<{ record: TicketTemplateListModel }, Omit<TicketTemplateListModel, 'ticketIds'>>({
      query: body => ({
        method: 'PUT',
        body,
        url: `/${body.id}`
      }),
      onQueryStarted: async({ id, name, type }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(ticketTemplateListApi.util.updateQueryData('getTicketTemplateLists', undefined, state => {
          state.records = state.records.map(record => record.id === id ? { ...record, name, type } : record)
        }))

        await basicApiNotification(queryFulfilled, {
          successMessage: `${capitalize(ticketTemplateListTitles.basic)} päivitetty`,
          errorMessage: `Virhe ${ticketTemplateListTitles.genetive} päivityksessä`,
          onError: patchResult.undo
        })
      }
    }),
    deleteTicketTemplateList: builder.mutation<undefined, { id: number }>({
      query: body => ({
        method: 'DELETE',
        url: `/${body.id}`
      }),
      onQueryStarted: async({ id }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(ticketTemplateListApi.util.updateQueryData('getTicketTemplateLists', undefined, state => {
          state.records = state.records.filter(record => record.id !== id)
        }))
        try {
          await queryFulfilled
          addWarningNotification(`${capitalize(ticketTemplateListTitles.basic)} poistettu`)
        } catch{
          patchResult.undo()
          addErrorNotification(`Virhe ${ticketTemplateListTitles.genetive} poistossa`)
        }
      }
    }),
    createTicketTemplateListTicket: builder.mutation<{ record: TicketTemplateListModel, _embedded: { tickets: TicketModel[] } }, { ticket: Omit<TicketModel, 'id'>, id: number }>({
      query: body => ({
        method: 'POST',
        body: body.ticket,
        url: `/${body.id}/tickets`
      }),
      onQueryStarted: (__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, { errorMessage: `Virhe ${ticketTitles.genetive} luonnissa`, successMessage: null })
    }),
    updateTicketTemplateListTicket: builder.mutation<{ record: TicketModel, _embedded: unknown }, TicketModel>({
      query: body => ({
        method: 'PUT',
        body,
        url: `/tickets/${body.id}`
      }),
      onQueryStarted: (__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, { errorMessage: `Virhe ${ticketTitles.genetive} päivityksessä`, successMessage: null })
    }),
    deleteTicketTemplateListTicket: builder.mutation<undefined, { ticketId: number, ticketTemplateListId: number }>({
      query: query => ({
        method: 'DELETE',
        url: `/${query.ticketTemplateListId}/tickets/${query.ticketId}`
      }),
      onQueryStarted: async({ ticketId, ticketTemplateListId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          ticketTemplateListApi.util.updateQueryData('getTicketTemplateLists', undefined, state => {
            state.records = state.records.map(record => record.id === ticketTemplateListId ? { ...record, ticketIds: record.ticketIds.filter(_ticketId => _ticketId !== ticketId) } : record)
            state._embedded.tickets = state._embedded.tickets?.filter(ticket => ticket.id !== ticketId)
          })
        )
        try {
          await queryFulfilled
          addWarningNotification(`${capitalize(ticketTitles.basic)} poistettu`)
        } catch{
          patchResult.undo()
          addErrorNotification(`Virhe ${ticketTitles.basic} luonnissa`)
        }
      }
    })
  })
})

const listenerMiddlewareTicketTemplates = createListenerMiddleware()

listenerMiddlewareTicketTemplates.startListening({
  matcher: ticketTemplateListApi.endpoints.getTicketTemplateLists.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { _embedded } = action?.payload ?? {}
    if(_embedded) {
      listenerApi.dispatch(ticketActions.fetchSuccess(_embedded.tickets))
    }
  }
})

const handleTicketUpdate = (data: { record: TicketTemplateListModel, _embedded: { tickets: TicketModel[] } }, dispatch: ThunkDispatch<EveliaRootState, unknown, UnknownAction>, isCreate = true) => {
  const { record, _embedded } = data ?? {}
  if(_embedded) {
    dispatch(ticketActions.fetchSuccess(_embedded.tickets))
  }
  if(record?.id) {
    const updateQueryData = ticketTemplateListApi.util.updateQueryData('getTicketTemplateLists', undefined, data => {
      data.records = data.records.map(ticketTemplate => ticketTemplate.id === record.id ? record : ticketTemplate)
    })
    dispatch(updateQueryData as ThunkAction<unknown, unknown, unknown, UnknownAction>)
    const notificationText = isCreate ? 'luotu' : 'päivitetty'
    addSuccessNotification(`${capitalize(ticketTitles.basic)} ${notificationText}`)
  }
}

const listenerMiddlewareTicketCreate = createListenerMiddleware()

listenerMiddlewareTicketCreate.startListening({
  matcher: ticketTemplateListApi.endpoints.createTicketTemplateListTicket.matchFulfilled,
  effect: (action, listenerApi) => handleTicketUpdate(action.payload, listenerApi.dispatch as ThunkDispatch<EveliaRootState, unknown, UnknownAction>)
})

const listenerMiddlewareTicketUpdate = createListenerMiddleware()

listenerMiddlewareTicketUpdate.startListening({
  matcher: ticketTemplateListApi.endpoints.updateTicketTemplateListTicket.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(ticketActions.updateSuccess(action.payload.record))
  }
})

export const {
  useGetTicketTemplateListsQuery,
  useCreateTicketTemplateListMutation,
  useUpdateTicketTemplateListMutation,
  useDeleteTicketTemplateListMutation,
  useCreateTicketTemplateListTicketMutation,
  useUpdateTicketTemplateListTicketMutation,
  useDeleteTicketTemplateListTicketMutation
} = ticketTemplateListApi
export const { middleware: ticketTemplatesMiddleware } = listenerMiddlewareTicketTemplates
export const { middleware: ticketCreateMiddleware } = listenerMiddlewareTicketCreate
export const { middleware: ticketUpdateMiddleware } = listenerMiddlewareTicketUpdate
