import { EVELIA_TOTP_TOKEN_HEADER } from '@evelia/common/constants'
import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'

import { getBaseQuery, transformErrorResponse } from './apiHelpers'
import { basicApiNotification } from './rtkHelpers'
import { BodyWithID } from './types/helpers'
import {
  ApprovalPayload,
  ApproveBody,
  ApprovedInboundInvoiceData,
  DisapproveBody
} from './types/inboundInvoiceApi'

export const inboundInvoiceApi = createApi({
  reducerPath: 'inboundInvoiceApi',
  baseQuery: getBaseQuery('inbound_invoices'),
  endpoints: builder => ({
    getApprovedInboundInvoices: builder.query<{ record: Record<string, ApprovedInboundInvoiceData> }, { paymentRange: [string, string] }>({
      query: query => {
        const q = queryString.stringify(query)
        return `/approved_payments?${q}`
      }
    }),
    approveInboundInvoice: builder.mutation<ApprovalPayload, BodyWithID<ApproveBody> & { token?: string }>({
      query: ({ id, body, token }) => ({
        url: `/${id}/approve`,
        method: 'POST',
        headers: { [EVELIA_TOTP_TOKEN_HEADER]: token },
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
        successMessage: 'Ostolasku hyväksytty',
        errorMessage: 'Virhe ostolaskua hyväksyttäessä'
      }),
      transformErrorResponse
    }),
    disapproveInboundInvoice: builder.mutation<ApprovalPayload, BodyWithID<DisapproveBody>>({
      query: ({ id, body }) => ({
        url: `/${id}/disapprove`,
        method: 'POST',
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
        successMessage: 'Ostolasku hylätty',
        errorMessage: 'Virhe ostolaskua hylättäessä'
      }),
      transformErrorResponse
    })
  })
})

export const { useGetApprovedInboundInvoicesQuery, useApproveInboundInvoiceMutation, useDisapproveInboundInvoiceMutation } = inboundInvoiceApi
