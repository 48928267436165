import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import costProvisionActions from '../actions/costProvisionActions'
import inboundInvoiceActions from '../actions/inboundInvoiceActions'
import supplierActions from '../actions/supplierActions'
import workActions from '../actions/workActions'
import { costProvisionApi } from '../api/costProvisionApi'
import { defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import {
  createFlow,
  createSocketWatcherWithGenerator,
  deleteFlow,
  fetchFlow,
  getDefaultGeneratorSocketHandlers,
  updateFlow
} from '../helpers/sagaHelpers'

const handleCostProvisionApiResponse = mainAction =>
  function* ({
    data,
    work,
    suppliers,
    inboundInvoices
  }) {
    yield put(mainAction(data))
    yield put(workActions.fetchSuccess(work))
    yield put(supplierActions.fetchSuccess(suppliers))
    yield put(inboundInvoiceActions.fetchSuccess(inboundInvoices))
    return data
  }

const watchOnCostProvisionSockets = createSocketWatcherWithGenerator('costProvision', getDefaultGeneratorSocketHandlers(costProvisionActions, handleCostProvisionApiResponse, defaultEmbeddedNormalizer))

const costProvisionFetchFlow = fetchFlow({
  fetchApi: costProvisionApi.fetch,
  actions: costProvisionActions,
  base: 'costProvisions',
  errorMsg: 'Kuluvarausten',
  apiResponseHandler: handleCostProvisionApiResponse(costProvisionActions.fetchSuccess)
})

const costProvisionUpdateFlow = updateFlow(costProvisionApi.update, costProvisionActions, 'Kuluvaraus', 'Kuluvarauksen', handleCostProvisionApiResponse(costProvisionActions.updateSuccess))
const costProvisionCreateFlow = createFlow(costProvisionApi.create, costProvisionActions, 'Kuluvaraus', 'Kuluvarauksen', handleCostProvisionApiResponse(costProvisionActions.createSuccess))
const costProvisionDeleteFlow = deleteFlow({
  deleteApi: costProvisionApi.remove,
  actions: costProvisionActions,
  singular: 'Kuluvaraus',
  errorMsg: 'Kuluvarauksen',
  base: 'costProvisions'
})

export default function* costProvisionSaga() {
  yield takeLatest(costProvisionActions.actionTypes.fetchRequest, costProvisionFetchFlow)
  yield takeEvery(costProvisionActions.actionTypes.updateRequest, costProvisionUpdateFlow)
  yield takeEvery(costProvisionActions.actionTypes.createRequest, costProvisionCreateFlow)
  yield takeEvery(costProvisionActions.actionTypes.deleteRequest, costProvisionDeleteFlow)

  yield all([
    watchOnCostProvisionSockets()
  ])
}
