import { now } from '@evelia/common/dateHelpers'
import isObject from 'lodash/isObject'
import { combineReducers } from 'redux'
import reduxCrud from 'redux-crud-mb'

import constants from '../constants'
import { getCombinedListReducerFor } from '../helpers/reducerHelpers'

const createReducer = (initialState, handlers) =>
  (state = initialState, action) =>
    Object.hasOwn(handlers, action.type)
      ? handlers[action.type](state, action)
      : state

const createFetchAndUpdateReducer = (actionTypes, objectKey) => ({
  [actionTypes.updateSuccess]: (state, action) => {
    return ({
      ...state,
      [objectKey]: { ...action.record, fetchedAt: now() }
    })
  },
  [actionTypes.updateStart]: state => ({
    ...state
  }),
  [actionTypes.updateError]: (state, action) => ({
    ...state,
    error: action.error
  }),

  [actionTypes.fetchSuccess]: (state, action) => {
    return ({
      ...state,
      [objectKey]: { ...action.records, fetchedAt: now(), busy: false }
    })
  },
  [actionTypes.fetchStart]: state => ({
    ...state,
    [objectKey]: { ...state[objectKey], busy: true }
  }),
  [actionTypes.fetchError]: (state, action) => ({
    ...state,
    [objectKey]: { ...state[objectKey], busy: false },
    error: action.error
  })
})

const commonSystemCustomerActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER)
const detailsActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_DETAILS)
const settingsActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_SETTINGS)
const maventaActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_MAVENTA)
const accountingActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_ACCOUNTING)
const emailSettingActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_EMAIL_SETTINGS)
const smsSettingActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_SMS_SETTINGS)
const formTemplateActionTypes = reduxCrud.actionTypesFor(constants.actionKeys.SYSTEM_CUSTOMER_FORM_TEMPLATE)

const detailsReducer = createFetchAndUpdateReducer(detailsActionTypes, 'details')
const settingsReducer = createFetchAndUpdateReducer(settingsActionTypes, 'settings')
const maventaReducer = createFetchAndUpdateReducer(maventaActionTypes, 'maventa')
const accountingReducer = createFetchAndUpdateReducer(accountingActionTypes, 'accounting')
const emailSettingsReducer = createFetchAndUpdateReducer(emailSettingActionTypes, 'emailSettings')
const smsSettingsReducer = createFetchAndUpdateReducer(smsSettingActionTypes, 'smsSettings')
const formTemplateReducer = createFetchAndUpdateReducer(formTemplateActionTypes, 'formTemplate')

const initialState = {
  busy: true, fetchedAt: null, details: {}, settings: {}, maventa: {}, accounting: {}, formTemplate: {}
}

const systemCustomerReducer = combineReducers({
  settingsData: createReducer(initialState, {
    [commonSystemCustomerActionTypes.fetchSuccess]: (state, action) => {
      const newState = {
        ...action.records,
        busy: false,
        fetchedAt: now()
      }
      Object.entries(action.records).forEach(([key, value]) => {
        if(isObject(value)) {
          newState[key].fetchedAt = now()
        }
      })
      return newState
    },
    [commonSystemCustomerActionTypes.fetchStart]: state => ({
      ...state,
      busy: true
    }),
    [commonSystemCustomerActionTypes.fetchError]: (state, action) => ({
      ...state,
      busy: false,
      error: action.error
    }),
    ...detailsReducer,
    ...settingsReducer,
    ...maventaReducer,
    ...accountingReducer,
    ...emailSettingsReducer,
    ...smsSettingsReducer,
    ...formTemplateReducer
  }),
  bankAccounts: getCombinedListReducerFor(constants.actionKeys.SYSTEM_CUSTOMER_BANK_ACCOUNTS),
  creditActions: getCombinedListReducerFor(constants.actionKeys.SYSTEM_CUSTOMER_CREDIT_ACTIONS),
  pricingRules: getCombinedListReducerFor(constants.actionKeys.PRICING_RULES),
  pricingRuleSets: getCombinedListReducerFor(constants.actionKeys.PRICING_RULE_SETS),
  pricingRuleFallbacks: getCombinedListReducerFor(constants.actionKeys.PRICING_RULE_FALLBACKS),
  salaryTypes: getCombinedListReducerFor(constants.actionKeys.SALARY_TYPES),
  packetIndustries: getCombinedListReducerFor(constants.actionKeys.PACKET_INDUSTRIES),
  wageIncomeTypes: getCombinedListReducerFor(constants.actionKeys.WAGE_INCOME_TYPES),
  salaryGroups: getCombinedListReducerFor(constants.actionKeys.SALARY_GROUP),
  systemCustomerAnnualInvoicings: getCombinedListReducerFor(constants.actionKeys.SYSTEM_CUSTOMER_ANNUAL_INVOICINGS),
  approvers: getCombinedListReducerFor(constants.actionKeys.SYSTEM_CUSTOMER_APPROVERS)
})

export default systemCustomerReducer
