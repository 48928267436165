import { useCallback } from 'react'
import { Control, Path, useController } from 'react-hook-form'

import ColorFormInput from '../FormGroups/inputs/ColorFormInput'

interface FormColorInputProps<TFieldValues extends Record<string, unknown>, TContext> {
  control: Control<TFieldValues, TContext>
  name: Path<TFieldValues>
  value?: string
  disabled?: boolean
  setNull?: boolean
  resetValue?: boolean
}
const FormColorInput = <TFieldValues extends Record<string, unknown>, TContext>
  ({
    control,
    resetValue,
    value: initialValue,
    ...props
  }: FormColorInputProps<TFieldValues, TContext>
  ) => {
  const { field } = useController({ name: props.name, control })
  const { value, onChange } = field
  const resetField = useCallback(() => {
    if(resetValue) {
      onChange(props.setNull ? null : initialValue)
    }
  }, [initialValue, onChange, props.setNull, resetValue])
  return (
    <ColorFormInput
      value={value}
      className='h-100'
      onChange={onChange}
      {...props}
      resetValue={resetValue ? resetField : undefined}
    />
  )
}

export default FormColorInput
