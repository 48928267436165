import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import employeeActions from '../../actions/employeeActions'
import { accessLevels } from '../../constants'
import { getEmployeeFullText, renderEmployeeFullText } from '../../helpers/employeeHelpers'
import {
  findCurrentEmployee,
  findEmployees,
  findEmployeeWithId,
  getEmployeeLevelsMap
} from '../../selectors/employeeSelectors'
import { SimpleDetails } from '../Details'
import { SearchFormGroup } from '../FormGroups'
import { emptyDefaultProps } from './chooserHelpers'
import SelectedBlock, { getUnset } from './SelectedBlock'

const filterNotInSelectors = employees => findEmployees(employees, { isNotInSelectors: false })

const EmployeeChooser = ({
  employeeId,
  defaultProps = emptyDefaultProps,
  fieldName = 'employeeId',
  label = 'Työntekijä',
  disabled,
  setNull,
  isRequired = false,
  updateField,
  minimumEmployeeAccessLevel = accessLevels.ALL
}) => {
  const employeeLevels = useSelector(getEmployeeLevelsMap)
  const employee = useSelector(state => findEmployeeWithId(state, employeeId))
  const currentEmployee = useSelector(findCurrentEmployee)
  const isDisabled = disabled || defaultProps.disableAll

  const getLabelKey = getEmployeeFullText(employeeLevels, currentEmployee)

  const handleOnChange = (employee, skipIsDirty = false) => {
    return updateField(fieldName, employee ? employee.id : null, skipIsDirty)
  }

  const meetsMinimumAccessRequirementPredicate = useCallback(({ employeeLevelId }) => {
    const accessLevel = employeeLevels[employeeLevelId]?.accessLevel
    return accessLevel && accessLevel >= minimumEmployeeAccessLevel
  }, [employeeLevels, minimumEmployeeAccessLevel])

  const filterEmployeeSearchResult = useCallback(
    employees => filterNotInSelectors(employees).filter(meetsMinimumAccessRequirementPredicate),
    [meetsMinimumAccessRequirementPredicate]
  )

  if(employee) {
    return (
      <SelectedBlock
        title={label}
        isRequired={isRequired}
        disabled={isDisabled}
        unset={getUnset(updateField, fieldName)}
        testId={fieldName}
      >
        <SimpleDetails text={renderEmployeeFullText(employeeLevels, currentEmployee)?.(employee) || getLabelKey(employee)} />
      </SelectedBlock>
    )
  }
  return (
    <SearchFormGroup
      {...defaultProps}
      renderMenuItemChildren={renderEmployeeFullText(employeeLevels, currentEmployee)}
      disabled={isDisabled}
      setNull={setNull}
      field={fieldName}
      labelKey={getLabelKey}
      label={label}
      handleOnChange={handleOnChange}
      searchAction={employeeActions.searchRequest}
      placeholder='Aloita haku kirjoittamalla'
      isRequired={isRequired}
      filterResults={filterEmployeeSearchResult}
    />
  )
}

EmployeeChooser.propTypes = {
  employeeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  defaultProps: PropTypes.object,
  updateField: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  setNull: PropTypes.bool,
  isRequired: PropTypes.bool,
  minimumEmployeeAccessLevel: PropTypes.oneOf(Object.values(accessLevels))
}

export default EmployeeChooser
