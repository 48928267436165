import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/common/helpers'

import { selectOptionsPropType } from '../../../propTypes'
import EveliaButton from '../../Buttons/EveliaButton'
import IconButton from '../../Buttons/IconButton'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

class CommonButtonFormInput extends PureComponent {
  render() {
    const {
      size,
      className,
      vertical,
      field,
      testId
    } = this.props
    return (
      <ButtonGroup
        size={size}
        className={appendEitherOrEmpty('ps-1 flex-wrap', className)}
        vertical={vertical}
        data-testid={testId || field}
      >
        {this.mapOptions()}
      </ButtonGroup>
    )
  }

  mapOptions = () => {
    const {
      value,
      options,
      disabled,
      disableAll,
      color,
      handleClick,
      compareValues,
      field,
      testId
    } = this.props
    return (options || []).map(option => {
      const active = compareValues ? compareValues(option.value) : value === option.value
      const ButtonComponent = option.icon ? IconButton : EveliaButton
      return (
        <ButtonComponent
          key={option.value == null ? '' : option.value}
          color={option.color || color}
          className={appendEitherOrEmpty('flex-grow-0', option.className)}
          onClick={handleClick(option.value)}
          outline={!active}
          disabled={disabled || disableAll}
          data-testid={`${field || testId}${option.value}`}
          data-selected={active}
          icon={option.icon}
          {...option.buttonProps}
        >
          {option.text}
        </ButtonComponent>
      )
    })
  }

  static propTypes = {
    ...commonFormInputPropTypes,
    options: selectOptionsPropType,
    size: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    compareValues: PropTypes.func,
    vertical: PropTypes.bool
  }

  static defaultProps = {
    ...commonFormInputDefaultProps,
    options: [],
    color: 'primary'
  }
}

export default CommonButtonFormInput
