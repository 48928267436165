import { createRef, PureComponent } from 'react'
import { Input } from 'reactstrap'
import { roundNumber } from '@evelia/common/helpers'

import { keyCodes } from '../../../constants'
import { checkIfEnter } from '../../../helpers/helpers'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

const allowedNumberFormat = /^-?(?=.)(?:\d+)*\d*(?:(\.)\d+)?$/
const allowedNumberStringFormat = /^-?[\d ]*(?:[,.]\d*)?$/

class NumberFormInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state.value = this.formatValue(props)
  }

  inputRef = createRef()

  state = {
    validationError: null,
    lastSelectionStart: null,
    lastSelectionEnd: null,
    value: null
  }

  componentDidUpdate = (prevProps, prevState) => {
    const isInputActive = this.inputRef.current === document.activeElement
    if(prevProps.value !== this.props.value && !isInputActive) {
      const { lastSelectionStart, lastSelectionEnd } = this.state
      // Retain cursor position when model updates
      this.inputRef.current.type !== 'hidden' && this.inputRef.current === document.activeElement && this.inputRef.current.setSelectionRange(lastSelectionStart, lastSelectionEnd || lastSelectionStart)
    }
    if(prevState.value !== this.state.value) {
      this.handleModelChange()
    } else if(prevProps.value !== this.props.value && !isInputActive) {
      this.setState({ value: this.formatValue(this.props) }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  render() {
    const {
      field,
      type,
      step,
      isInvalid,
      disabled,
      disableAll,
      readOnly,
      inputId,
      onKeyDown,
      placeholder,
      autoFocus,
      testId,
      className
    } = this.props

    const { validationError, value } = this.state

    return (
      <Input
        invalid={isInvalid || !!validationError}
        type={type}
        step={step}
        name={field}
        id={inputId}
        value={value || ''}
        onChange={this.handleOnChange}
        disabled={disabled || disableAll || readOnly}
        readOnly={readOnly}
        innerRef={this.inputRef}
        onKeyUp={this.handleKeyUp}
        onKeyDown={onKeyDown}
        onBlur={this.onBlur}
        data-testid={testId || field}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={className}
      />
    )
  }

  onBlur = event => {
    this.setState({ value: this.formatValue(this.props) })
    this.props.onBlur && this.props.onBlur(event)
  }

  formatValue = ({ value, decimals }) => {
    let roundValue = value
    if(value != null && value.toString().match(allowedNumberFormat) && value.toString().substr(-1) !== '0') {
      roundValue = roundNumber(value, decimals)
      // Handle number formatting (toFixed etc)
      if(roundValue.toString().includes('.')) {
        roundValue = roundValue.toString().replace('.', ',')
      }
    }
    return roundValue == null ? '' : `${roundValue}`
  }

  handleOnChange = event => {
    this.setState({
      lastSelectionStart: this.inputRef.current.selectionStart,
      lastSelectionEnd: this.inputRef.current.selectionEnd,
      value: event.target.value
    })
  }

  handleModelChange = () => {
    const {
      field,
      onChange,
      setNull,
      decimals,
      value: propValue
    } = this.props
    let { value } = this.state
    if(value === '' && setNull) {
      value = null
    }
    if(value == null || value.toString().length === 0 || value.toString().match(allowedNumberStringFormat)) {
      this.clearValidationErrors()
    } else {
      this.setValidationError(field, 'Syötä numero')
    }
    if(value != null) {
      value = value.toString().replace(',', '.').replace(' ', '')
    }
    if(value != null && value.toString().match(allowedNumberFormat)) {
      const numberValue = roundNumber(Number(value), decimals)
      if(!Number.isNaN(numberValue)) {
        return numberValue === propValue ? numberValue : onChange(numberValue, null)
      }
    }
    return onChange(value, null)
  }

  handleKeyUp = event => {
    if(event.keyCode === keyCodes.ESC) {
      return this.props.onEscPressed(event)
    } else if(checkIfEnter(event)) {
      return this.props.onEnterPressed(event)
    }
    return event
  }

  setValidationError = (field, msg) => {
    this.setState({ validationError: msg })
    this.props.setValidationError?.(msg)
  }

  clearValidationErrors = () => {
    this.setState({ validationError: null })
    this.props.clearValidationError?.()
  }

  static propTypes = commonFormInputPropTypes
  static defaultProps = {
    ...commonFormInputDefaultProps,
    decimals: 6
  }
}

export default NumberFormInput
