import { emptyArray } from '@evelia/common/constants'
import { castToArray, getSelf } from '@evelia/common/helpers'
import get from 'lodash/get'
import memoize from 'micro-memoize'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

export const getLinkedItemsOfitem = (subStateName, mainIdField) =>
  createCachedSelector(
    (state, mainId) => get(state, subStateName).records,
    (state, mainId) => Number(mainId),
    (items, mainId) => items.filter(item => item[mainIdField] === mainId)
  )((state, mainId) => `${mainId}`)

export const getSubentitySelectors = (mainStateName, subStateName, junctionStateName, mainIdFieldName, subIdFieldName) => {
  const getJunctionData = createCachedSelector(
    (state, mainId) => state[mainStateName][junctionStateName].records,
    (state, mainId) => Number(mainId),
    (junctionItems, mainId) => junctionItems.filter(junctionItem => junctionItem[mainIdFieldName] === mainId)
  )((state, mainId) => `${mainId}`)

  const getJunctionDataBySubId = createCachedSelector(
    (state, mainId, subId) => state[mainStateName][junctionStateName].records,
    (state, mainId, subId) => Number(mainId),
    (state, mainId, subId) => Number(subId),
    (junctionItems, mainId, subId) => junctionItems.find(junctionItem => junctionItem[mainIdFieldName] === mainId && junctionItem[subIdFieldName] === subId)
  )((state, mainId, subId) => `${mainId}_${subId}`)

  const getSubItemsOfItem = createCachedSelector(
    (state, mainId) => getJunctionData(state, mainId),
    (state, mainId) => state[subStateName].records,
    (junctionItems, subItems) => {
      const subItemIds = junctionItems.map(junctionItem => junctionItem[subIdFieldName])
      return subItems.filter(subItem => subItemIds.includes(subItem.id))
    }
  )((state, mainId) => `${mainId}`)

  const rejectSubItemsOfItem = createCachedSelector(
    (state, mainId) => getJunctionData(state, mainId),
    (state, mainId) => state[subStateName].records,
    (junctionItems, subItems) => {
      const subItemIds = junctionItems.map(junctionItem => junctionItem[subIdFieldName])
      return subItems.filter(subItem => !subItemIds.includes(subItem.id))
    }
  )((state, mainId) => `${mainId}`)

  return {
    getJunctionData,
    getJunctionDataBySubId,
    getSubItemsOfItem,
    rejectSubItemsOfItem
  }
}

export const getFindItemByIdSelector = (getListFn, fallback) => createCachedSelector(
  getListFn,
  (state, id) => Number(id),
  (records, id) => records.find(record => record.id === id) || fallback
)((state, id) => `${id}`)

export const getFindItemsByIdsSelector = (getListFn, fallback) => createCachedSelector(
  getListFn,
  (state, ids) => ids,
  (records, rawIds) => {
    const ids = (rawIds || []).map(id => Number(id))
    return records.filter(record => ids.includes(record.id)) || fallback
  }
)((state, ids) => `${(ids || []).join(',')}`)

export const getCastedArray = memoize((targetValues, castValues) => castToArray(targetValues).map(castValues))

export const getFilterItemsByFieldSelector = (getListFn, fieldNames, castValues = getSelf) => createCachedSelector(
  getListFn,
  (state, targetValue) => targetValue,
  (records, targetValue) => {
    const targetValues = getCastedArray(targetValue, castValues)
    return records.filter(record => castToArray(fieldNames).every((fieldName, index) => record[fieldName] === targetValues[index]))
  }
)((state, targetValues) => `${JSON.stringify(targetValues)}`)

export const getFilterItemsByFieldWithValueArraySelector = (getListFn, fieldName, castValues = getSelf, sortBy) => createCachedSelector(
  getListFn,
  (state, targetValues) => targetValues,
  (records, targetValuesRaw) => {
    const targetValues = getCastedArray(targetValuesRaw, castValues)
    const values = records.filter(record => targetValues.includes(record[fieldName]))
    return sortBy ? values.sort(sortBy) : values
  }
)((state, targetValues) => `${JSON.stringify(targetValues)}`)

export const getTableOptions = createCachedSelector(
  (state, mainStateName) => get(state, mainStateName).tableOptions,
  (__state, __mainStateName, tableIdentifier) => tableIdentifier || 'default',
  (tableOptions, tableIdentifier) => tableOptions[tableIdentifier]
)((__state, mainStateName, tableIdentifier) => `${mainStateName}-${tableIdentifier}`)

export const getTableIds = createCachedSelector(
  (state, mainStateName) => get(state, mainStateName).records,
  getTableOptions,
  (records, tableOptions) => {
    return tableOptions.ids.map(id => records.find(item => item.id === id) || console.error(`Missing table record id ${id}`)).filter(Boolean)
  }
)((__state, mainStateName, tableIdentifier) => `${mainStateName}-${tableIdentifier}`)

export const getTableIdsSelector = (mainStateName, idField = 'id') => createCachedSelector(
  state => get(state, mainStateName).records,
  (state, tableIdentifier) => get(state, mainStateName).tableOptions[tableIdentifier || 'default'],
  (records, tableOptions) => {
    return tableOptions
      ? tableOptions.ids.map(id => records.find(item => item[idField] === id) || console.error(`Missing ${mainStateName} id ${id}`)).filter(Boolean)
      : emptyArray
  }

)((state, tableIdentifier) => tableIdentifier ?? 'default')

export const getMemoSelector = (mainReduxName, idField) => getFilterItemsByFieldSelector(state => state[mainReduxName].memos.records, [idField, 'memoType'])
export const getMemoEntitySelector = (mainReduxName, idField) => getFilterItemsByFieldSelector(state => Object.values(state[mainReduxName].memos.entities), [idField, 'memoType'])

export const getStats = (getListFn, list, itemId) => createSelector(
  getListFn,
  state => state[list].stats,
  (item, stats) => item?.id && item.id === stats[itemId] ? stats : {}
)

export const getItemsFromSearchResultsSelector = (mainStateName, getListFn, findWithIdFn) => createSelector(
  getListFn,
  state => state[mainStateName].search.searchResultIds || emptyArray,
  (items, searchResultIds) => searchResultIds.map(id => findWithIdFn(items, id))
)
export const getRequestBusyStatusSelector = selector => createSelector(
  [selector],
  ({ busy }) => busy
)
