import { addErrorNotification, addSuccessNotification } from '../../helpers/notificationHelpers'
import { isServerMessageError } from './types/helpers'

type NotificationMessages = {
  errorMessage: string | null
  successMessage: string | null
  onError?: () => void
}

export const basicApiNotification = async(
  queryFulfilled: Promise<unknown>,
  {
    errorMessage,
    successMessage,
    onError
  }: NotificationMessages) => {
  try {
    await queryFulfilled
    if(successMessage !== null) {
      addSuccessNotification(successMessage)
    }
  } catch(error: unknown) {
    if(isServerMessageError(error)) {
      const notificationError = error.error?.data?.message ?? errorMessage
      if(notificationError !== null) {
        onError?.()
        addErrorNotification(notificationError)
      }
    } else {
      throw error
    }
  }
}
