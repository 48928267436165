import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import workActions from '../actions/workActions'
import {
  createEmployee,
  createEmployeeFiles,
  createEmployeeLicence,
  deleteEmployee,
  deleteEmployeeFiles,
  deleteEmployeeLicence,
  employeeInvitationApi,
  fetchEmployee,
  fetchEmployeeFiles,
  fetchEmployeeLevels,
  fetchEmployeeLicences,
  fetchEmployeeWork,
  searchEmployees,
  updateEmployee,
  updateEmployeeLicence
} from '../api/employeeApi'
import employeeSubcontractorsApi from '../api/employeeSubcontractorsApi'
import { actionTypes } from '../constants'
import { getMemoSagas, getSystemMessageSagas } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getSubEntitySagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnEmployeeSockets = createSocketWatcher('employee', {
  created: employeeActions.createSuccess,
  updated: employeeActions.updateSuccess,
  deleted: employeeActions.deleteSuccess
})

const watchOnEmployeeLevelSockets = createSocketWatcher('employeeLevel', {
  created: employeeActions.employeeLevels.createSuccess,
  updated: employeeActions.employeeLevels.updateSuccess,
  deleted: employeeActions.employeeLevels.deleteSuccess
})

const watchOnEmployeeLicenceSockets = createSocketWatcher('employeeLicence', {
  created: employeeActions.employeeLicences.createSuccess,
  updated: employeeActions.employeeLicences.updateSuccess,
  deleted: employeeActions.employeeLicences.deleteSuccess
})

const watchOnEmployeeFileSockets = createSocketWatcher('employeeFile', {
  created: employeeActions.files.createSuccess,
  updated: employeeActions.files.updateSuccess,
  deleted: employeeActions.files.deleteSuccess
})

const watchOnEmployeeMemoSockets = createSocketWatcher('employeeMemo', {
  created: employeeActions.memos.createSuccess,
  updated: employeeActions.memos.updateSuccess,
  deleted: employeeActions.memos.deleteSuccess
})

export const handleEmployeeApiResponse = (mainAction, single = false, actionData) => function* ([employees]) {
  const employee = single && Array.isArray(employees) ? employees[0] : employees
  yield put(mainAction(employee, actionData))
  // NOTE: hacky solution until better employee extras implementation
  // `single` is used with update and create flow
  const firstEmployee = single ? employee : employee?.[0]
  if(firstEmployee?.taxNumber !== undefined) {
    yield put(employeeActions.extras.fetchSuccess(employee))
  }
  return employee
}

const employeeFetchFlow = fetchFlow({
  fetchApi: fetchEmployee,
  actions: employeeActions,
  errorMsg: 'Työntekijöiden',
  idField: 'id',
  base: 'employees',
  updateLastFetched: true,
  apiResponseHandler: handleEmployeeApiResponse(employeeActions.fetchSuccess, false, { updateLastFetched: true })
})

function* employeeDeleteFlow({ record, data = {} }) {
  try {
    const toDelete = yield select(state => state.employee.records.find(employee => employee.id === record.id))
    yield call(deleteEmployee, toDelete)
    yield put(employeeActions.deleteSuccess(toDelete))
    addSuccessNotification('Työntekijä poistettu')
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe työntekijän poistamisessa')
  }
}

const employeeUpdateFlow = updateFlow(updateEmployee, employeeActions, 'Työntekijä', 'Työntekijän', handleEmployeeApiResponse(employeeActions.updateSuccess, true))
const employeeCreateFlow = createFlow(createEmployee, employeeActions, 'Työntekijä', 'Työntekijän', handleEmployeeApiResponse(employeeActions.createSuccess, true))
const employeeSearchFlow = searchFlow(searchEmployees, employeeActions, 'Työntekijöiden', function* (data, searchTerm) {
  const employees = yield handleEmployeeApiResponse(employeeActions.fetchSuccess)(data)
  yield put(employeeActions.searchSuccess(employees, searchTerm))
  return employees
})

const employeeLevelsFetchFlow = fetchFlow({
  fetchApi: fetchEmployeeLevels,
  actions: employeeActions.employeeLevels,
  errorMsg: 'Käyttäjätasojen',
  idField: 'id',
  base: 'employees.levels',
  updateLastFetched: true
})

const employeeInvitationsFetchFlow = fetchFlow({
  fetchApi: employeeInvitationApi.fetch,
  actions: employeeActions.employeeInvitations,
  base: 'employees.invitations',
  errorMsg: 'Kutsujen'
})

const employeeInvitationUpdateFlow = updateFlow(employeeInvitationApi.update, employeeActions.employeeInvitations, 'Kutsu', 'Kutsun')
const employeeInvitationCreateFlow = createFlow(employeeInvitationApi.create, employeeActions.employeeInvitations, 'Kutsu', 'Kutsun', function* (invitations) {
  yield all(invitations.map(invitation => put(employeeActions.employeeInvitations.createSuccess(invitation))))
})

const employeeInvitationDeleteFlow = deleteFlow({
  deleteApi: employeeInvitationApi.remove,
  actions: employeeActions.employeeInvitations,
  singular: 'Kutsu',
  errorMsg: 'Kutsun',
  base: 'employees.invitations'
})

function* employeeWorkFetchFlow({ record, data = {} }) {
  try {
    yield put(workActions.employees.fetchStart())
    const [workEmployee] = yield call(fetchEmployeeWork, record)
    yield put(workActions.employees.fetchSuccess(workEmployee))
  } catch(err) {
    yield put(workActions.employees.fetchError(err))
    yield * genericSagaErrorHandler(err, 'Virhe työntekijän töiden noutamisessa')
  }
}

const employeeLicencesFetchFlow = fetchFlow({
  fetchApi: fetchEmployeeLicences,
  actions: employeeActions.employeeLicences,
  base: 'employees.employeeLicences',
  errorMsg: 'työntekijän pätevyyksien'
})

const employeeLicenceUpdateFlow = updateFlow(updateEmployeeLicence, employeeActions.employeeLicences, 'Työntekijän pätevyys', 'Työntekijän pätevyyden')
const employeeLicenceCreateFlow = createFlow(createEmployeeLicence, employeeActions.employeeLicences, 'Työntekijän pätevyys', 'Työntekijän pätevyyden')
const employeeLicenceDeleteFlow = deleteFlow({
  deleteApi: deleteEmployeeLicence,
  actions: employeeActions.employeeLicences,
  singular: 'Työntekijän pätevyys',
  errorMsg: 'Työntekijän pätevyyden',
  base: 'employees.employeeLicences'
})

const fileFlows = getSubEntitySagas(employeeActions, fileActions, fetchEmployeeFiles, createEmployeeFiles, deleteEmployeeFiles, 'employeeId', 'fileId', 'employees', 'files', {
  singular: 'Työntekijän tiedosto',
  accusative: 'Työntekijän tiedoston',
  fetchError: 'Virhe työntekijän tiedostojen noutamisessa',
  deleteSuccess: 'Työntekijän tiedostolinkki poistettu',
  deleteError: 'Virhe työntekijän tiedostolinkin poistamisessa'
})

const employeeSystemMessageSagas = getSystemMessageSagas({
  actions: employeeActions,
  baseName: 'employees',
  socketName: 'employee',
  titleGenetive: ''
})

const memoSagas = getMemoSagas({
  actions: employeeActions,
  baseName: 'employees',
  socketName: 'employee',
  titleGenetive: 'työntekijän'
})

const employeeSubcontractorsFetchFlow = fetchFlow({
  fetchApi: employeeSubcontractorsApi.fetch,
  actions: employeeActions.employeeSubcontractors,
  base: 'employees.employeeSubcontractors',
  errorMsg: 'työntekijän alihankkijatiedot'
})

const employeeSubcontractorsUpdateFlow = updateFlow(employeeSubcontractorsApi.update, employeeActions.employeeSubcontractors, 'Työntekijän lisäasetus', 'Työntekijän lisäasetuksen')
const employeeSubcontractorsCreateFlow = createFlow(employeeSubcontractorsApi.create, employeeActions.employeeSubcontractors, 'Työntekijän lisäasetus', 'Työntekijän lisäasetuksen')

export default function* employeeSaga() {
  yield takeLatest(employeeActions.actionTypes.fetchRequest, employeeFetchFlow)
  yield takeEvery(employeeActions.actionTypes.updateRequest, employeeUpdateFlow)
  yield takeEvery(employeeActions.actionTypes.createRequest, employeeCreateFlow)
  yield takeEvery(employeeActions.actionTypes.deleteRequest, employeeDeleteFlow)
  yield takeLatest(employeeActions.actionTypes.searchRequest, employeeSearchFlow)

  yield takeLatest(employeeActions.employeeLevels.actionTypes.fetchRequest, employeeLevelsFetchFlow)

  yield takeLatest(employeeActions.employeeInvitations.actionTypes.fetchRequest, employeeInvitationsFetchFlow)
  yield takeEvery(employeeActions.employeeInvitations.actionTypes.updateRequest, employeeInvitationUpdateFlow)
  yield takeEvery(employeeActions.employeeInvitations.actionTypes.createRequest, employeeInvitationCreateFlow)
  yield takeLatest(employeeActions.employeeInvitations.actionTypes.deleteRequest, employeeInvitationDeleteFlow)

  yield takeLatest(actionTypes.EMPLOYEE_WORK_FETCH_REQUEST, employeeWorkFetchFlow)

  yield takeLatest(employeeActions.employeeLicences.actionTypes.fetchRequest, employeeLicencesFetchFlow)
  yield takeEvery(employeeActions.employeeLicences.actionTypes.updateRequest, employeeLicenceUpdateFlow)
  yield takeEvery(employeeActions.employeeLicences.actionTypes.createRequest, employeeLicenceCreateFlow)
  yield takeLatest(employeeActions.employeeLicences.actionTypes.deleteRequest, employeeLicenceDeleteFlow)

  yield takeLatest(employeeActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(employeeActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(employeeActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(employeeActions.employeeSubcontractors.actionTypes.fetchRequest, employeeSubcontractorsFetchFlow)
  yield takeEvery(employeeActions.employeeSubcontractors.actionTypes.updateRequest, employeeSubcontractorsUpdateFlow)
  yield takeEvery(employeeActions.employeeSubcontractors.actionTypes.createRequest, employeeSubcontractorsCreateFlow)

  yield all([
    memoSagas(),
    employeeSystemMessageSagas(),
    watchOnEmployeeSockets(),
    watchOnEmployeeLevelSockets(),
    watchOnEmployeeLicenceSockets(),
    watchOnEmployeeFileSockets(),
    watchOnEmployeeMemoSockets()
  ])
}
