import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalProps
} from 'reactstrap'

import { callRenderFunctionOrContent, type RenderNode } from '../../helpers/helpers'

interface ModalBaseProps {
  title: React.ReactNode
  isOpen: boolean
  onCancel?: React.MouseEventHandler
  renderBody: RenderNode
  renderFooter?: RenderNode
  modalProps?: Partial<ModalProps>
}

const ModalBase = ({
  title,
  isOpen,
  onCancel,
  renderBody,
  renderFooter,
  modalProps
}: ModalBaseProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClick={ev => ev.stopPropagation() /* Stop propagation, so form submit with enter don't propagate to parent */} {...modalProps}>
      <ModalHeader className='modal-header' cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={onCancel}><span className='modal-title'>{title}</span></ModalHeader>
      <ModalBody>
        {callRenderFunctionOrContent(renderBody)}
      </ModalBody>
      {renderFooter && (
        <ModalFooter>
          {callRenderFunctionOrContent(renderFooter)}
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalBase
