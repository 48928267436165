import queryString from 'query-string'

import { BaseIdModel } from '../types/baseModelTypes'
import { createCRUDApi } from './createCRUDApi'

export type DeliveryTypeModel = {
  isSystem: boolean
  name: string
  code: string
  supplierId: number | null
  type: string
  address?: string
  city?: string
  postalCode?: string
  createdAt: string | null
  updatedAt: string | null
} & BaseIdModel

type DeliveryTypeQuery = {
  supplierId?: number
}

const { api, useRecords, useMutationsHook } = createCRUDApi<DeliveryTypeModel, DeliveryTypeQuery, 'deliveryTypes'>({
  path: 'deliveryTypes',
  queryDefinition: {
    query: ({ supplierId } = {}) => `?${queryString.stringify({ supplierId })}`
  },
  socketMatchers: {
    created: (deliveryType, queryArgs) => queryArgs?.supplierId == null || deliveryType.supplierId === queryArgs.supplierId,
    updated: (deliveryType, queryArgs) => queryArgs?.supplierId == null || deliveryType.supplierId === queryArgs.supplierId
  },
  titles: {
    singular: 'toimitustapa',
    plural: 'toimitustavat',
    genetive: 'toimitustavan',
    pluralGenetive: 'toimitustapojen'
  },
  invalidateOnDelete: true
})

export const deliveryTypeApi = api
export const useDeliveryTypeMutations = useMutationsHook
export const useDeliveryTypes = useRecords
