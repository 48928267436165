import { TimeRecordTypeModel } from '../../api/types/timeRecordTypes'
import IconButton from '../Buttons/IconButton'
import { getTimeRecordTypeIcon } from './timeRecordTypeHelpers'

interface Props {
  timeRecordType: TimeRecordTypeModel
  disabled?: boolean
  onClick?: () => void
  testId?: string
  className?: string
  size?: string
}
const TimeRecordStatusButton = ({
  timeRecordType,
  disabled,
  onClick,
  className,
  testId,
  size
}: Props) => (
  <IconButton
    size={size}
    color={timeRecordType.color || undefined}
    onClick={onClick}
    icon={getTimeRecordTypeIcon(timeRecordType)}
    className={className}
    disabled={disabled}
    outline={timeRecordType.isOutline}
    condensed
    testId={testId || `timeRecordType_${timeRecordType.name}`}
  >
    {timeRecordType.name}
  </IconButton>
)

export default TimeRecordStatusButton
