import { useState } from 'react'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import DateMomentUtils from '@date-io/moment'
import { parseMomentData } from '@evelia/common/dateHelpers'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import get from 'lodash/get'

import useToggle from '../../hooks/useToggle'
import FormMaterialUiInputAdapter from './FormMaterialUiInputAdapter'

const FormTimeInput = ({
  control,
  name,
  errors,
  label,
  inputOnly,
  required,
  disabled,
  readOnly,
  prepend,
  append,
  tooltipError
}) => {
  const { field, formState } = useController({ name, control })
  const [pickerError, setPickerError] = useState()

  const [isOpen, { enable, disable }] = useToggle()

  const inputErrors = formState.errors
  const inputError = pickerError
    ? { message: pickerError }
    : get(errors || inputErrors, name)
  const combinedErrors = inputError
    ? { [name]: inputError }
    : null

  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <KeyboardTimePicker
        id={name}
        value={parseMomentData(field.value)}
        onBlur={field.onBlur}
        name={field.name}
        onChange={momentDate => field.onChange(parseMomentData(momentDate, 'HH:mm'))}
        TextFieldComponent={FormMaterialUiInputAdapter}
        autoOk
        ampm={false}
        format='HH:mm'
        todayLabel='Tänään'
        clearLabel='Tyhjennä'
        cancelLabel='Peruuta'
        invalidDateMessage='Virheellinen muoto'
        open={isOpen}
        onOpen={enable}
        onClose={disable}
        clearable={!readOnly}
        onError={setPickerError}
          // TextFieldComponent
        label={label}
        inputOnly={inputOnly}
        required={required}
        data-testid={name}
        setOpen={enable}
        invalid={!!combinedErrors}
        disabled={disabled}
        errors={combinedErrors}
        tooltipError={tooltipError}
        prepend={prepend}
        append={append}

      />
    </MuiPickersUtilsProvider>
  )
}

FormTimeInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.node,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  append: PropTypes.node,
  prepend: PropTypes.node,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipError: PropTypes.bool
}

export default FormTimeInput
