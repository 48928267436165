import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { appendEitherOrEmpty } from '@evelia/common/helpers'

import workActions, { getUrl } from '../../../actions/workActions'
import { findWorkWithId, getHasAccessToWork } from '../../../selectors/workSelectors'
import { WorkDetails } from '../../Details'
import { SearchFormGroup } from '../../FormGroups'
import { emptyDefaultProps } from '../chooserHelpers'
import SelectedBlock, { getUnset } from '../SelectedBlock'
import WorkSearchItem from './WorkSearchItem'

const getItemDetails = work => {
  const details = appendEitherOrEmpty(work.name, work.description)
  return work.workNumber ? appendEitherOrEmpty(work.workNumber, details, ': ') : details
}

export const renderSearchItem = showCustomerDetails => item => (
  <WorkSearchItem work={item} getItemDetails={getItemDetails} showCustomerDetails={showCustomerDetails} />
)

const WorkChooser = ({
  workId,
  defaultProps = emptyDefaultProps,
  fieldName = 'workId',
  label = 'Työ',
  disabled,
  setNull,
  isRequired,
  searchAction,
  updateField,
  showCustomerSalesProhibition,
  showCustomerDetails,
  onChange
}) => {
  const work = useSelector(state => findWorkWithId(state, workId))
  const isDisabled = disabled || defaultProps.disableAll
  const hasAccessToWork = useSelector(state => getHasAccessToWork(state, workId))

  const handleOnChange = useCallback((work, skipIsDirty = false) => {
    if(onChange) {
      onChange(work)
    }
    return updateField(fieldName, work ? work.id : null, skipIsDirty)
  }, [fieldName, onChange, updateField])

  if(workId || work) {
    return (
      <SelectedBlock
        title={label}
        isRequired={!!isRequired}
        disabled={isDisabled}
        unset={getUnset(updateField, fieldName)}
        navigateTo={hasAccessToWork ? getUrl(workId) : null}
        testId={fieldName}
      >
        <WorkDetails work={work} showCustomerSalesProhibition={showCustomerSalesProhibition} showCustomerDetails={showCustomerDetails} />
      </SelectedBlock>
    )
  }

  return (
    <SearchFormGroup
      {...defaultProps}
      disabled={isDisabled}
      setNull={setNull}
      field={fieldName}
      labelKey={getItemDetails}
      renderMenuItemChildren={renderSearchItem(showCustomerDetails)}
      label={label}
      handleOnChange={handleOnChange}
      searchAction={searchAction || workActions.searchRequest}
      placeholder='Aloita haku kirjoittamalla'
      isRequired={isRequired}
      emptyLabel='Ei hakutuloksia'
    />
  )
}
WorkChooser.propTypes = {
  workId: PropTypes.number,
  work: PropTypes.object,
  defaultProps: PropTypes.object,
  updateField: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  setNull: PropTypes.bool,
  isRequired: PropTypes.bool,
  searchAction: PropTypes.func,
  navigateTo: PropTypes.func,
  showCustomerSalesProhibition: PropTypes.bool,
  showCustomerDetails: PropTypes.bool,
  onChange: PropTypes.func
}
export default WorkChooser
