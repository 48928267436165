import { capitalize } from '@evelia/common/helpers'
import { call, put, takeEvery } from 'redux-saga/effects'

import ticketActions from '../actions/ticketActions'
import workActions from '../actions/workActions'
import { createWorkTicketsFromTicketTemplateList } from '../api/workApi'
import { ticketTemplateListTitles } from '../components/Tickets/ticketTitles'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import { genericSagaErrorHandler, getPromiseHandlersFromData } from '../helpers/sagaHelpers'

function* createWorkTicketsFromTicketTemplateListFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workActions.workTicketTemplateLists.setBusy(true))
    const { data: tickets } = yield call(createWorkTicketsFromTicketTemplateList, record, data.workId)
    yield put(ticketActions.fetchSuccess(tickets))
    addSuccessNotification(`${capitalize(ticketTemplateListTitles.basic)} lisätty`)
    yield call(resolve, tickets)
    yield put(workActions.workTicketTemplateLists.setBusy(false))
  } catch(err) {
    yield * genericSagaErrorHandler(err, `Virhe ${ticketTemplateListTitles.genetive} lisäämisessä`, reject)
  }
}

export default function* workTicketTemplateListsSaga() {
  yield takeEvery(workActions.workTicketTemplateLists.actionTypes.createRequest, createWorkTicketsFromTicketTemplateListFlow)
}
