import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead'
import { clsx } from 'clsx'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

import { checkIfEnter } from '../../helpers/helpers'
import FormInputGroupWrapper from './FormInputGroupWrapper'
import FormInputWrapper from './FormInputWrapper'
import FormSelectButton from './FormSelectButton'

// Display all the options if there's a selection.
export const filterByDisplayAllOptions = (option, props) => {
  if(props.selected.length) {
    return true
  }
  const label = isFunction(props.labelKey)
    ? props.labelKey(option)
    : option[props.labelKey]
  return `${label}`.toLowerCase().includes(props.text.toLowerCase())
}

export const selectHintOnEnter = (shouldSelectHint, event) => {
  if(checkIfEnter(event)) {
    return true
  }
  return shouldSelectHint
}

const FormPlainSelectInput = ({
  name,
  value,
  errors,
  label,
  inputProps,
  placeholder,
  required,
  multiple,
  inputOnly,
  options,
  disabled,
  tooltipError,
  labelKey = 'name',
  valueKey = 'id',
  formGroupClassName,
  positionFixed,
  onChange,
  onValueChange,
  isCustomAddon,
  invalid,
  tooltip,
  helpText,
  inline,
  onBlur,
  typeaheadProps
}) => {
  const selections = options.filter(option => Array.isArray(value)
    ? value.some(selection => selection === option || selection === option[valueKey])
    : value === option || value === option[valueKey])

  const selected = !multiple && selections.length
    ? [selections[0]]
    : selections

  const getSelectedValueArray = values => values.map(value => value?.[valueKey] ?? value)
  const getSelectedValue = value => {
    const isDefaultValueNullSelected = value?.[valueKey] === null
    // React-hook-form doesn't recognize change if value is undefined therefore set to null
    if(isDefaultValueNullSelected) {
      return null
    }
    return value?.[valueKey] ?? value ?? null
  }

  const handleChange = values => {
    const selection = multiple ? getSelectedValueArray(values) : getSelectedValue(values[0])
    onValueChange?.(selection, multiple ? values : values[0])
    onChange(selection)
  }

  // Allow clearing null default value by typing something
  const hasDefaultValueNull = options.some(option => option?.[valueKey] === null)
  const handleKeyDownIfDefaultNull = () => {
    if(!value) {
      onChange('')
    }
  }

  const isInvalid = invalid || !!get(errors, name)

  const ref = useRef()
  const handleBlur = event => {
    // Close menu manually (might not be done automatically e.g. in modals)
    ref.current?.hideMenu()
    onBlur?.(event)
  }

  return (
    <FormInputWrapper
      name={name}
      label={label}
      inputOnly={inputOnly}
      required={required}
      formGroupClassName={formGroupClassName}
      tooltip={tooltip}
      helpText={helpText}
      inline={inline}
    >
      <FormInputGroupWrapper
        name={name}
        errors={errors}
        tooltipError={tooltipError}
        isCustomAddon={isCustomAddon}
      >
        <Typeahead
          id={name}
          labelKey={labelKey}
          ref={ref}
          onKeyDown={hasDefaultValueNull ? handleKeyDownIfDefaultNull : undefined}
          onChange={handleChange}
          onBlur={handleBlur}
          options={options}
          placeholder={placeholder}
          selected={selected}
          multiple={multiple}
          size='sm'
          filterBy={multiple ? undefined : filterByDisplayAllOptions}
          emptyLabel='Ei tuloksia'
          promptText='Aloita haku kirjoittamalla'
          searchText='Etsitään...'
          paginationText='Näytä lisää tuloksia...'
          newSelectionPrefix='Lisää: '
          isInvalid={isInvalid}
          data-testid={name}
          selectHint={selectHintOnEnter}
          highlightOnlyResult
          disabled={disabled}
          positionFixed={positionFixed}
          flip
          {...typeaheadProps}
          inputProps={{
            ...inputProps,
            'data-testid': name,
            'data-evelia': 'new-select'
          }}
        // isInvalid doesn't add is-invalid className to the component so FormFeedback is not displayed
          className={clsx(isInvalid && 'is-invalid', inputProps?.className)}
        >
          {rtbProps => <FormSelectButton {...rtbProps} required={required} />}
        </Typeahead>
      </FormInputGroupWrapper>
    </FormInputWrapper>
  )
}

FormPlainSelectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.array]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.node,
  inputProps: PropTypes.object,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.node,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  tooltipError: PropTypes.bool,
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  valueKey: PropTypes.string,
  formGroupClassName: PropTypes.string,
  positionFixed: PropTypes.bool,
  typeaheadProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  isCustomAddon: PropTypes.bool,
  invalid: PropTypes.bool,
  tooltip: PropTypes.node,
  helpText: PropTypes.node,
  inline: PropTypes.bool,
  onBlur: PropTypes.func
}

export default FormPlainSelectInput
