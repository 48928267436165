import { sortByProperty } from '@evelia/common/helpers'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { getFindItemByIdSelector, getTableIdsSelector } from '../helpers/selectorHelpers'
import { findAuthorsOfCurrentEmployee } from './authorSelectors'

const getSystemMessagesFromArgument = arg => arg.systemMessages ? arg.systemMessages.records : arg

export const findSystemMessageWithId = getFindItemByIdSelector(getSystemMessagesFromArgument)

export const getSystemMessagesByTableIds = getTableIdsSelector('systemMessages')

export const getSystemMessages = createCachedSelector(
  getSystemMessagesFromArgument,
  (__state, includeConfirmed) => !!includeConfirmed,
  findAuthorsOfCurrentEmployee,
  (systemMessages, includeConfirmed, authors) =>
    includeConfirmed
      ? systemMessages.filter(systemMessage => hasSystemMessagePermission(systemMessage, authors))
      : systemMessages.filter(systemMessage => hasSystemMessagePermission(systemMessage, authors) &&
      systemMessage.confirmedAt == null)
)((__state, includeConfirmed) => `${!!includeConfirmed}`)

const hasSystemMessagePermission = (systemMessage, authors) => {
  if(!systemMessage) {
    return false
  }
  const { employeeId, createdBy, destination } = systemMessage

  const includesEmployee = authors.some(author => author.employeeId === employeeId)
  const includesAuthor = authors.some(author => author.id === createdBy)

  return (!employeeId && !destination) ||
   includesEmployee || includesAuthor
}

export const getHasSystemMessagePermission = createCachedSelector(
  findSystemMessageWithId,
  findAuthorsOfCurrentEmployee,
  (systemMessage, employee) => hasSystemMessagePermission(systemMessage, employee)
)((__state, systemMessageId) => `${systemMessageId}`)

export const getSystemMessagesSorted = createSelector(
  (state, includeConfirmed) => getSystemMessages(state, includeConfirmed),
  systemMessages => systemMessages.sort(sortByProperty('createdAt', true))
)
