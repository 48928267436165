import { castToArray } from '@evelia/common/helpers'
import { createSelector } from 'reselect'

import { getFindItemByIdSelector } from '../helpers/selectorHelpers'

export const getSuppliersFromArgument = arg => arg.suppliers ? arg.suppliers.records : arg

export const findSupplierWithId = getFindItemByIdSelector(getSuppliersFromArgument)

export const getSuppliers = createSelector(
  getSuppliersFromArgument,
  state => state.suppliers.tableOptions.default.ids,
  (suppliers, order) => orderSuppliers(suppliers, order)
)

export const getEnabledSuppliers = createSelector(
  getSuppliersFromArgument,
  state => state.suppliers.tableOptions.default.ids,
  (suppliers, order) => orderSuppliers(suppliers.filter(supplier => order.includes(supplier.id)), order)
)

export const getEnabledSuppliersWithSelected = createSelector(
  getSuppliersFromArgument,
  state => state.suppliers.tableOptions.default.ids,
  (__state, supplierIds) => castToArray(supplierIds).map(Number),
  (suppliers, order, supplierIds) => orderSuppliers(suppliers.filter(supplier => supplierIds.includes(supplier.id) || order.includes(supplier.id)), order)
)

export const findTinkaSuppliers = createSelector(
  getSuppliersFromArgument,
  suppliers => suppliers.filter(supplier => !!supplier.costProvisionFieldName)
)

const orderSuppliers = (suppliers, order) => {
  const idToSupplier = suppliers?.reduce((acc, supplier) => ({ ...acc, [supplier.id]: supplier }), {}) ?? {}
  const suppliersFromOrder = (order?.map(id => {
    const supplier = idToSupplier[id]
    delete idToSupplier[id]
    return supplier
  }) ?? []).filter(Boolean)
  const remainingIds = Object.keys(idToSupplier).sort((a, b) => +a - +b)
  return [...suppliersFromOrder, ...remainingIds.map(id => idToSupplier[id])]
}
