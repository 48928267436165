import moment from '@evelia/common/dateHelpers'
import { parseTemplate } from 'url-template'

import { get, post } from '../helpers/apiHelpers'

const reportQueryTerms = [
  'startDate',
  'endDate',
  'customerId',
  'targetId',
  'workId',
  'supervisorId',
  'invoicing',
  'workTypeId',
  'employeeId',
  'contactId',
  'projectId',
  'offerId',
  'supplyOfferId',
  'chartGrouppingOption',
  'workRecordProducts*',
  'employeeIds*',
  'method',
  'salaryGroupId',
  'paymentDate',
  'workCustomStateIds*',
  'customerMainGroupId',
  'state*',
  'type',
  'isWithoutDate',
  'isWithoutDateIncluded',
  'notInvoisable',
  'archive',
  'dontIncludeNotApprovedInboundInvoices',
  'dontIncludeOverdueReceivables',
  'view',
  'receivablesFilterDayCount',
  'includeReportAttachments',
  'isSpecialReport',
  'isRelativesIncluded',
  'offerOnly',
  'supplyOfferOnly',
  'workAsProduct',
  'excludeSubProjects'
]

const baseUrl = `/api/reports{/reportContext}{/reportType*}{?${reportQueryTerms.join(',')}}`
const reportUrlTemplate = parseTemplate(baseUrl)

export function fetchReport({
  reportContext,
  reportType,
  startDate,
  endDate,
  ...rest
}) {
  const requestUrl = reportUrlTemplate.expand({ reportContext, reportType, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD'), ...rest })
  return get(requestUrl)
}

export const generateReport = ({
  reportContext,
  reportType,
  startDate,
  endDate,
  type,
  archive,
  includeReportAttachments,
  ...data
}) => {
  const requestUrl = reportUrlTemplate.expand({ reportContext, reportType, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD'), type, archive, includeReportAttachments })
  return post(requestUrl, {
    body: JSON.stringify(data)
  })
}
