import { Control, FieldValues, Path, useController } from 'react-hook-form'

import FormPlainNumberInput from './FormPlainNumberInput'

interface FormNumberInputProps<T extends FieldValues> extends React.ComponentProps<typeof FormPlainNumberInput>{
  control: Control<T>
  name: Path<T>
}

const FormNumberInput = <T extends FieldValues>({
  control,
  errors,
  ...props
}: FormNumberInputProps<T>) => {
  const { field, formState } = useController({ name: props.name, control })
  const { value, onChange, ref, ...fieldProps } = field
  const inputErrors = formState.errors
  return (
    <FormPlainNumberInput
      {...props}
      value={value}
      errors={errors || inputErrors}
      onChange={onChange}
      inputProps={{
        innerRef: ref,
        ...props.inputProps,
        ...fieldProps
      }}
    />
  )
}

export default FormNumberInput
