import { sortByProperty } from '@evelia/common/helpers'
import sortBy from 'lodash/sortBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  getFilterItemsByFieldWithValueArraySelector,
  getFindItemByIdSelector,
  getStats
} from '../helpers/selectorHelpers'
import { findCurrentEmployee } from './employeeSelectors'

export const getWorkRecordProductsFromArgument = arg => arg.workRecordProducts ? arg.workRecordProducts.records : arg

export const findWorkRecordProductWithId = getFindItemByIdSelector(getWorkRecordProductsFromArgument)

const getWorkRecordProductsByOwnRoles = createSelector(
  state => state.workRecordProducts.records,
  findCurrentEmployee,
  (workRecordProducts, employee) => {
    const { employeeRoleIds } = employee
    const selectedProducts = workRecordProducts
      .filter(workRecordProduct =>
        workRecordProduct.employeeRoleIds.length === 0 || workRecordProduct.employeeRoleIds.some(employeeRoleId => employeeRoleIds.includes(employeeRoleId)))
    return sortBy(selectedProducts, 'productNumber')
  }
)

export const getWorkRecordProductSalaryTypesByWorkRecordProductId = createCachedSelector(
  state => state.workRecordProducts.workRecordProductSalaryTypes.records,
  (state, workRecordProductId) => Number(workRecordProductId),
  (salaryTypes, workRecordProductId) => salaryTypes.filter(salaryType => salaryType.workRecordProductId === workRecordProductId)
)((state, workRecordProductId) => workRecordProductId)

export const getWorkRecordProductPricingRulesByWorkRecordProductId = createCachedSelector(
  state => state.workRecordProducts.workRecordProductPricingRules.records,
  state => state.systemCustomer.pricingRuleSets.records,
  (state, workRecordProductId) => Number(workRecordProductId),
  (pricingRules, pricingRuleSets, workRecordProductId) => {
    const pricingRulesOfWorkRecordProduct = pricingRules.filter(pricingRule => pricingRule.workRecordProductId === workRecordProductId)
    const defaultPricingRule = pricingRules.find(pricingRule => pricingRule.pricingRuleSetId === null && pricingRule.workRecordProductId === workRecordProductId)
    return [
      { ...defaultPricingRule },
      ...pricingRuleSets.map(pricingRuleSet => {
        const pricingRule = pricingRulesOfWorkRecordProduct.find(pricingRule => pricingRule.pricingRuleSetId === pricingRuleSet.id)
        return {
          ...(pricingRule || {}),
          pricingRuleSetId: pricingRuleSet.id,
          workRecordProductId
        }
      })
    ]
  }
)((state, workRecordProductId) => workRecordProductId)

export const getStatsOfWorkRecordProduct = getStats(findWorkRecordProductWithId, 'workRecordProducts', 'workRecordProductId')

export const getOnlyEnabledWorkRecordProductsByOwnRoles = createSelector(
  getWorkRecordProductsByOwnRoles,
  workRecordProducts => workRecordProducts.filter(workRecordProduct => !workRecordProduct.isDisabled)
)

export const getWorkRecordProductsByTypes = getFilterItemsByFieldWithValueArraySelector(getWorkRecordProductsFromArgument, 'recordType', undefined, sortByProperty('productNumber'))
