import { useCallback, useMemo } from 'react'
import { castToArray } from '@evelia/common/helpers'

import CommonValueFormGroup from './CommonValueFormGroup'
import TypeaheadFormInput from './inputs/TypeaheadFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const mapOptions = options => {
  if(!options) {
    options = []
  }
  return options.map(({ value, text, ...rest }) => {
    const inputValue = value == null ? '' : value
    return { value: inputValue, text, ...rest }
  })
}

const getOptionFromValue = options => value =>
  options.filter(option => castToArray(value).includes(option.value))

const useTypeaheadOptions = (optionValues, multiple, field) => {
  const handleOnChange = useCallback(newSelection => {
    return multiple
      ? newSelection.map(({ value }) => value)
      : newSelection[0]?.value ?? null
  }, [multiple])

  return useMemo(() => ({
    options: mapOptions(optionValues),
    handleOnChange,
    inputProps: {
      'data-testid': field
    }
  }), [field, handleOnChange, optionValues])
}

const SelectTypeaheadFormGroup = props => {
  const { options, handleOnChange, inputProps } = useTypeaheadOptions(props.options, props.multiple, props.field)

  return (
    <CommonValueFormGroup
      {...commonFormInputDefaultProps}
      {...props}
      labelKey='text'
      options={options}
      getSelected={getOptionFromValue(options)}
      handleOnChange={handleOnChange}
      setNull
      Component={TypeaheadFormInput}
      inputProps={inputProps}
    />
  )
}

export const SelectTypeaheadFormInput = props => {
  const { options, handleOnChange, inputProps } = useTypeaheadOptions(props.options, props.multiple, props.field)

  return (
    <TypeaheadFormInput
      {...commonFormInputDefaultProps}
      {...props}
      labelKey='text'
      options={options}
      getSelected={getOptionFromValue(options)}
      handleOnChange={handleOnChange}
      setNull
      inputProps={inputProps}
    />
  )
}

SelectTypeaheadFormGroup.propTypes = commonFormInputPropTypes
SelectTypeaheadFormInput.propTypes = commonFormInputPropTypes

export default SelectTypeaheadFormGroup
