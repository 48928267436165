// eslint-disable-next-line simple-import-sort/imports
import moment, { DurationInputArg1, Moment, MomentInput, unitOfTime } from 'moment'

import './moment/fi.js'
import isString from 'lodash/isString.js'

moment.locale('fi')

export default moment
export { moment }

export const MONDAY = 'monday'
export const TUESDAY = 'tuesday'
export const WEDNESDAY = 'wednesday'
export const THURSDAY = 'thursday'
export const FRIDAY = 'friday'
export const SATURDAY = 'saturday'
export const SUNDAY = 'sunday'

export const WEEKDAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY]

export const getWeekDaysDataFromMomentDate = (momentDate: Moment) => {
  return WEEKDAYS.reduce((acc, day, index) => {
    return {
      ...acc,
      [day]: {
        date: momentDate.weekday(index).format(dateFormats.finnishDateFormatShort),
        dateKey: momentDate.weekday(index).format('YYYY-MM-DD'),
        shortDateName: momentDate.weekday(index).format('ddd'),
        timeStamp: momentDate.weekday(index).format()
      }
    }
  }, {
  })
}

const finnishDateFormat = 'DD.MM.YYYY'
const finnishDateFormatShort = 'DD.MM.' /* from frontend */
const finnishDateFormatShortBasic = 'D.M.YYYY' /* from backend */

const finnishTimeFormatShort = 'HH:mm'
const finnishTimeFormat = finnishTimeFormatShort + ':ss'

const finnishDateTimeFormat = `${finnishDateFormat} ${finnishTimeFormat}`
const finnishDateTimeFormatShort = `${finnishDateFormat} ${finnishTimeFormatShort}`

const isoDate = 'YYYY-MM-DD'
const isoTime = 'HH:mm:ss'
const isoTimeTz = 'HH:mm:ssZ'
const isoTimeFormat = 'HH:mm'
const isoDateTimeFormat = `${isoDate}T${isoTime}ZZ`

const safeTimeShort = 'HHmm'
const safeTime = `${safeTimeShort}ss`
const safeDate = 'YYYY_MM_DD'
const safeDateTime = `${safeDate}_${safeTime}`
const safeDateTimeShort = `${safeDate}_${safeTimeShort}`

const fileDateFormat = 'YYYYMMDD'
const finnishHumanReadableDateFormat = 'dddd, MMMM DD, YYYY'

export const dateFormats = {
  finnishDateFormat,
  finnishDateFormatShort,
  finnishDateFormatShortBasic,
  finnishTimeFormatShort,
  finnishTimeFormat,
  finnishDateTimeFormat,
  finnishDateTimeFormatShort,
  isoDate,
  isoTime,
  isoTimeTz,
  isoTimeFormat,
  isoDateTimeFormat,
  safeTimeShort,
  safeTime,
  safeDate,
  safeDateTime,
  safeDateTimeShort,
  fileDateFormat,
  finnishHumanReadableDateFormat
} as const

export const now = () => moment().format()
export const withFormat = (value: MomentInput, format?: string) => moment(value).format(format)

export const formatOrNull = (value: MomentInput, format?: string) => value ? withFormat(value, format) : null
export const formatOrNow = (value: MomentInput, format?: string) => value ? withFormat(value, format) : now()

export const formatDate = (value: MomentInput, format = finnishDateFormat) => withFormat(value, format)
export const formatDateOrNull = (value: MomentInput, format?: string) => value != null ? formatDate(value, format) : null
export const formatDateOrEmpty = (value: MomentInput, format?: string) => value != null ? formatDate(value, format) : ''
export const formatDateSafe = (value: MomentInput, format?: string) => (value && moment(value).isValid()) ? formatDate(value, format) : null

export const formatDateTime = (value: MomentInput, format = finnishDateTimeFormat) => withFormat(value, format)
export const formatDateTimeShort = (value: MomentInput, format = finnishDateTimeFormatShort) => withFormat(value, format)
export const formatDateTimeOrNull = (value: MomentInput, format?: string) => value ? formatDateTime(value, format) : null
export const formatDateTimeOrEmpty = (value: MomentInput, format?: string) => value ? formatDateTime(value, format) : ''

export const formatTime = (value: MomentInput, format = finnishTimeFormat) => withFormat(value, format)
export const formatTimeShort = (value: MomentInput, format = finnishTimeFormatShort) => withFormat(value, format)
export const formatTimeOrNull = (value: MomentInput, format?: string) => value != null ? formatTime(value, format) : null
export const formatTimeOrEmpty = (value: MomentInput, format?: string) => value != null ? formatTime(value, format) : ''

export const startOf = (value: MomentInput, unitOfTime: unitOfTime.StartOf = 'day') => moment(value).startOf(unitOfTime)
export const endOf = (value: MomentInput, unitOfTime: unitOfTime.StartOf = 'day') => moment(value).endOf(unitOfTime)
export const formatEndOf = (value: MomentInput, unitOfTime: unitOfTime.StartOf) => endOf(value, unitOfTime).format()
export const formatEndOfOrNull = (value: MomentInput, unitOfTime: unitOfTime.StartOf) => value ? formatEndOf(value, unitOfTime) : null
export const formatStartOf = (value: MomentInput, unitOfTime: unitOfTime.StartOf) => startOf(value, unitOfTime).format()
export const formatStartOfOrNull = (value: MomentInput, unitOfTime: unitOfTime.StartOf) => value ? formatStartOf(value, unitOfTime) : null
export const startOfThis = (unit: unitOfTime.StartOf) => moment().startOf(unit)
export const endOfThis = (unit: unitOfTime.StartOf) => moment().endOf(unit)

export const isAfter = (date1: MomentInput, date2?: MomentInput, unitOfTime: unitOfTime.StartOf = 'day') => moment(date1).isAfter(moment(date2), unitOfTime)
export const isBefore = (date1: MomentInput, date2: MomentInput, unitOfTime: unitOfTime.StartOf = 'day') => moment(date1).isBefore(moment(date2), unitOfTime)
export const isSameOrAfter = (date1: MomentInput, date2: MomentInput) => moment(date1).isSameOrAfter(moment(date2), 'day')
export const isSameOrBefore = (date1: MomentInput, date2: MomentInput) => moment(date1).isSameOrBefore(moment(date2), 'day')
export const isBeforeToday = (date: MomentInput) => moment(date).isBefore(moment(), 'day')
export const isTodayOrBefore = (date: MomentInput) => moment(date).isSameOrBefore(moment(), 'day')
export const isAfterToday = (date: MomentInput) => isAfter(date)
export const isSameAsToday = (date: MomentInput) => startOf(date).isSame(startOf(moment()), 'day')
export const isSameDay = (date1: MomentInput, date2: MomentInput) => moment(date1).isSame(moment(date2), 'day')

export const addTimeWithoutFormat = (start: MomentInput, amount: DurationInputArg1, unit: unitOfTime.DurationConstructor) => moment(start).add(amount, unit)
export const addTime = (start: MomentInput, amount: DurationInputArg1, unit: unitOfTime.DurationConstructor) => addTimeWithoutFormat(start, amount, unit).format()
export const addDays = (amount: DurationInputArg1, start: MomentInput) => addTimeWithoutFormat(start || undefined, amount, 'days')
export const addWeeks = (amount: DurationInputArg1, start: MomentInput) => addTimeWithoutFormat(start || undefined, amount, 'weeks')
export const addMonths = (amount: DurationInputArg1, start: MomentInput) => addTimeWithoutFormat(start || undefined, amount, 'months')

export const formatFileDate = (startDate: MomentInput, endDate: MomentInput) => `${withFormat(startDate, fileDateFormat)}_${withFormat(endDate, fileDateFormat)}`
export const fileDateToday = () => `${withFormat(moment(), fileDateFormat)}`
export const formatFileNameDateTime = () => moment().format(safeDateTimeShort)

export type { unitOfTime } from 'moment'

export const parseMomentData = (value?: MomentInput, format?: boolean | string) => {
  const maybeMoment = moment(value, [moment.ISO_8601, dateFormats.isoTimeFormat])
  return maybeMoment.isValid()
    ? (format ? maybeMoment.format(isString(format) ? format : undefined) : maybeMoment)
    : value
}
