import React from 'react'
import { Alert, Button } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/common/helpers'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useToggle from '../hooks/useToggle'

interface InfoBoxProps {
  message?: string
  children?: React.ReactNode
  color?: string
  closable?: boolean
  title?: string
  icon?: IconProp
  closedText?: string
  className?: string
  testId?: string
}

const InfoBox = React.memo(({
  children,
  message,
  closedText,
  color = 'info',
  closable,
  icon = faInfoCircle,
  title,
  className = 'my-3 p-0',
  testId
}: InfoBoxProps) => {
  const [isOpen, { toggle }] = useToggle()
  const Wrapper = closable ? Button : 'div'
  const wrapperProps = closable
    ? { onClick: toggle, className: 'bg-transparent btn-outline-light border-0' }
    : {}
  return (
    <Wrapper {...wrapperProps} title={title} className={appendEitherOrEmpty(wrapperProps.className, className)}>
      <Alert color={color} className='px-2 py-1 m-0' data-testid={testId}>
        <div className='d-flex gap-2 flex-wrap'>
          <div className='flex-0'>
            <FontAwesomeIcon icon={icon} />
          </div>
          {(closable && !isOpen)
            ? closedText
            : children || message}
        </div>
      </Alert>
    </Wrapper>
  )
})

export default InfoBox
